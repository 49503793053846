import i18n from "@/plugins/i18n";
import { point, lineString } from "@turf/helpers";
import bbox from "@turf/bbox";
import length from "@turf/length";

export default class ValidationHelpers {
  static isRequired(value) {
    return (value != undefined && value !== "") || i18n.t("contents.validations.required");
  }

  static isEmailValid(email) {
    const validator = require("email-validator");
    return validator.validate(email);
  }

  static isUrlValid(url) {
    try {
      new URL(url);
    } catch {
      return false;
    }
    return true;
  }

  static isImageUrlTypeValid(imageUrl) {
    // Check if the input has https png and jpg
    try {
      new URL(imageUrl);
    } catch {
      return false;
    }

    return (
      imageUrl.includes("https") &&
      (imageUrl.includes(".png") ||
        imageUrl.includes(".jpg") ||
        imageUrl.includes(".jpeg") ||
        imageUrl.includes("PNG") ||
        imageUrl.includes("JPG") ||
        imageUrl.includes("JPEG"))
    );
  }

  static isPasswordValid(password) {
    // Between 8 to 16 characters, at least one uppercase, at least one lowercase, at least one number with specials
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!#$%&'*+\-.,/=?^_`{|}~@\d]{8,16}$/.test(password);
  }

  static isInteger(value) {
    if (typeof value === "string" && /[.,]/.test(value)) {
      return false;
    }
    return !isNaN(value) && !isNaN(parseInt(value));
  }

  static isFloat(value) {
    return !isNaN(value) && !isNaN(parseFloat(value));
  }

  static hasLessThanNineDecimal(value) {
    return this.isFloat(value) && value.toString().slice(value.toString().indexOf(".") + 1).length < 9;
  }

  static isBoolean(value) {
    return typeof value === "boolean" || ["True", "False"].includes(value);
  }

  static isUUID(value) {
    return /[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}/gi.test(value);
  }

  static isNameLengthValid(name) {
    return /^.{0,64}$/.test(name) || i18n.t("contents.validations.poi-name-limit");
  }

  static isClientTitleValid(title) {
    return /^.{1,50}$/.test(title);
  }

  static isSiteTitleValid(title) {
    return /^.{1,50}$/.test(title);
  }

  static isBuildingTitleValid(title) {
    return /^.{1,100}$/.test(title);
  }

  static isExternalIdentifierValid(externalIdentifier) {
    return /^.{1,40}$/.test(externalIdentifier);
  }

  static isOptionalExternalIdentifierValid(externalIdentifier) {
    return /^.{0,50}$/.test(externalIdentifier);
  }

  static isLongTitleValid(title) {
    return /^.{1,100}$/.test(title);
  }

  static isShortTitleValid(shortTitle) {
    return /^.{1,5}$/.test(shortTitle);
  }

  static isLevelIndexValid(levelIndex) {
    return levelIndex !== "" && !isNaN(Number(levelIndex)) && Number(levelIndex) >= -99 && Number(levelIndex) <= 999;
  }

  static isKeyValid({ key, min = 0, max = 100 }) {
    const keyRegex = new RegExp(`^[a-zA-Z0-9-._]{${min},${max}}$`);
    return keyRegex.test(key);
  }

  static isCoordinateValid(str) {
    try {
      if (typeof str === "string") {
        const coords = JSON.parse(str);
        const polygon = coords?.[0];
        if (polygon[0][0] !== polygon[polygon.length - 1][0] || polygon[0][1] !== polygon[polygon.length - 1][1]) {
          return i18n.t("contents.validations.first-and-last-same");
        }

        // const hasMoreThan8Digits = (coord) => {
        //   coord = String(coord);
        //   const decimals = coord.split(".")?.[1];
        //   if (decimals.length > 8) {
        //     return true;
        //   }
        //   return false;
        // };
        // let exceeds8Digits = false;
        // coords?.[0].forEach((latLngArray) => [
        //   latLngArray.forEach((coordinate) => {
        //     if (hasMoreThan8Digits(coordinate)) {
        //       exceeds8Digits = true;
        //     }
        //   })
        // ]);
        // if (exceeds8Digits) {
        //   return i18n.t("contents.validations.more-than-eight");
        // }

        return true;
      }
      return i18n.t("contents.validations.invalid-coordinates");
    } catch (error) {
      return i18n.t("contents.validations.invalid-coordinates");
    }
  }

  static isSiteCoordsValid(coordinates) {
    try {
      const parsedCoordinates = JSON.parse(coordinates || "[]");
      const featureCollection = {
        type: "FeatureCollection",
        features: parsedCoordinates?.[0]?.map((coord) => point(coord))
      };

      const boundaryBox = bbox(featureCollection);

      const width = length(
        lineString([
          [boundaryBox[0], boundaryBox[1]],
          [boundaryBox[2], boundaryBox[1]]
        ]),
        { units: "kilometers" }
      );
      const height = length(
        lineString([
          [boundaryBox[0], boundaryBox[1]],
          [boundaryBox[0], boundaryBox[3]]
        ]),
        { units: "kilometers" }
      );

      if (width > 10 || height > 10) {
        return i18n.t("contents.validations.site-boundaries");
      }
      return true;
    } catch {
      return false;
    }
  }

  static checkByType(value, type) {
    switch (type) {
      case "Integer":
        return this.isInteger(value) || i18n.t("invalid.invalid-integer");
      case "Float":
        return this.isFloat(value) || i18n.t("invalid.invalid-float");
      case "Double":
        return this.isFloat(value) || i18n.t("invalid.invalid-double");
      case "Boolean":
        return this.isBoolean(value) || i18n.t("invalid.invalid-boolean");
      default:
        return true;
    }
  }
}
