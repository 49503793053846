<template>
  <div>
    <div :id="mapOptions.container"></div>
    <PublishModal />
  </div>
</template>

<script>
import maplibregl from "maplibre-gl";
import { mapState } from "vuex";
import PublishModal from "@/components/preview/PublishModal.vue";
import MapEventConstants from "@/constants/MapEventConstants";
import PreviewService from "@/services/PreviewService";
import { setIpadHeight } from "@/helpers/StyleHelpers";

const MAP_ID = "preview-map";

export default {
  name: "PreviewMap",
  components: {
    PublishModal
  },
  props: {},

  data: () => ({
    map: undefined,
    mapDraw: undefined,
    mapOptions: {
      container: MAP_ID,
      style: undefined,
      center: [-122.83941269, 45.5165561],
      zoom: 17
    },
    drawOptions: {
      displayControlsDefault: false
    }
  }),

  computed: {
    ...mapState("MAP", ["currentClient", "minZoom", "maxZoom"]),
    ...mapState("CONTENT", ["sites"]),
    ...mapState("PREVIEW_MAP", ["currentLevel", "currentBid", "previewTilesJsonUrl", "zoom"])
  },
  watch: {
    currentLevel() {
      this.updateLayerFilters();
    },
    currentBid() {
      this.updateLayerFilters();
      this.setBuildingOutlines();
    }
  },

  async created() {
    // removes content map's current building. So that when going back to that map it can rezoom to related building
    this.$store.commit("MAP/CURRENT_BUILDING");
    window.addEventListener("resize", setIpadHeight);
    setIpadHeight();
    this.setMapUI();
    await this.$store.dispatch("CONTENT/SET_CLIENTS");
    await this.$store.dispatch("CONTENT/SET_SITES", { clientId: this.currentClient });
    if (!this.previewTilesJsonUrl) {
      const previewStatus = await PreviewService.getStatus(this.currentClient);
      this.$store.commit("PREVIEW_MAP/SET_PREVIEW_TILES_JSON", previewStatus?.previewTilesJsonUrl);
    }
    this.mapOptions.style = await PreviewService.getPreviewStyleJson(this.currentClient);
    this.mapOptions.minZoom = this.minZoom;
    this.mapOptions.maxZoom = this.maxZoom;
    this.setSourcesToStyle();
    if (maplibregl.getRTLTextPluginStatus() === "unavailable") {
      maplibregl.setRTLTextPlugin(
        "https://unpkg.com/@mapbox/mapbox-gl-rtl-text@0.2.3/mapbox-gl-rtl-text.min.js",
        null,
        true // Lazy load the plugin
      );
    }
    this.map = new maplibregl.Map(this.mapOptions);
    this.$store.commit("ADD_LOADING_REQUEST");
    this.map.on(MapEventConstants.LOAD, () => {
      this.mapLoaded();
    });
    this.map.on("click", () => {
      this.$store.commit("PREVIEW_MAP/IS_LEVEL_SELECTOR_EXPANDED", false);
    });
    this.map.on("error", (e) => {
      if (!e?.error?.message?.includes('does not exist on source "source_ptr" as specified by style')) {
        console.debug(e.error);
      }
    });
  },

  beforeDestroy() {
    this.map.remove();
    this.$store.commit("PREVIEW_MAP/MAP_READY", false);
    this.$store.dispatch("PREVIEW_MAP/SET_CURRENT_LEVEL", undefined);
    this.$store.commit("PREVIEW_MAP/IS_LEVEL_SELECTOR_EXPANDED", false);
    this.map = undefined;
    window.removeEventListener("resize", setIpadHeight);
  },

  methods: {
    async mapLoaded() {
      this.$store.commit("PREVIEW_MAP/MAP", this.map);
      this.map.on(MapEventConstants.ZOOM_END, this.setZoom);
      if (this.map) {
        const layers = this.map.getStyle().layers.filter((layer) => layer.id.includes("_ptr"));
        layers.forEach((layer) => {
          this.map.setLayerZoomRange(layer.id, 0, 24);
        });
      }
      this.updateLayerFilters();
      // map loaded
      let interval = setInterval(() => {
        if (this.map?.isStyleLoaded()) {
          clearInterval(interval);
          this.$store.commit("PREVIEW_MAP/MAP_READY", true);
          this.$store.commit("REMOVE_LOADING_REQUEST");
        }
      });
    },
    setBuildingOutlines() {
      const buildingsGeoJson = {
        type: "FeatureCollection",
        features: []
      };
      this.sites.forEach((site) => {
        site.buildings
          .filter((building) => building.buildingInternalIdentifier !== this.currentBid)
          .forEach((building) => {
            buildingsGeoJson.features.push({
              type: "Feature",
              properties: {
                name: building.buildingTitle,
                typeCode: "building-outline",
                buildingInternalIdentifier: building.buildingInternalIdentifier,
                buildingExternalIdentifier: building.buildingExternalIdentifier
              },
              geometry: {
                type: "Polygon",
                coordinates: building?.geometry?.coordinates || []
              }
            });
          });
      });
      if (!this.map.getSource("building-outline_source_ptr")) {
        this.map.addSource("building-outline_source_ptr", {
          type: "geojson",
          data: buildingsGeoJson
        });
      } else {
        this.map.getSource("building-outline_source_ptr").setData(buildingsGeoJson);
      }

      if (!this.map.getLayer("meta_building_boundaries")) {
        this.map.addLayer({
          id: "meta_building_boundaries",
          type: "fill-extrusion",
          source: "building-outline_source_ptr",
          filter: ["==", ["get", "typeCode"], "building-outline"],
          layout: { visibility: "visible" },
          paint: {
            "fill-extrusion-color": "#DCE2E5",
            "fill-extrusion-height": 50
          }
        });
      }
    },
    updateLayerFilters() {
      if (this.map) {
        const layers = this.map.getStyle().layers.filter((layer) => layer.id.includes("_ptr"));
        layers.forEach((layer) => {
          this.map.setFilter(layer.id, [
            "all",
            ["match", ["get", "bid"], [this.currentBid], true, false],
            ["==", ["get", "lvl"], this.currentLevel]
          ]);
        });
      }
    },
    setZoom() {
      this.$store.commit("PREVIEW_MAP/ZOOM", this.map.getZoom());
    },
    setMapUI() {
      this.$store.commit("MAP/SHOW_UI_ICON", { iconName: "zoom" });
      this.$store.commit("MAP/SHOW_UI_ICON", { iconName: "publish" });
      this.$store.commit("MAP/SHOW_UI_ICON", { iconName: "levelSelector" });
      this.$store.commit("MAP/SHOW_UI_ICON", { iconName: "buildingSiteSelector" });
    },
    setSourcesToStyle() {
      this.mapOptions.style.sources["source_ptr"] = {
        type: "vector",
        url: this.previewTilesJsonUrl
      };
      this.mapOptions.style.sources["source_path_ptr"] = {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: []
        }
      };
      this.mapOptions.style.sources["source_annotations_ptr"] = {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: []
        }
      };
      this.mapOptions.style.sources["source_selected_ptr"] = {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: []
        }
      };
    }
  }
};
</script>
