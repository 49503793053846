import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/store.js";

import AuthHelpers from "@/helpers/AuthHelpers";

import LoginRoutes from "@/router/loginRoutes";
import MainRoutes from "@/router/mainRoutes";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/cms/content"
  },
  {
    path: "/cms",
    redirect: "/cms/content"
  },
  {
    path: "/404",
    redirect: "/cms/content"
  },
  {
    path: "*",
    redirect: "/404"
  }
];
routes.push(LoginRoutes);
routes.push(MainRoutes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const mode = process.env.VUE_APP_DASHBOARD_MODE;

store.commit("CONTENT/DASHBOARD_MODE", mode);
if (mode === "embed") {
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get("access_token");
  const refreshToken = urlParams.get("refresh_token");
  AuthHelpers.setAccessToken(accessToken);
  AuthHelpers.setRefreshToken(refreshToken);
}

router.beforeEach(async (to, from, next) => {
  store.commit("MAP/HIDE_UI_ICONS");
  if (to.meta.requiresAuth) {
    const isRefreshed = await AuthHelpers.refreshToken();
    if (!isRefreshed && mode === "embed") {
      next({ path: "/cms/embedError" });
    }
  }
  // not requries auth means login page so we redirect to cms embed error
  else if (mode === "embed" && to.path !== "/cms/embedError") {
    next({ path: "/cms/embedError" });
    return;
  }

  const isAuthTokenValid = AuthHelpers.isAuthTokenValid();
  if (to.meta.requiresAuth && !isAuthTokenValid) {
    store.commit("CONTENT/IS_FORM_DIRTY", false);
    localStorage.setItem("redirectRoute", JSON.stringify(to));
    AuthHelpers.logout();
    next({ path: "/signin" });
  } else if (to.path === "/signin" && isAuthTokenValid) {
    next(from.path);
  } else {
    next();
  }
});

export default router;
