import Helpers from "@/helpers/Helpers";

export default [
  //   BUILDINGS
  {
    path: "clients/:clientId/sites/:siteId/:contentType(buildings)/:buildingId/edit",
    name: "BuildingEdit",
    component: Helpers.lazyLoad("BuildingsView", "views/content/buildings"),
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/:siteId/:contentType(buildings)/:buildingId(add)",
    name: "BuildingAdd",
    component: Helpers.lazyLoad("BuildingsView", "views/content/buildings"),
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/:siteId/buildings/:buildingId?",
    name: "Buildings",
    component: Helpers.lazyLoad("BuildingsView", "views/content/buildings"),
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/:siteId/:contentType(\\outdoor-wayfinding-network)/:featureId?",
    name: "OutdoorWayfindingNetwork",
    component: Helpers.lazyLoad("OutdoorWayfindingNetwork", "views/content"),
    meta: { requiresAuth: true }
  }
];
