const LEVEL_STATUS = [
  { statusCode: 0, displayedName: "ai-error", cssClass: "error-color", state: "error" },
  { statusCode: 1, displayedName: "ai-running", cssClass: "warning-color", state: "inProgress" },
  { statusCode: 2, displayedName: "ai-running", cssClass: "warning-color", state: "inProgress" },
  { statusCode: 3, displayedName: "ai-running", cssClass: "warning-color", state: "inProgress" },
  { statusCode: 4, displayedName: "ready-for-review", cssClass: "success-color", state: "success" },
  { statusCode: 5, cssClass: "hidden", state: "partially-approved" },
  { statusCode: 6, displayedName: "ai-error", cssClass: "error-color", state: "error" },
  { statusCode: 7, displayedName: "ai-error", cssClass: "error-color", state: "declined" },
  { statusCode: 8, displayedName: "ai-error", cssClass: "error-color", state: "error" },
  { statusCode: 9, cssClass: "hidden", state: "no-state" },
  { statusCode: 10, cssClass: "hidden", state: "completed" }
];
/**
 *  0: { text: this.$t(`${this.translationPath}ai-error`), color: "error-color" },
        1: { text: this.$t(`${this.translationPath}ai-running`), color: "warning-color" },
        2: { text: this.$t(`${this.translationPath}ai-running`), color: "warning-color" },
        3: { text: this.$t(`${this.translationPath}ai-running`), color: "warning-color" },
        4: { text: this.$t(`${this.translationPath}ready-for-review`), color: "success-color" },
        // 5: { text: this.$t(`${this.translationPath}partially-approved`), color: "success-color" },
        6: { text: this.$t(`${this.translationPath}ai-error`), color: "error-color" },
        7: { text: this.$t(`${this.translationPath}ai-error`), color: "error-color" },
        8: { text: this.$t(`${this.translationPath}ai-error`), color: "error-color" }
        // 9: { text: this.$t(`${this.translationPath}no-state`) }
        10 => completed
 */
export default class LevelStatusHelper {
  static getStatusObject(statusCode) {
    return LEVEL_STATUS.find((statusObject) => statusObject.statusCode === statusCode);
  }

  static getDisplayedName(statusCode) {
    return this.getStatusObject(statusCode)?.displayedName;
  }

  static getCssClass(statusCode) {
    return this.getStatusObject(statusCode)?.cssClass;
  }

  static isErrorState(statusCode) {
    return this.getStatusObject(statusCode)?.state === "error";
  }

  static isInProgressState(statusCode) {
    return this.getStatusObject(statusCode)?.state === "inProgress";
  }

  static isInSuccessState(statusCode) {
    return this.getStatusObject(statusCode)?.state === "success";
  }

  static isInitialState(statusCode) {
    return statusCode === 9 || statusCode === 7;
  }

  static isCompleted(statusCode) {
    return statusCode === 10;
  }

  static isAnyLevelInProgress(levelStatuses) {
    const inProgressLevel = Object.values(levelStatuses)?.find((levelStatusObject) =>
      this.isInProgressState(levelStatusObject?.levelStatus)
    );
    if (inProgressLevel) {
      return true;
    }
    return false;
  }

  static shouldHide(statusCode) {
    return (
      statusCode === undefined ||
      this.getStatusObject(statusCode)?.displayedName === undefined ||
      statusCode < 0 ||
      statusCode > 10
    );
  }
}
