import Vue from "vue";
import Vuex from "vuex";

import AuthHelpers from "@/helpers/AuthHelpers";
import LoginService from "@/services/LoginService";
import ServiceConstants from "@/constants/serviceConstants";
import TaxonomyService from "../services/TaxonomyService";

import mapStore from "./mapStore";
import contentStore from "./contentStore";
import advancedConfigurationsStore from "./advancedConfigurationsStore";
import previewMapStore from "./previewMapStore";
import configStore from "@/store/configStore";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAppLoaded: false,
    loadingRequestCount: 0,
    backgroundLoadingRequestCount: 0,
    breadcrumbList: [],
    taxonomy: [],
    user: {},
    historyLength: 0,
    shouldShowVersionInformationModal: false
  },
  mutations: {
    APP_LOADED(state) {
      state.isAppLoaded = true;
    },
    ADD_LOADING_REQUEST(state) {
      state.loadingRequestCount++;
    },
    REMOVE_LOADING_REQUEST(state) {
      if (state.loadingRequestCount > 0) {
        // For preventing loading from flickring on and off too frequently
        setTimeout(() => state.loadingRequestCount--, 250);
      }
    },
    ADD_BACKGROUND_LOADING_REQUEST(state) {
      state.backgroundLoadingRequestCount++;
    },
    REMOVE_BACKGROUND_LOADING_REQUEST(state) {
      if (state.backgroundLoadingRequestCount > 0) {
        // For preventing loading from flickring on and off too frequently
        setTimeout(() => state.backgroundLoadingRequestCount--, 250);
      } else if (state.backgroundLoadingRequestCount <= 0) {
        setTimeout(() => (state.backgroundLoadingRequestCount = 0));
      }
    },
    BREADCRUMB_LIST(state, list = []) {
      state.breadcrumbList = [...list];
    },
    TAXONOMY(state, taxonomy) {
      state.taxonomy = taxonomy;
    },
    SET_USER(state, parsedJwt) {
      state.user = {
        surname: parsedJwt.family_name,
        name: parsedJwt.unique_name,
        email: parsedJwt.email,
        id: parsedJwt.user_id,
        partnerId: parsedJwt.partner_id,
        roles: parsedJwt.roles
      };
    },
    SET_HISTORY_LENGTH(state, length) {
      state.historyLength = length;
    },
    SET_VERSION_INFORMATION_MODAL_STATUS(state, status) {
      state.shouldShowVersionInformationModal = status;
    }
  },
  actions: {
    async SET_TAXONOMY({ state, commit }, payload) {
      if ((Object.keys(state.taxonomy).length && payload?.forceUpdate) || !Object.keys(state.taxonomy).length) {
        let response = await TaxonomyService.getTaxonomy();
        commit("TAXONOMY", response);
      }
    },
    async LOGIN({ commit }, payload) {
      const input = {
        username: payload.username,
        password: payload.password,
        grant_type: ServiceConstants.PASSWORD_GRANT_TYPE
      };

      const response = await LoginService.getToken(input);
      const { access_token, refresh_token } = response?.result || {};
      if (access_token && refresh_token) {
        AuthHelpers.login(access_token, refresh_token);
        const user = AuthHelpers.parseJwt(access_token);
        commit("SET_USER", user);
      } else {
        throw new Error();
      }
    },
    async SSO_LOGIN({ commit }, payload) {
      const input = {
        code: payload.code,
        client_id: payload.client_id,
        grant_type: ServiceConstants.AUTHORIZATTION_GRANT_TYPE
      };
      const response = await LoginService.getToken(input);
      const { access_token, refresh_token } = response?.result || {};
      if (access_token && refresh_token) {
        AuthHelpers.login(access_token, refresh_token);
        const user = AuthHelpers.parseJwt(access_token);
        commit("SET_USER", user);
      } else {
        throw new Error();
      }
    }
  },
  getters: {},
  modules: {
    MAP: mapStore,
    CONTENT: contentStore,
    ADVANCED_CONFIGS: advancedConfigurationsStore,
    PREVIEW_MAP: previewMapStore,
    CONFIG: configStore
  }
});
