var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{staticClass:"mt-2 mb-3",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('div',{staticClass:"mt-2 mb-3 geometry-sub-title"},[_vm._v(" "+_vm._s(_vm.isEditModeActive ? _vm.$t(`${_vm.translationPath}adjust-position`) : _vm.$t(`${_vm.translationPath}edit-position`))+" ")])]),(_vm.isEditModeActive)?_c('v-row',{staticClass:"mb-1"},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":_vm.$t(`${_vm.translationPath}anchors-locked`),"color":_vm.isAnchorsLocked ? 'neutral' : 'primary',"hide-details":"","inset":""},model:{value:(_vm.isAnchorsLocked),callback:function ($$v) {_vm.isAnchorsLocked=$$v},expression:"isAnchorsLocked"}})],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"anchor",class:{
          'primary-color': _vm.isEditModeActive && _vm.isAnchorsLocked,
          'dashed-border': _vm.isEditModeActive && !_vm.isAnchorsLocked
        }},[_vm._v(" A ")])]),_c('v-col',{staticClass:"mr-2"},[_c('v-text-field',{attrs:{"id":"anchor-a-lat","label":_vm.$t(`${_vm.translationPath}latitude`),"rules":[_vm.rules.required, _vm.rules.hasLessThanNineDecimal],"hide-details":"","dense":"","outlined":_vm.isEditModeActive,"disabled":!_vm.isEditModeActive},model:{value:(_vm.latA),callback:function ($$v) {_vm.latA=$$v},expression:"latA"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"id":"anchor-a-lng","label":_vm.$t(`${_vm.translationPath}longitude`),"rules":[_vm.rules.required, _vm.rules.hasLessThanNineDecimal],"hide-details":"","dense":"","outlined":_vm.isEditModeActive,"disabled":!_vm.isEditModeActive},model:{value:(_vm.lngA),callback:function ($$v) {_vm.lngA=$$v},expression:"lngA"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"anchor",class:{
          'primary-color': _vm.isEditModeActive && _vm.isAnchorsLocked,
          'dashed-border': _vm.isEditModeActive && !_vm.isAnchorsLocked
        }},[_vm._v(" B ")])]),_c('v-col',{staticClass:"mr-2"},[_c('v-text-field',{attrs:{"id":"anchor-b-lat","label":_vm.$t(`${_vm.translationPath}latitude`),"rules":[_vm.rules.required, _vm.rules.hasLessThanNineDecimal],"hide-details":"","dense":"","outlined":_vm.isEditModeActive,"disabled":!_vm.isEditModeActive},model:{value:(_vm.latB),callback:function ($$v) {_vm.latB=$$v},expression:"latB"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"id":"anchor-b-lng","label":_vm.$t(`${_vm.translationPath}longitude`),"rules":[_vm.rules.required, _vm.rules.hasLessThanNineDecimal],"hide-details":"","dense":"","outlined":_vm.isEditModeActive,"disabled":!_vm.isEditModeActive},model:{value:(_vm.lngB),callback:function ($$v) {_vm.lngB=$$v},expression:"lngB"}})],1)],1),(!_vm.isEditModeActive)?_c('v-row',[_c('v-btn',{attrs:{"id":"edit-anchor-points","color":"primary","block":""},on:{"click":_vm.setEditMode}},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")])],1):_c('v-row',[_c('v-col',{staticClass:"mr-2"},[_c('v-btn',{staticClass:"edit-anchor-buttons",attrs:{"color":"neutral","block":""},on:{"click":_vm.onCancelClicked}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1),_c('v-col',[_c('v-btn',{staticClass:"edit-anchor-buttons",attrs:{"color":"primary","disabled":!_vm.isAnchorsUpdated || !_vm.isFormValid,"block":""},on:{"click":_vm.setAnchors}},[_vm._v(" "+_vm._s(_vm.$t("set"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }