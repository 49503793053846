<template>
  <v-app id="app">
    <div v-if="!isAppLoaded">
      <PtrLoading />
    </div>
    <router-view />
  </v-app>
</template>

<script>
import PtrLoading from "@/components/shared/PtrLoading.vue";

export default {
  name: "App",
  components: {
    PtrLoading
  },
  computed: {
    isAppLoaded() {
      return this.$store.state.isAppLoaded;
    }
  },
  mounted() {
    this.$store.commit("SET_HISTORY_LENGTH", window.history.length);
  }
};
</script>

<style lang="scss">
@import "@/scss/global.scss";
@import "@/scss/thirdParty/maplibre.min.css";
@import "@/scss/thirdParty/materialdesignicons.css";
@import "@/scss/thirdParty/readexpro.css";

.crosshair {
  .mapboxgl-canvas-container {
    cursor: crosshair !important;
  }
}

.move {
  .mapboxgl-canvas-container {
    cursor: move !important;
  }
}

.all-scroll {
  .mapboxgl-canvas-container {
    cursor: all-scroll !important;
  }
}

.popup-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  padding: 10px 0;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background: var(--v-neutral-lighten4);
  }
}

.maplibregl-popup {
  z-index: 90;
}

.options-popup .mapboxgl-popup-content,
.maplibregl-popup-content {
  width: 200px;
  font-size: #{$font-size-5};
  text-align: center;
  padding: 5px;
}

.info-popup {
  z-index: 90;
}

.info-popup .mapboxgl-popup-content,
.maplibregl-popup-content {
  width: 200px;
  font-size: #{$font-size-5};
  text-align: center;
  padding: 8px;
  border-radius: 8px;
  & > p {
    margin: 0;
    color: var(--v-neutral-lighten4);
    font-weight: 400;
    font-size: #{$font-size-3};
  }
}
.info-popup .mapboxgl-popup-content {
  background-color: var(--v-neutral-darken4);
  z-index: 999;
}

.info-popup.maplibregl-popup-anchor-top {
  & > .maplibregl-popup-tip {
    border-bottom-color: var(--v-neutral-darken4) !important;
  }
}

.info-popup.maplibregl-popup-anchor-bottom {
  & > .maplibregl-popup-tip {
    border-top-color: var(--v-neutral-darken4) !important;
  }
}

.info-popup.maplibregl-popup-anchor-left {
  & > .maplibregl-popup-tip {
    border-right-color: var(--v-neutral-darken4) !important;
  }
}

.info-popup .maplibregl-popup-anchor-right {
  & > .maplibregl-popup-tip {
    border-left-color: var(--v-neutral-darken4) !important;
  }
}
</style>
