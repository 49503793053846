const CONTENT_TYPE = "Content-Type";
const APPLICATION_JSON = "application/json";
const AUTHORIZATTION = "Authorization";
const BEARER = "Bearer ";

const PASSWORD_GRANT_TYPE = "password";
const REFRESH_TOKEN_GRANT_TYPE = "refresh_token";
const AUTHORIZATTION_GRANT_TYPE = "authorization_code";

export default class ServiceConstants {
  static get CONTENT_TYPE() {
    return CONTENT_TYPE;
  }

  static get APPLICATION_JSON() {
    return APPLICATION_JSON;
  }

  static get AUTHORIZATTION() {
    return AUTHORIZATTION;
  }

  static get BEARER() {
    return BEARER;
  }

  static get PASSWORD_GRANT_TYPE() {
    return PASSWORD_GRANT_TYPE;
  }

  static get REFRESH_TOKEN_GRANT_TYPE() {
    return REFRESH_TOKEN_GRANT_TYPE;
  }

  static get AUTHORIZATTION_GRANT_TYPE() {
    return AUTHORIZATTION_GRANT_TYPE;
  }
}
