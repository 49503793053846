import Helpers from "@/helpers/Helpers";

export default [
  {
    path: "clients/:clientId/:contentType(global-geofences)/:globalGeofenceId/edit",
    name: "GlobalGeofenceEdit",
    components: { default: Helpers.lazyLoad("GlobalGeofencesView", "views/content/globalGeofences") },
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/:contentType(global-geofences)/:globalGeofenceId(add)",
    name: "GlobalGeofenceAdd",
    components: { default: Helpers.lazyLoad("GlobalGeofencesView", "views/content/globalGeofences") },
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/global-geofences/:globalGeofenceId?",
    name: "GlobalGeofences",
    component: Helpers.lazyLoad("GlobalGeofencesView", "views/content/globalGeofences"),
    meta: { requiresAuth: true }
  }
];
