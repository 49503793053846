<template>
  <v-expansion-panels v-model="defaultOpenPanelIndex" class="content-add-edit-expansion-panels" flat>
    <v-expansion-panel active-class="expanded" class="mt-0" :disabled="isMapBorderEnabled">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="information" />
        <div class="expansion-panel-header-text">{{ $t("contents.mapDesigner.basic-information") }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="siteForm" v-model="isFormValid" class="mt-2">
          <v-row>
            <v-col class="py-1">
              <v-text-field
                id="site-title"
                v-model.trim="siteTitle"
                :rules="[rules.required, isTitleValid]"
                :label="$t(`${translationPath}site-name`)"
                outlined
                hide-details="auto"
                dense
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-1">
              <v-text-field
                id="site-external-id"
                v-model.trim="siteExternalIdentifier"
                :rules="[isExternalIdentifierValid]"
                :label="$t(`${translationPath}eid`)"
                outlined
                hide-details="auto"
                dense
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
          <GeometrySection
            ref="geometrySection"
            :feature-id="featureId"
            :should-show-polygon-icon="true"
            :should-show-point-icon="false"
            :is-edit="isEdit"
            @clear="clearGeometry"
          ></GeometrySection>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel active-class="expanded" class="mt-0" :disabled="isMapBorderEnabled">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="custom-integration" />
        <div class="expansion-panel-header-text">{{ $t(`${translationPath}custom-integration`) }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <CustomIntegration
          ref="customIntegration"
          :extra-data-prop="extraData"
          :feature-id="featureId?.toString()"
          @integrationUpdated="setFormDirty"
          @setFormDirty="setFormDirty"
        ></CustomIntegration>
      </v-expansion-panel-content>
      <v-expansion-panel-content> </v-expansion-panel-content>
    </v-expansion-panel>
    <div class="v-expansion-panel">
      <div class="v-expansion-panel-header" @click="onSettingsClicked">
        <PtrIcon class="expansion-panel-header-icon" icon="settings" />
        <div class="expansion-panel-header-text">{{ $t(`${translationPath}settings`) }}</div>
      </div>
    </div>
    <slot name="danger-zone"></slot>
    <!--SiteSettingsPopup
      v-if="shouldDisplaySettingsPopup"
      @discard="(isFormDirty) => onSiteSettingsDiscard(isFormDirty)"
    /-->
    <AdvancedSdkConfigs
      v-if="shouldDisplaySettingsPopup"
      @discard="(isFormDirty) => onSiteSettingsDiscard(isFormDirty)"
    />
  </v-expansion-panels>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import PtrIcon from "@/components/shared/PtrIcon";
import CustomIntegration from "@/components/shared/CustomIntegration.vue";

import GeometrySection from "@/components/mapDesigner/GeometrySection.vue";
import SiteService from "@/services/SiteService";
import SdkConfigurationService from "@/services/SdkConfigurationService";
import ValidationHelpers from "@/helpers/ValidationHelpers";
import MapModeConstants from "@/constants/mapModeConstants";
import MapHelpers from "@/helpers/MapHelpers";
import ToastHelpers from "@/helpers/ToastHelpers";
import booleanWithin from "@turf/boolean-within";
import { polygon } from "@turf/helpers";
import AdvancedSdkConfigs from "../views/content/AdvancedSdkConfigs.vue";
//import SiteSettingsPopup from "./SiteSettingsPopup.vue";
export default {
  name: "SiteAddEditView",
  components: { PtrIcon, GeometrySection, CustomIntegration, AdvancedSdkConfigs },
  props: {
    featureId: [String, Number]
  },
  data: () => ({
    translationPath: "contents.sites.",
    site: {},
    isEdit: false,
    isFormValid: false,
    rules: {
      required: (value) => ValidationHelpers.isRequired(value),
      coordinates: (value) => ValidationHelpers.isCoordinateValid(value),
      siteBoundary: (value) => ValidationHelpers.isSiteCoordsValid(value)
    },
    siteTitle: "",
    siteExternalIdentifier: "",
    extraData: {},
    defaultOpenPanelIndex: 0,
    shouldDisplaySettingsPopup: false
  }),
  computed: {
    drawnCoordinates: {
      get() {
        return this.$store.state.MAP.drawnCoordinates;
      },
      set(value) {
        this.$store.commit("MAP/DRAWN_COORDINATES", value);
      }
    },
    ...mapState("MAP", ["isMapReady", "mapDraw", "currentClient", "currentSite", "isMapBorderEnabled"]),
    ...mapState("CONTENT", ["isFormDirty", "sites"]),
    ...mapState("ADVANCED_CONFIGS", ["initialSdkConfigurations", "cachedSdkConfigurations"]),
    ...mapGetters("MAP", ["currentSiteObject"])
  },
  watch: {
    $route() {
      this.setMapUI();
    },
    featureId: {
      immediate: true,
      async handler() {
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: undefined, shouldKeepFormClean: true });
        this.$store.commit("MAP/DRAWN_COORDINATES", undefined);
        this.site = this.sites.find((site) => site.siteInternalIdentifier.toString() === this.featureId?.toString());
        if (this.site) {
          this.isEdit = true;
          this.$store.dispatch("MAP/SET_CURRENT_SITE", { site: this.featureId });
          const siteData = await SiteService.getSite(this.featureId);
          this.siteTitle = siteData.siteTitle;
          this.extraData = siteData.siteExtraData;
          this.siteExternalIdentifier = siteData.siteExternalIdentifier;
          this.$store.commit("MAP/DRAWN_COORDINATES", this.site.geometry.coordinates);
          let feature = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: []
            }
          };
          if (this.mapDraw.getAll().features.length > 0) {
            feature = this.mapDraw.getAll().features[0];
          }
          feature.geometry.coordinates = this.site.geometry.coordinates;
          this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature, shouldKeepFormClean: true });
          const sdkConfigResponse = await SdkConfigurationService.getSiteSdkConfigurations(this.featureId);
          this.$store.commit("ADVANCED_CONFIGS/INITIAL_SDK_CONFIGURATIONS", JSON.stringify(sdkConfigResponse));
          this.$store.commit("ADVANCED_CONFIGS/CACHED_SDK_CONFIGURATIONS", sdkConfigResponse);
        } else if (this.featureId === "add") {
          this.$refs.siteForm?.resetValidation();
          this.isEdit = false;
          this.siteTitle = "";
          this.extraData = {};
          this.siteExternalIdentifier = "";
        }
      }
    },
    drawnCoordinates() {
      this.emitValidationStatusForForm();
    },
    isFormValid() {
      this.emitValidationStatusForForm();
    },
    isMapBorderEnabled() {
      this.emitValidationStatusForForm();
    }
  },
  beforeDestroy() {
    MapHelpers.disableMapInteractions();
    this.$store.commit("MAP/MAP_MODE", MapModeConstants.EMPTY_MODE);
    this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: undefined, shouldKeepFormClean: true });
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
    this.$store.commit("MAP/HIDE_UI_ICONS", "search");
  },
  created() {
    MapHelpers.enableMapInteractions();
    this.setMapUI();
    this.$store.dispatch("MAP/MAP_ENTER_EDIT_MODE");
  },
  methods: {
    setMapUI() {
      this.$store.commit("MAP/SHOW_UI_ICON", { iconName: "search" });
      this.$store.commit("MAP/SHOW_UI_ICON", { iconName: "zoom" });
    },
    goBack() {
      // wait for addeditpanel to close completely. Otherwise info about this.currentsite appears on the panel for a very short time while it is closing.
      setTimeout(
        () =>
          this.$router
            .push({ name: "Sites", params: { siteId: this.currentSite } })
            .catch((e) => console.log(e.message)),
        200
      );
    },
    areBuildingsWithinSite() {
      let coordinates;
      try {
        coordinates = JSON.parse(this.drawnCoordinates || "[]");
      } catch (e) {
        coordinates = this.drawnCoordinates;
      }
      let turfSitePolygon;
      try {
        turfSitePolygon = polygon(coordinates);
      } catch (e) {
        return false;
      }
      const areBuildingsInsideSide = this.currentSiteObject?.buildings.every((building) => {
        const turfBuildingPolygon = polygon(building?.geometry?.coordinates);
        return booleanWithin(turfBuildingPolygon, turfSitePolygon);
      });
      return areBuildingsInsideSide;
    },
    async save() {
      if (!this.isSinglePolygon()) {
        ToastHelpers.createErrorToast(this.$t(this.translationPath + "site-divide-error"));
        return false;
      }
      if (this.isEdit && !this.areBuildingsWithinSite()) {
        ToastHelpers.createErrorToast(this.$t(this.translationPath + "buildings-within-error"));
        return false;
      }
      let coordinates;
      try {
        coordinates = JSON.parse(this.drawnCoordinates || "[]");
      } catch (e) {
        coordinates = this.drawnCoordinates;
      }
      let extraData = this.$refs?.customIntegration?.getExtraData();
      if (!extraData && this.feature) {
        extraData = this.feature.properties?.extra || this.feature.properties?.extraData;
      }
      const request = {
        siteTitle: this.siteTitle.trim(),
        siteExternalIdentifier: this.siteExternalIdentifier?.trim(),
        siteExtraData: extraData,
        geometry: {
          type: "Polygon",
          coordinates
        }
      };
      let response;
      if (this.isEdit) {
        response = await SiteService.updateSite(request, this.currentSite);
      } else {
        response = await SiteService.createSite(request, this.currentClient);
      }
      if (response?.createdTimestampUtcEpochSeconds) {
        await this.saveSdkConfigurations(this.isEdit ? this.currentSite : response.result.siteInternalIdentifier);
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        await this.$store.dispatch("CONTENT/SET_SITES", { forceUpdate: true, clientId: this.currentClient });
        this.$store.dispatch("MAP/DISPLAY_SITE_SYMBOLS");
        this.$store.dispatch("MAP/DISPLAY_SITE_BORDERS");
        this.$router
          .push({
            name: "Sites",
            params: { siteId: this.isEdit ? this.currentSite : response.result.siteInternalIdentifier }
          })
          .catch((e) => console.log(e.message));
        return true;
      }
      return false;
    },
    async saveSdkConfigurations(siteId) {
      if (!this.isFormDirty) {
        return;
      }
      if (this.initialSdkConfigurations === JSON.stringify(this.cachedSdkConfigurations)) {
        console.debug("SDK configurations not changed");
        return;
      }
      if (!this.isEdit && JSON.stringify(this.cachedSdkConfigurations) === "[]") {
        // Skip config request on add if sdk config is empty
        return;
      }
      await SdkConfigurationService.createSiteSdkConfigurations(siteId, this.siteTitle, this.cachedSdkConfigurations);
    },
    isTitleValid(value) {
      return ValidationHelpers.isSiteTitleValid(value.trim()) || this.$t(`${this.translationPath}invalid-title`);
    },
    isExternalIdentifierValid(value) {
      return (
        ValidationHelpers.isOptionalExternalIdentifierValid(value) ||
        this.$t("contents.validations.invalid-optional-external-identifier")
      );
    },
    setFormStatusClean() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
    },
    async deleteContent() {
      const response = await SiteService.deleteSite(this.$route.params.siteId);
      if (response?.createdTimestampUtcEpochSeconds) {
        await this.$store.dispatch("CONTENT/SET_SITES", { forceUpdate: true, clientId: this.currentClient });
        this.$store.dispatch("MAP/DISPLAY_SITE_SYMBOLS");
        this.$store.dispatch("MAP/DISPLAY_SITE_BORDERS");
        return true;
      }
      return false;
    },
    isSinglePolygon() {
      return this.mapDraw?.getAll()?.features?.[0]?.geometry?.type !== "MultiPolygon";
    },
    setFormDirty() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    emitValidationStatusForForm() {
      let isValid =
        this.isFormValid && this.drawnCoordinates && this.drawnCoordinates?.length !== 0 && !this.isMapBorderEnabled;
      this.$emit("valid", isValid);
    },
    clearGeometry() {
      this.$store.dispatch("MAP/HIDE_SITE_SYMBOLS");
      this.$store.dispatch("MAP/HIDE_SITE_BORDERS");
    },
    onSettingsClicked() {
      this.shouldDisplaySettingsPopup = true;
    },
    onSiteSettingsDiscard() {
      this.shouldDisplaySettingsPopup = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.row {
  &.topic {
    .col {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.v-expansion-panel,
.v-expansion-panel-header {
  cursor: pointer;
}
</style>
