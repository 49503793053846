const STATUSES = {
  NO_CHANGE: 0,
  READY_TO_STAGE: 1,
  STAGE_IN_PROGRESS: 2,
  REVIEW_READY: 3,
  PUBLISH_IN_PROGRESS: 4
};
Object.freeze(STATUSES);
export default class PreviewStatuses {
  static get NO_CHANGE() {
    return STATUSES.NO_CHANGE;
  }
  static get READY_TO_STAGE() {
    return STATUSES.READY_TO_STAGE;
  }
  static get STAGE_IN_PROGRESS() {
    return STATUSES.STAGE_IN_PROGRESS;
  }
  static get REVIEW_READY() {
    return STATUSES.REVIEW_READY;
  }
  static get PUBLISH_IN_PROGRESS() {
    return STATUSES.PUBLISH_IN_PROGRESS;
  }
}
