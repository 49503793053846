import vm from "../main";
import axios from "./PointrCloudAxios";
import Helpers from "@/helpers/Helpers";
import ToastHelpers from "@/helpers/ToastHelpers";
import AuthHelpers from "@/helpers/AuthHelpers";

export default class ClientService {
  static async getClients() {
    let response;
    let clients;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get("/clients");
    } catch (error) {
      AuthHelpers.logout();
      window.location.href = "/signin";
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    clients = response?.data?.results?.sort(Helpers.sortContent);
    if (!clients || clients?.length === 0) {
      AuthHelpers.logout();
      window.location.href = "/signin";
    }
    return clients;
  }

  static async getClient(clientInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/clients/${clientInternalIdentifier}`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.result;
  }

  static async createClient(client) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post("/clients", client);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        error?.response?.data?.error?.message || `${vm.$t("contents.fail-message")}`
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    ToastHelpers.createSuccessToast(`${client.clientTitle} ${vm.$t("contents.created")}`);
    return response?.data;
  }

  static async updateClient(client, clientInternalIdentifier, messageKey) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.patch(`/clients/${clientInternalIdentifier}`, client);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        error?.response?.data?.error?.message ||
          `${vm.$t(messageKey + ".failed")}` ||
          `${vm.$t("contents.fail-message")}`
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    ToastHelpers.createSuccessToast(
      messageKey ? `${vm.$t(messageKey)}` : `${client.clientTitle} ${vm.$t("contents.updated")}`
    );
    return response?.data;
  }

  static async deleteClient(clientInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.delete(`/clients/${clientInternalIdentifier}`);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        error?.response?.data?.error?.message || vm.$t(`delete-failed`)
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    ToastHelpers.createSuccessToast(`Client ${vm.$t("delete-success")}`);
    return response?.data;
  }

  static async activateAnalytics(clientInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(`/clients/${clientInternalIdentifier}/analytics/activate`);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        error?.response?.data?.error?.message || vm.$t(`analytics.activation-requested-fail`)
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data;
  }
}
