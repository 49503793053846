import vm from "../main";
import axios from "./PointrCloudAxios";
import Helpers from "@/helpers/Helpers";
import ToastHelpers from "@/helpers/ToastHelpers";

export default class BuildingService {
  static async getBuildings(siteInternalIdentifier) {
    let response;
    let buildings;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/sites/${siteInternalIdentifier}/buildings/draft`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    buildings = response?.data?.results?.sort(Helpers.sortContent);
    return buildings;
  }

  static async getBuilding(buildingInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/buildings/${buildingInternalIdentifier}/draft`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.result;
  }

  static async createBuilding(building, siteInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(`/sites/${siteInternalIdentifier}/buildings`, building);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        error?.response?.data?.error?.message || `${vm.$t("contents.fail-message")}`
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    ToastHelpers.createSuccessToast(`${building.buildingTitle} ${vm.$t("contents.created")}`);
    return response?.data;
  }

  static async updateBuilding(building, buildingInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.patch(`/buildings/${buildingInternalIdentifier}`, building);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        error?.response?.data?.error?.message || `${vm.$t("contents.fail-message")}`
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    ToastHelpers.createSuccessToast(`${building.buildingTitle} ${vm.$t("contents.updated")}`);
    return response?.data;
  }

  static async deleteBuilding(buildingInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.delete(`/buildings/${buildingInternalIdentifier}`);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        error?.response?.data?.error?.message || vm.$t(`delete-failed`)
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    ToastHelpers.createSuccessToast(`Building ${vm.$t("delete-success")}`);
    return response?.data;
  }
}
