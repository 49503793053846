<template>
  <div>
    <BuildingSiteSelectorContent
      :title="title"
      :subtitle="subtitle"
      :content-list="contentList"
      :content-name="currentMode"
      :selected-id="selectedId"
      :should-enable-back-btn="currentMode === modes.BUILDING"
      :should-show-select-btn="currentMode === modes.BUILDING"
      @content-item-selected="handleSelect"
      @back-clicked="handleBack"
    ></BuildingSiteSelectorContent>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BuildingSiteSelectorContent from "@/components/preview/BuildingSiteSelectorContent.vue";
import MapHelpers from "@/helpers/MapHelpers";

export default {
  components: {
    BuildingSiteSelectorContent
  },

  data: () => ({
    list: [],
    modes: {
      SITE: "Site",
      BUILDING: "Building"
    },
    currentMode: "Building"
  }),

  computed: {
    ...mapState("CONTENT", ["sites"]),
    ...mapState("PREVIEW_MAP", ["isMapReady", "levels", "currentSid", "currentBid", "map"]),
    selectedSite() {
      return this.sites.find((site) => site.siteInternalIdentifier === this.currentSid) || this.sites[0];
    },
    selectedBuilding() {
      return (
        this.selectedSite?.buildings?.find((building) => building.buildingInternalIdentifier === this.currentBid) ||
        this.selectedSite?.buildings?.[0]
      );
    },
    contentList() {
      if (this.currentMode === this.modes.SITE) {
        return this.sites.map((site) => ({ title: site.siteTitle, id: site.siteInternalIdentifier }));
      } else {
        return this.selectedSite?.buildings.map((building) => ({
          title: building.buildingTitle,
          id: building.buildingInternalIdentifier
        }));
      }
    },
    title() {
      if (this.currentMode === this.modes.SITE) {
        return "No site selected";
      } else {
        return this.selectedSite?.siteTitle || "No site selected";
      }
    },
    subtitle() {
      if (this.currentMode === this.modes.SITE) {
        return "";
      } else {
        return this.selectedBuilding?.buildingTitle || "No building selected";
      }
    },
    selectedId() {
      if (this.currentMode === this.modes.SITE) {
        return this.currentSid || this.sites[0]?.siteInternalIdentifier;
      } else {
        return this.currentBid || this.sites[0]?.buildings?.[0]?.buildingInternalIdentifier;
      }
    }
  },

  watch: {
    isMapReady: {
      immediate: true,
      handler() {
        if (this.isMapReady) {
          this.initialize();
        }
      }
    },
    selectedBuilding() {
      MapHelpers.fitBounds(this.selectedBuilding?.geometry?.coordinates?.[0], { map: this.map });
    }
  },

  beforeDestroy() {
    this.$store.commit("PREVIEW_MAP/CURRENT_SITE_INTERNAL_IDENTIFIER", undefined);
    this.$store.commit("PREVIEW_MAP/CURRENT_BUILDING_INTERNAL_IDENTIFIER", undefined);
  },

  methods: {
    initialize() {
      MapHelpers.fitBounds(this.selectedBuilding?.geometry?.coordinates?.[0], { map: this.map });
      this.$store.commit("PREVIEW_MAP/CURRENT_SITE_INTERNAL_IDENTIFIER", this.sites?.[0]?.siteInternalIdentifier);
      this.$store.commit(
        "PREVIEW_MAP/CURRENT_BUILDING_INTERNAL_IDENTIFIER",
        this.sites?.[0]?.buildings?.[0]?.buildingInternalIdentifier
      );
      this.$store.commit("PREVIEW_MAP/CURRENT_LEVEL", this.sites?.[0]?.buildings?.[0]?.levels?.[0]?.levelIndex);
    },
    handleSelect(id) {
      if (this.currentMode === this.modes.SITE) {
        this.$store.commit("PREVIEW_MAP/CURRENT_SITE_INTERNAL_IDENTIFIER", id);
        if (this.selectedSite?.buildings.length) {
          this.$store.commit(
            "PREVIEW_MAP/CURRENT_BUILDING_INTERNAL_IDENTIFIER",
            this.selectedSite?.buildings[0].buildingInternalIdentifier
          );
          this.$store.commit("PREVIEW_MAP/CURRENT_LEVEL", this.selectedSite?.buildings?.[0]?.levels?.[0]?.levelIndex);
        }
        this.currentMode = this.modes.BUILDING;
      } else {
        this.$store.commit("PREVIEW_MAP/CURRENT_BUILDING_INTERNAL_IDENTIFIER", id);
        const building = this.selectedSite.buildings.find((building) => building.buildingInternalIdentifier === id);
        this.$store.commit("PREVIEW_MAP/CURRENT_LEVEL", building?.levels?.[0]?.levelIndex);
      }
    },
    handleBack() {
      if (this.currentMode === this.modes.BUILDING) {
        this.currentMode = this.modes.SITE;
      }
    }
  }
};
</script>
