import Helpers from "@/helpers/Helpers";

export default [
  //   SITES
  {
    path: "clients/:clientId/:contentType(sites)/:siteId/edit",
    name: "SiteEdit",
    component: Helpers.lazyLoad("SitesView", "views/content/sites"),
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/:contentType(sites)/:siteId(add)",
    name: "SiteAdd",
    components: { default: Helpers.lazyLoad("SitesView", "views/content/sites") },
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/:siteId?",
    name: "Sites",
    component: Helpers.lazyLoad("SitesView", "views/content/sites"),
    meta: { requiresAuth: true }
  }
];
