import vm from "../main";
import axios from "./PointrCloudAxios";

export default class TaxonomyService {
  static async getTaxonomy() {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get("/featureTypes");
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    let dictByTypeCode = {};
    response?.data?.results?.forEach((type) => {
      dictByTypeCode[type.code] = type;
    });
    return dictByTypeCode;
  }
}
