import Helpers from "@/helpers/Helpers";

export default [
  //   LEVELS
  {
    path: "clients/:clientId/sites/:siteId/buildings/:buildingId/:contentType(\\levels)/:levelId/edit",
    name: "LevelEdit",
    component: Helpers.lazyLoad("LevelsView", "views/content/levels"),
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/:siteId/buildings/:buildingId/levels/:levelId/edit/download",
    name: "LevelEditDownload",
    component: Helpers.lazyLoad("LevelAddEditDownloadView", "views/content/levels"),
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/:siteId/buildings/:buildingId/:contentType(\\levels)/:levelId/add",
    name: "LevelAdd",
    component: Helpers.lazyLoad("LevelsView", "views/content/levels"),
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/:siteId/buildings/:buildingId/:contentType(\\levels)/:levelId?",
    name: "Levels",
    component: Helpers.lazyLoad("LevelsView", "views/content/levels"),
    meta: { requiresAuth: true }
  }
];
