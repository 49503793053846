import Helpers from "@/helpers/Helpers";

export default [
  //   CLIENTS
  {
    path: "clients/:clientId/edit",
    name: "ClientEdit",
    component: Helpers.lazyLoad("ClientAddEditView", "views/content/clients"),
    children: [
      {
        path: "advanced-configurations",
        name: "EditClientAdvancedConfigurations",
        component: Helpers.lazyLoad("AdvancedSdkClientConfigs", "views/content"),
        meta: { requiresAuth: true }
      }
    ],
    meta: { requiresAuth: true }
  },
  // TODO: Uncomment when multi client is implemented
  // {
  //   path: "clients/new",
  //   name: "ClientAdd",
  //   component: Helpers.lazyLoad("ClientAddEditView", "views/content/clients"),
  //   children: [
  //     {
  //       path: "advanced-configurations",
  //       name: "AddClientAdvancedConfigurations",
  //       component: Helpers.lazyLoad("AdvancedSdkClientConfigs", "views/content"),
  //       meta: { requiresAuth: true }
  //     }
  //   ],
  //   meta: { requiresAuth: true }
  // },
  {
    path: "clients/:clientId?",
    name: "Clients",
    component: Helpers.lazyLoad("ClientsView", "views/content/clients"),
    meta: { requiresAuth: true }
  }
];
