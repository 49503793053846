const TRANSITION_TYPES = {
  "custom-transition": {
    title: "Custom Transition",
    code: "custom-transition",
    properties: {
      class: "graph",
      customField: "disabled",
      iconImage: "unavailable",
      image: "unavailable",
      isDescriptionEnabled: "optional",
      isGeoJsonEnabled: "false",
      isMapObject: "false",
      isMultiLocation: "false",
      isPortalAccessible: "true",
      isPortalComfortable: "true",
      isSearchable: "false",
      isTitleEnabled: "optional",
      isWalkable: "true",
      isZoomLevelConfigurable: "false",
      keyword: "unavailable",
      mapDesignerDisplayMode: "visible",
      mobileDisplayMode: "viewOnly",
      portalDirection: "bidirectional",
      portalFloor: "multiplexAligned",
      portalTravelTime: "0",
      shape: ["point"],
      webDisplayMode: "viewOnly",
      categories: ["Core"]
    }
  },
  "lift-node": {
    title: "Elevator Node",
    code: "lift-node",
    properties: {
      class: "graph",
      customField: "disabled",
      iconImage: "unavailable",
      image: "unavailable",
      isDescriptionEnabled: "optional",
      isGeoJsonEnabled: "false",
      isMapObject: "false",
      isMultiLocation: "false",
      isPortalAccessible: "true",
      isPortalComfortable: "true",
      isSearchable: "false",
      isTitleEnabled: "optional",
      isWalkable: "true",
      isZoomLevelConfigurable: "false",
      keyword: "unavailable",
      mapDesignerDisplayMode: "visible",
      mobileDisplayMode: "viewOnly",
      portalDirection: "bidirectional",
      portalFloor: "multiplexAligned",
      portalTravelTime: "120",
      shape: ["point"],
      webDisplayMode: "viewOnly",
      categories: ["Core"]
    }
  },
  "stairs-node": {
    title: "Stairs Node",
    code: "stairs-node",
    properties: {
      class: "graph",
      customField: "disabled",
      iconImage: "unavailable",
      image: "unavailable",
      isDescriptionEnabled: "optional",
      isGeoJsonEnabled: "false",
      isMapObject: "false",
      isMultiLocation: "false",
      isPortalAccessible: "false",
      isPortalComfortable: "false",
      isSearchable: "false",
      isTitleEnabled: "optional",
      isWalkable: "true",
      isZoomLevelConfigurable: "false",
      keyword: "unavailable",
      mapDesignerDisplayMode: "visible",
      mobileDisplayMode: "viewOnly",
      portalDirection: "bidirectional",
      portalFloor: "multiplexAligned",
      portalTravelTime: "60",
      shape: ["point"],
      webDisplayMode: "viewOnly",
      categories: ["Core"]
    }
  },
  "escalator-node": {
    title: "Escalator Node",
    code: "escalator-node",
    properties: {
      class: "graph",
      customField: "disabled",
      iconImage: "unavailable",
      image: "unavailable",
      isDescriptionEnabled: "optional",
      isGeoJsonEnabled: "false",
      isMapObject: "false",
      isMultiLocation: "false",
      isPortalAccessible: "false",
      isPortalComfortable: "true",
      isSearchable: "false",
      isTitleEnabled: "optional",
      isWalkable: "true",
      isZoomLevelConfigurable: "false",
      keyword: "unavailable",
      mapDesignerDisplayMode: "visible",
      mobileDisplayMode: "viewOnly",
      portalDirection: "unidirectional",
      portalFloor: "duplexNonAligned",
      portalTravelTime: "45",
      shape: ["point"],
      webDisplayMode: "viewOnly",
      categories: ["Core"]
    }
  },
  "building-entrance-exit": {
    title: "Building Entrance-Exit",
    code: "building-entrance-exit",
    properties: {
      class: "graph",
      customField: "disabled",
      iconImage: "unavailable",
      image: "unavailable",
      isDescriptionEnabled: "optional",
      isGeoJsonEnabled: "false",
      isMapObject: "false",
      isMultiLocation: "false",
      isPortalAccessible: "true",
      isPortalComfortable: "true",
      isSearchable: "false",
      isTitleEnabled: "optional",
      isWalkable: "true",
      isZoomLevelConfigurable: "false",
      keyword: "unavailable",
      mapDesignerDisplayMode: "visible",
      mobileDisplayMode: "viewOnly",
      portalDirection: "bidirectional",
      portalFloor: "duplexNonAligned",
      portalTravelTime: "1",
      shape: ["point"],
      webDisplayMode: "viewOnly",
      categories: [""]
    }
  }
};

const NON_CUSTOM_TYPES = ["escalator-node", "lift-node", "stairs-node", "building-entrance-exit"];

export default class TransitionTaxonomy {
  static get TRANSITION_TYPES() {
    return TRANSITION_TYPES;
  }

  static get NON_CUSTOM_TYPES() {
    return NON_CUSTOM_TYPES;
  }
}
