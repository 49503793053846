<template>
  <v-expansion-panels v-model="defaultOpenPanelIndex" class="content-add-edit-expansion-panels" flat>
    <v-expansion-panel active-class="expanded" class="mt-0" :disabled="isMapBorderEnabled">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="information" />
        <div class="expansion-panel-header-text">{{ $t("contents.mapDesigner.basic-information") }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="mb-2">
        <v-form ref="globalGeofenceForm" v-model="isFormValid" class="mt-2">
          <v-row>
            <v-col class="py-1">
              <v-text-field
                id="global-geofence-title"
                v-model="name"
                :rules="[rules.required, rules.characterValidation]"
                :label="$t(`${translationPath}name`)"
                outlined
                hide-details="auto"
                dense
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-1">
              <v-text-field
                id="global-geofence-external-id"
                v-model="eid"
                :label="$t(`${translationPath}eid`)"
                outlined
                hide-details="auto"
                dense
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
          <GeometrySection
            ref="geometrySection"
            :feature-id="featureId"
            :should-show-polygon-icon="false"
            :should-show-point-icon="false"
            :is-edit="isEdit"
            is-circle-enabled
            should-disable-cut
            should-disable-modify
            @clear="clearGeometry"
          ></GeometrySection>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel active-class="expanded" class="mt-0" :disabled="isMapBorderEnabled">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="custom-integration" />
        <div class="expansion-panel-header-text">{{ $t(`${translationPath}custom-integration`) }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <CustomIntegration
          ref="customIntegration"
          :extra-data-prop="extraData"
          :feature-id="featureId"
          @integrationUpdated="setFormDirty"
          @setFormDirty="setFormDirty"
        ></CustomIntegration>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <slot name="danger-zone"></slot>
  </v-expansion-panels>
</template>

<script>
import { mapState } from "vuex";
import GeometrySection from "@/components/mapDesigner/GeometrySection.vue";
import PtrIcon from "@/components/shared/PtrIcon";
import CustomIntegration from "@/components/shared/CustomIntegration.vue";
import ContentService from "@/services/ContentService";
import ValidationHelpers from "@/helpers/ValidationHelpers";
import MapHelpers from "@/helpers/MapHelpers";
import MapModeConstants from "@/constants/mapModeConstants";
export default {
  name: "GlobalGeofenceAddEditView",
  components: { PtrIcon, GeometrySection, CustomIntegration },
  props: {
    featureId: [String, Number]
  },
  data: () => ({
    translationPath: "contents.global-geofence.",
    globalGeofence: {},
    isEdit: false,
    isFormValid: false,
    rules: {
      required: (value) => ValidationHelpers.isRequired(value),
      characterValidation: (value) => {
        return ValidationHelpers.isNameLengthValid(value);
      }
    },
    name: "",
    eid: "",
    extraData: {},
    defaultOpenPanelIndex: 0
  }),
  computed: {
    drawnCoordinates: {
      get() {
        return this.$store.state.MAP.drawnCoordinates;
      },
      set(value) {
        this.$store.commit("MAP/DRAWN_COORDINATES", value);
      }
    },
    ...mapState("MAP", ["isMapReady", "mapDraw", "currentClient", "isMapBorderEnabled"]),
    ...mapState("CONTENT", ["isFormDirty", "globalGeofences"]),
    ...mapState("ADVANCED_CONFIGS", ["cachedExtraData"])
  },
  watch: {
    $route() {
      this.setMapUI();
    },
    featureId: {
      immediate: true,
      handler() {
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: undefined, shouldKeepFormClean: true });
        this.$store.commit("MAP/DRAWN_COORDINATES", undefined);
        this.globalGeofence = this.globalGeofences.find(
          (globalGeofence) => globalGeofence.properties.fid === this.featureId?.toString()
        );
        if (this.globalGeofence) {
          this.isEdit = true;
          this.name = this.globalGeofence.properties.name;
          this.eid = this.globalGeofence.properties.eid;
          this.extraData = this.globalGeofence.properties.extra;
          this.$store.commit("MAP/DRAWN_COORDINATES", this.globalGeofence.geometry.coordinates);
          this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", {
            feature: this.globalGeofence,
            shouldKeepFormClean: true
          });
        } else if (this.featureId === "add") {
          this.$refs.globalGeofenceForm?.resetValidation();
          this.isEdit = false;
          this.name = "";
          this.eid = "";
          this.extraData = {};
        }
      }
    },
    drawnCoordinates() {
      this.emitValidationStatusForForm();
    },
    isFormValid() {
      this.emitValidationStatusForForm();
    },
    isMapBorderEnabled() {
      this.emitValidationStatusForForm();
    }
  },
  beforeDestroy() {
    MapHelpers.disableMapInteractions();
    this.$store.commit("MAP/MAP_MODE", MapModeConstants.EMPTY_MODE);
    this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: undefined, shouldKeepFormClean: true });
    this.$store.dispatch("MAP/HIDE_SITE_SYMBOLS");
    this.$store.dispatch("MAP/HIDE_SITE_BORDERS");
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
  },
  created() {
    MapHelpers.enableMapInteractions();
    this.setMapUI();
  },
  methods: {
    setMapUI() {
      this.$store.commit("MAP/SHOW_UI_ICON", { iconName: "zoom" });
    },
    goBack() {
      this.$router.push({ name: "GlobalGeofences" }).catch((e) => console.log(e.message));
    },
    setFormStatusClean() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
    },
    async save() {
      let coordinates;
      try {
        coordinates = JSON.parse(this.drawnCoordinates || "[]");
      } catch (e) {
        coordinates = this.drawnCoordinates;
      }
      let extraData = this.getExtraData();
      const request = {
        type: "Feature",
        properties: {
          typeCode: "global-geofence", // Get from taxonomy?
          fid: this.globalGeofence?.properties?.fid,
          eid: this.eid,
          cid: this.currentClient,
          name: this.name.trim(),
          extra: extraData,
          style: {}
        },
        geometry: {
          type: "Polygon",
          coordinates
        }
      };
      const response = await ContentService.putFeature(request, "global_geofences", this.isEdit);
      if (response?.createdTimestampUtcEpochSeconds) {
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        this.$router.push({ name: "GlobalGeofences" }).catch((e) => console.log(e.message));
      }
    },
    async deleteContent() {
      const response = await ContentService.deleteFeature(this.globalGeofence);
      if (response?.createdTimestampUtcEpochSeconds) {
        await this.$store.dispatch("CONTENT/SET_GLOBAL_GEOFENCES", { forceUpdate: true, clientId: this.currentClient });
        this.$store.dispatch("MAP/ADD_MAP_OBJECTS_TO_MAP", this.globalGeofences);
        this.$router.push({ name: "GlobalGeofences" }).catch((e) => console.log(e.message));
        return true;
      }
      return false;
    },
    setFormDirty() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    emitValidationStatusForForm() {
      let isValid =
        this.isFormValid && this.drawnCoordinates && this.drawnCoordinates?.length !== 0 && !this.isMapBorderEnabled;
      this.$emit("valid", isValid);
    },
    clearGeometry() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
      const filteredGlobalGeofences = this.globalGeofences.filter((geofence) => {
        return geofence.properties.fid !== this.globalGeofence.properties.fid;
      });
      this.$store.dispatch("MAP/ADD_MAP_OBJECTS_TO_MAP", filteredGlobalGeofences);
    },
    getExtraData() {
      let extraData = this.$refs?.customIntegration?.getExtraData();
      if (!extraData && this.feature) {
        extraData = this.feature?.properties?.extra || this.feature?.properties?.extraData;
      }
      return extraData;
    }
  }
};
</script>
<style lang="scss" scoped>
.row {
  &.topic {
    .col {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
