const VALUE_DATA_TYPES = [
  { label: "String", index: 0, default: "" },
  { label: "Boolean", index: 1, default: false },
  { label: "Integer", index: 2, default: 0 },
  { label: "Float", index: 3, default: 0 },
  { label: "Double", index: 4, default: 0 }
];

export default class WebConfigurationParameterValueDataType {
  static get VALUE_DATA_TYPES() {
    return VALUE_DATA_TYPES;
  }
}
