<template>
  <v-form ref="typeForm" v-model="isAutoCompleteValid" class="mt-2 mb-3">
    <v-row>
      <v-col class="py-1">
        <v-autocomplete
          id="content-type-input"
          v-model="selectedLevelType"
          :items="levelTypeList"
          outlined
          dense
          :label="$t(`${translationPath}type`)"
          hide-details="auto"
          :menu-props="{ closeOnContentClick: true }"
          :rules="[autoCompleteRequired]"
          @change="(item) => onTypeChanged(item)"
        >
          <template #append>
            <PtrIcon icon="caret-down" />
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";
export default {
  name: "LevelTypeSelectionCom",
  components: { PtrIcon },
  props: {
    levelType: String
  },
  data: () => ({
    translationPath: "contents.levels.",
    levelTypes: {
      Workplace: [{ text: "Office", value: "office" }],
      Healthcare: [
        { text: "Hospital", value: "hospital" },
        { text: "Clinic", value: "clinic" },
        { text: "Pharmacy", value: "pharmacy" }
      ],
      Retail: [
        { text: "Department Store", value: "department store" },
        { text: "Convenience/Grocery Store", value: "convenience/grocery store" },
        { text: "Mall", value: "mall" },
        { text: "Restaurant/Shop", value: "restaurant/shop" }
      ],
      Aviation: [{ text: "Airport", value: "airport" }],
      Education: [
        { text: "University", value: "university" },
        { text: "High School", value: "high school" }
      ],
      "Public Transportation": [
        { text: "High School", value: "high school" },
        { text: "Subway/Train Station", value: "subway/train station" },
        { text: "Bus Station", value: "bus station" }
      ],
      Hospitality: [
        { text: "Hotel", value: "hotel" },
        { text: "Stadium / Sports Center", value: "stadium / sports center" },
        { text: "Museum / Art Center", value: "museum / art center" },
        { text: "Theme Park", value: "theme park" },
        { text: "Zoo", value: "zoo" }
      ],
      Hall: [
        { text: "Exhibition", value: "exhibition" },
        { text: "Conference", value: "conference" }
      ],
      Industrial: [
        { text: "Factory", value: "factory" },
        { text: "Mine", value: "mine" },
        { text: "Warehouse", value: "warehouse" }
      ],
      Others: [
        { text: "Parking Garage", value: "parking garage" },
        { text: "Other", value: "other" }
      ]
    },
    selectedLevelType: {},
    isAutoCompleteValid: false
  }),
  computed: {
    levelTypeList() {
      let types = [];
      Object.keys(this.levelTypes).forEach((category) => {
        types = [
          ...types,
          {
            header: category
          },
          ...this.levelTypes[category]
        ];
      });
      return types;
    }
  },
  watch: {
    levelType() {
      this.setTypeObject(this.levelType);
    }
  },
  created() {
    this.setTypeObject(this.levelType);
  },
  methods: {
    autoCompleteRequired(value) {
      if (value === null || value === undefined) {
        this.isAutoCompleteValid = false;
        return this.$t("contents.validations.type-must-be-selected");
      }
      this.isAutoCompleteValid = true;
      return true;
    },
    onTypeChanged(type) {
      this.$emit("typeSelected", type);
    },
    setTypeObject(type) {
      this.selectedLevelType = Object.values(this.levelTypes)
        .flat()
        .find((levelType) => levelType.value === type);
    }
  }
};
</script>
