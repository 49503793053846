<template>
  <v-row class="mt-0 mb-2">
    <v-col class="input-col py-0 grow" cols="auto">
      <ImageInput
        v-if="currentProperty.valueType === 'image'"
        ref="imageInputComponent"
        v-model="value"
        :property="currentProperty"
        :input-type="currentProperty.inputType"
        :aspect-ratio="currentProperty.aspectRatio"
        @isFileValid="(value) => $emit('isCustomInputValid', value, name)"
      />
      <OpeningHours v-else-if="currentProperty.key === 'openHours'" v-model="value"></OpeningHours>
      <PhoneInput
        v-else-if="currentProperty.key === 'phoneNumber'"
        v-model="value"
        @valid="(value) => $emit('isCustomInputValid', value, name)"
      ></PhoneInput>
      <PriceInput v-else-if="currentProperty.key === 'price'" v-model="value"></PriceInput>
      <OverridableButtonInput
        v-else-if="currentProperty.key === 'book' || currentProperty.key === 'order'"
        v-model="value"
        :property-key="currentProperty.key"
      ></OverridableButtonInput>
      <RatingInput v-else-if="currentProperty.key === 'rating'" v-model="value"></RatingInput>
      <v-textarea
        v-else-if="currentProperty.inputType === 'textarea'"
        :id="name"
        v-model="value"
        :label="$t(`${featureTranslationPath}${name}`)"
        :rules="[rules[name]]"
        hide-details="auto"
        outlined
        dense
        rows="1"
        auto-grow
        @keydown="setFormDirty"
      >
        <template #append>
          <PtrIcon
            v-if="value?.length"
            class="pa-1"
            icon="cancel-circle"
            width="18"
            height="18"
            style="cursor: pointer"
            @click.native="resetValue"
          />
        </template>
      </v-textarea>
      <v-combobox
        v-else-if="currentProperty.inputType === 'combobox'"
        :id="name"
        ref="combobox"
        v-model="value"
        :label="$t(`${featureTranslationPath}${name}`)"
        :rules="[rules[name] === undefined ? rules.required : rules[name]]"
        outlined
        hide-details="auto"
        dense
        small-chips
        multiple
        append-icon=""
        @keydown="setFormDirty"
      >
        <template #selection="data">
          <v-chip
            :key="JSON.stringify(data.item)"
            color="neutral-darken3"
            outlined
            class="keyword-chip mt-1"
            small
            v-bind="data.attrs"
            close
            close-icon="$close"
            :input-value="data.selected"
            :disabled="data.disabled"
            @click:close="() => onChipDeleted(data)"
          >
            <span class="text-truncate">
              {{ data.item }}
            </span>
          </v-chip>
        </template>
      </v-combobox>
      <v-text-field
        v-else
        :id="name"
        v-model.trim="value"
        :label="$t(`${featureTranslationPath}${name}`)"
        :rules="[rules[name] === undefined ? rules.required : rules[name]]"
        hide-details="auto"
        outlined
        dense
        @keydown="setFormDirty"
      >
        <template #append>
          <PtrIcon
            v-if="value?.length"
            class="pa-1"
            icon="cancel-circle"
            width="18"
            height="18"
            style="cursor: pointer"
            @click.native="resetValue"
          />
        </template>
      </v-text-field>
    </v-col>
    <v-col class="ml-2 delete-icon-container py-0">
      <v-btn class="px-0 delete-property" plain @click="deleteProperty">
        <PtrIcon width="16" height="20" icon="thrash"></PtrIcon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";
import ValidationHelpers from "@/helpers/ValidationHelpers";
import ImageInput from "@/components/mapDesigner/ImageInput.vue";
import OpeningHours from "@/components/mapDesigner/OpeningHours.vue";
import PhoneInput from "@/components/mapDesigner/PhoneInput.vue";
import PriceInput from "@/components/mapDesigner/PriceInput.vue";
import OverridableButtonInput from "@/components/mapDesigner/OverridableButtonInput.vue";
import RatingInput from "@/components/mapDesigner/RatingInput.vue";
import poiProperties from "@/constants/poiProperties";

export default {
  components: { PtrIcon, ImageInput, OpeningHours, PhoneInput, PriceInput, OverridableButtonInput, RatingInput },
  props: {
    name: String,
    initialValue: [String, Number, Array, File, Object]
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    featureTranslationPath: "contents.feature.",
    value: undefined
  }),
  computed: {
    properties() {
      return poiProperties.POI_PROPERTIES;
    },
    currentProperty() {
      return this.properties.find((property) => property.key === this.name);
    },
    rules() {
      return {
        description: (value) => {
          return ValidationHelpers.isRequired(value);
        },
        keywords: (value) => {
          if (value.length > 0) {
            return true;
          } else {
            return "Required";
          }
        },
        tags: (value) => {
          if (value.length > 0) {
            return true;
          } else {
            return "Required";
          }
        },
        eid: (value) => {
          return ValidationHelpers.isRequired(value);
        },
        required: (value) => ValidationHelpers.isRequired(value),
        email: (value) => {
          if (value === undefined || value === "") {
            return this.$t(`${this.translationPath}propertyRequired`, { propertyName: "Email" });
          }
          return (
            ValidationHelpers.isEmailValid(value) ||
            this.$t(`${this.translationPath}notValid`, { propertyName: "Email" })
          );
        },
        website: (value) => {
          if (value === undefined || value === "") {
            return this.$t(`${this.translationPath}propertyRequired`, { propertyName: "Website" });
          }
          return (
            ValidationHelpers.isUrlValid(value) ||
            this.$t(`${this.translationPath}notValid`, { propertyName: "Website" })
          );
        },
        menu: (value) => {
          if (value === undefined || value === "") {
            return this.$t(`${this.translationPath}propertyRequired`, { propertyName: "Menu" });
          }
          return (
            ValidationHelpers.isUrlValid(value) || this.$t(`${this.translationPath}notValid`, { propertyName: "Menu" })
          );
        }
      };
    }
  },
  watch: {
    value() {
      this.$emit("propertyUpdated", this.name, this.value);
    },
    initialValue() {
      this.value = this.initialValue;
    }
  },
  created() {
    this.value = this.initialValue;
  },

  methods: {
    setFormDirty() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    deleteProperty() {
      this.$emit("propertyDeleted", this.name);
      this.setFormDirty();
    },
    /**
     * Updates some inputs to which user might enter some value as input but does not press enter afterwards
     * So that they are saved successfully
     */
    updateInputs() {
      this.$refs.combobox?.blur();
      this.$refs.imageInputComponent?.update();
    },
    resetValue() {
      this.value = undefined;
    },
    onChipDeleted(data) {
      data.parent.selectItem(data.item);
      this.setFormDirty();
    }
  }
};
</script>
<style lang="scss" scoped>
.delete-icon-container {
  max-width: min-content;
}
.input-col {
  max-width: 248px !important;
  width: 248px !important;
}

.delete-property {
  min-width: 24px !important;
}
::v-deep {
  .v-chip {
    color: var(--v-neutral-darken3);
    font-size: #{$font-size-1};
    line-height: #{$line-height-xs};
  }

  .mdi-close::before {
    color: var(--v-neutral-darken3) !important;
    font-size: #{$font-size-6};
    font-family: "Readex Pro", sans-serif;
    content: "+";

    font-weight: #{$font-weight-light};
    transform: rotate(45deg) translateY(-1px);
  }
}

::v-deep .v-textarea textarea {
  line-height: 1rem;
  margin-top: 12px !important;
  margin-bottom: 6px;
}
</style>
