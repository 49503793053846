<template>
  <v-form v-model="isFormValid" class="mt-2 mb-3">
    <v-row>
      <div class="mt-2 mb-3 geometry-sub-title">
        {{ isEditModeActive ? $t(`${translationPath}adjust-position`) : $t(`${translationPath}edit-position`) }}
      </div>
    </v-row>
    <v-row v-if="isEditModeActive" class="mb-1">
      <v-switch
        v-model="isAnchorsLocked"
        class="mt-0"
        :label="$t(`${translationPath}anchors-locked`)"
        :color="isAnchorsLocked ? 'neutral' : 'primary'"
        hide-details
        inset
      ></v-switch>
    </v-row>
    <v-row>
      <v-col cols="2">
        <div
          class="anchor"
          :class="{
            'primary-color': isEditModeActive && isAnchorsLocked,
            'dashed-border': isEditModeActive && !isAnchorsLocked
          }"
        >
          A
        </div>
      </v-col>
      <v-col class="mr-2">
        <v-text-field
          id="anchor-a-lat"
          v-model="latA"
          :label="$t(`${translationPath}latitude`)"
          :rules="[rules.required, rules.hasLessThanNineDecimal]"
          hide-details
          dense
          :outlined="isEditModeActive"
          :disabled="!isEditModeActive"
        />
      </v-col>
      <v-col>
        <v-text-field
          id="anchor-a-lng"
          v-model="lngA"
          :label="$t(`${translationPath}longitude`)"
          :rules="[rules.required, rules.hasLessThanNineDecimal]"
          hide-details
          dense
          :outlined="isEditModeActive"
          :disabled="!isEditModeActive"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <div
          class="anchor"
          :class="{
            'primary-color': isEditModeActive && isAnchorsLocked,
            'dashed-border': isEditModeActive && !isAnchorsLocked
          }"
        >
          B
        </div>
      </v-col>
      <v-col class="mr-2">
        <v-text-field
          id="anchor-b-lat"
          v-model="latB"
          :label="$t(`${translationPath}latitude`)"
          :rules="[rules.required, rules.hasLessThanNineDecimal]"
          hide-details
          dense
          :outlined="isEditModeActive"
          :disabled="!isEditModeActive"
        />
      </v-col>
      <v-col>
        <v-text-field
          id="anchor-b-lng"
          v-model="lngB"
          :label="$t(`${translationPath}longitude`)"
          :rules="[rules.required, rules.hasLessThanNineDecimal]"
          hide-details
          dense
          :outlined="isEditModeActive"
          :disabled="!isEditModeActive"
        />
      </v-col>
    </v-row>
    <v-row v-if="!isEditModeActive">
      <v-btn id="edit-anchor-points" color="primary" block @click="setEditMode">
        {{ $t("edit") }}
      </v-btn>
    </v-row>
    <v-row v-else>
      <v-col class="mr-2">
        <v-btn class="edit-anchor-buttons" color="neutral" block @click="onCancelClicked">
          {{ $t("cancel") }}
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          class="edit-anchor-buttons"
          color="primary"
          :disabled="!isAnchorsUpdated || !isFormValid"
          block
          @click="setAnchors"
        >
          {{ $t("set") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import { Marker } from "maplibre-gl";
import { point } from "@turf/helpers";
import distance from "@turf/distance";
import ValidationHelpers from "@/helpers/ValidationHelpers";
import MapHelpers from "@/helpers/MapHelpers";

export default {
  name: "AnchorPointInputs",
  props: {
    levelType: String,
    anchorFeatures: Array,
    allFeatures: Array
  },
  data: () => ({
    translationPath: "contents.levels.",
    isFormValid: true,
    latA: undefined,
    lngA: undefined,
    latB: undefined,
    lngB: undefined,
    isEditModeActive: false,
    isAnchorsLocked: true,
    anchorMarkers: [],
    ghostMarkers: [],
    referencePointForA: {},
    referencePointForB: {},
    shouldChangeA: false,
    shouldChangeB: false,
    anchorsGeojson: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: []
          }
        },
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: []
          }
        }
      ]
    }
  }),
  computed: {
    rules() {
      return {
        required: (value) => ValidationHelpers.isRequired(value),
        hasLessThanNineDecimal: (value) => ValidationHelpers.hasLessThanNineDecimal(value)
      };
    },
    isAnchorsUpdated() {
      const initialLatA = this.anchorFeatures[0].geometry.coordinates[1];
      const initialLngA = this.anchorFeatures[0].geometry.coordinates[0];
      const initialLatB = this.anchorFeatures[1].geometry.coordinates[1];
      const initialLngB = this.anchorFeatures[1].geometry.coordinates[0];
      return (
        initialLatA !== this.latA || initialLngA !== this.lngA || initialLatB !== this.latB || initialLngB !== this.lngB
      );
    },
    ...mapGetters("MAP", ["map", "currentSiteObject"])
  },
  watch: {
    isFormValid() {
      this.$emit("valid", this.isFormValid);
    },
    isAnchorsLocked() {
      const markers = document.getElementsByClassName("anchor-marker");
      if (!markers?.length) {
        return;
      }
      if (!this.isAnchorsLocked) {
        const emptyGeoJsonForAnchors = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: []
              }
            },
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: []
              }
            }
          ]
        };
        Array.from(markers)?.forEach((marker) => marker.classList?.add("dashed-border"));
        this.map.getSource("anchors-dashed-line-source").setData(emptyGeoJsonForAnchors);
        this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.move-anchors"));
      } else {
        Array.from(markers)?.forEach((marker) => marker.classList?.remove("dashed-border"));
        this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.drag-anchors"));
        const coordinatesA = this.shouldChangeA ? [this.lngA, this.latA] : this.anchorFeatures[0].geometry.coordinates;
        const coordinatesB = this.shouldChangeB ? [this.lngB, this.latB] : this.anchorFeatures[1].geometry.coordinates;
        this.$emit("changeAnchors", [coordinatesA, coordinatesB]);
      }
      this.$emit("anchorsLocked", this.isAnchorsLocked);
    },
    isEditModeActive() {
      this.$emit("isAnchorEditModeActive", this.isEditModeActive);
      const markers = document.getElementsByClassName("anchor-marker");
      if (!markers?.length) {
        return;
      }
      if (this.isEditModeActive) {
        this.anchorMarkers.forEach((marker) => marker.setDraggable(true));
        Array.from(markers)?.forEach((marker) => marker.classList?.add("primary-color"));
        this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
      } else {
        this.anchorMarkers.forEach((marker) => marker.setDraggable(false));
        Array.from(markers)?.forEach((marker) => marker.classList?.remove("primary-color"));
        this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
        this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
      }
    },
    latA() {
      this.anchorMarkers[0].setLngLat([Number(this.lngA), Number(this.latA)]);
      // update coordinate of anchor Marker A and then set data to map
      if (this.isAnchorsLocked) {
        this.anchorsGeojson.features[0].geometry.coordinates[1] = [this.lngA, this.latA];
        this.map.getSource("anchors-dashed-line-source").setData(this.anchorsGeojson);
      }
    },
    lngA() {
      this.anchorMarkers[0].setLngLat([Number(this.lngA), Number(this.latA)]);
      // update coordinate of anchor Marker A and then set data to map
      if (this.isAnchorsLocked) {
        this.anchorsGeojson.features[0].geometry.coordinates[1] = [this.lngA, this.latA];
        this.map.getSource("anchors-dashed-line-source").setData(this.anchorsGeojson);
      }
    },
    latB() {
      this.anchorMarkers[1].setLngLat([Number(this.lngB), Number(this.latB)]);
      if (this.isAnchorsLocked) {
        this.anchorsGeojson.features[1].geometry.coordinates[1] = [this.lngB, this.latB];
        this.map.getSource("anchors-dashed-line-source").setData(this.anchorsGeojson);
      }
    },
    lngB() {
      this.anchorMarkers[1].setLngLat([Number(this.lngB), Number(this.latB)]);
      if (this.isAnchorsLocked) {
        this.anchorsGeojson.features[1].geometry.coordinates[1] = [this.lngB, this.latB];
        this.map.getSource("anchors-dashed-line-source").setData(this.anchorsGeojson);
      }
    },
    anchorFeatures() {
      this.resetAnchorPoints();
    }
  },
  created() {
    this.anchorsGeojson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: []
          }
        },
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: []
          }
        }
      ]
    };
    this.anchorFeatures?.forEach((feature, index) => {
      let ghostMarkerElement = document.createElement("div");
      ghostMarkerElement.className = "ghost-anchor-marker";
      const ghostMarker = new Marker({ element: ghostMarkerElement, draggable: false }).setLngLat(
        feature.geometry.coordinates
      );
      // populate line geojson with ghost marker coordinates. index 0 is A index 1 is B
      if (index === 0) {
        this.anchorsGeojson.features[0].geometry.coordinates.push(feature.geometry.coordinates);
      } else {
        this.anchorsGeojson.features[1].geometry.coordinates.push(feature.geometry.coordinates);
      }
      ghostMarker.addTo(this.map);
      this.ghostMarkers.push(ghostMarker);
      let markerElement = document.createElement("div");
      markerElement.className = "anchor-marker";
      markerElement.id = index === 0 ? "anchor-a" : "anchor-b";
      markerElement.innerHTML = index === 0 ? "A" : "B";
      const marker = new Marker({ element: markerElement, draggable: false }).setLngLat(feature.geometry.coordinates);
      marker.addTo(this.map);
      this.anchorMarkers.push(marker);
      MapHelpers.addAnchorDashSourceAndLayer();
      if (index === 0) {
        // add anchor marker A's coordinate to line geojson
        this.anchorsGeojson.features[0].geometry.coordinates.push(feature.geometry.coordinates);
        marker.on("drag", (e) => {
          const { lng, lat } = e.target._lngLat;
          this.latA = Number(lat.toFixed(8));
          this.lngA = Number(lng.toFixed(8));
          // update coordinate of anchor Marker A and then set data to map
          if (this.isAnchorsLocked) {
            this.anchorsGeojson.features[0].geometry.coordinates[1] = [this.lngA, this.latA];
            this.map.getSource("anchors-dashed-line-source").setData(this.anchorsGeojson);
          }
        });
        marker.on("dragend", (e) => {
          const { lng, lat } = e.target._lngLat;
          this.referencePointForA.lat = Number(lat.toFixed(8));
          this.referencePointForA.lng = Number(lng.toFixed(8));
          this.shouldChangeA = true;
        });
        marker.getElement().addEventListener("click", () => {
          if (this.isAnchorsLocked || !this.isEditModeActive || Object.keys(this.referencePointForA).length === 0) {
            return;
          }
          const newAnchorAPoint = this.findNearestPoint(this.referencePointForA);
          this.lngA = newAnchorAPoint[0];
          this.latA = newAnchorAPoint[1];
          this.shouldChangeA = true;
        });
      } else {
        // add anchor marker B's coordinate to line geojson
        this.anchorsGeojson.features[1].geometry.coordinates.push(feature.geometry.coordinates);
        marker.on("drag", (e) => {
          const { lng, lat } = e.target._lngLat;
          this.latB = Number(lat.toFixed(8));
          this.lngB = Number(lng.toFixed(8));
          // update coordinate of anchor Marker B and then set data to map
          if (this.isAnchorsLocked) {
            this.anchorsGeojson.features[1].geometry.coordinates[1] = [this.lngB, this.latB];
            this.map.getSource("anchors-dashed-line-source").setData(this.anchorsGeojson);
          }
        });
        marker.on("dragend", (e) => {
          const { lng, lat } = e.target._lngLat;
          this.referencePointForB.lat = Number(lat.toFixed(8));
          this.referencePointForB.lng = Number(lng.toFixed(8));
          this.shouldChangeB = true;
        });
        marker.getElement().addEventListener("click", () => {
          if (this.isAnchorsLocked || !this.isEditModeActive || Object.keys(this.referencePointForB).length === 0) {
            return;
          }
          const newAnchorBPoint = this.findNearestPoint(this.referencePointForB);
          this.lngB = newAnchorBPoint[0];
          this.latB = newAnchorBPoint[1];
          this.shouldChangeB = true;
        });
      }
    });
    this.resetAnchorPoints();
  },
  beforeDestroy() {
    this.anchorMarkers.forEach((marker) => {
      marker.remove();
    });
    this.ghostMarkers.forEach((marker) => {
      marker.remove();
    });
    MapHelpers.removeAnchorDashSourceAndLayer();
  },
  methods: {
    setEditMode() {
      this.isEditModeActive = true;
      this.isAnchorsLocked = true;
      this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.drag-anchors"));
    },
    onCancelClicked() {
      this.isEditModeActive = false;
      this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
      this.resetAnchorPoints();
      this.shouldChangeA = false;
      this.shouldChangeB = false;
      const markers = document.getElementsByClassName("anchor-marker");
      if (!markers?.length) {
        return;
      }
      Array.from(markers)?.forEach((marker) => marker.classList?.remove("primary-color"));
      Array.from(markers)?.forEach((marker) => marker.classList?.remove("dashed-border"));
      this.map.getSource("anchors-dashed-line-source").setData({
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: []
            }
          },
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: []
            }
          }
        ]
      });
    },
    setAnchors() {
      if (this.isAnchorsLocked) {
        this.$emit("geotransform", [
          [Number(this.lngA), Number(this.latA)],
          [Number(this.lngB), Number(this.latB)]
        ]);
      } else {
        const coordinatesA = this.shouldChangeA
          ? [Number(this.lngA), Number(this.latA)]
          : this.anchorFeatures[0].geometry.coordinates;
        const coordinatesB = this.shouldChangeB
          ? [Number(this.lngB), Number(this.latB)]
          : this.anchorFeatures[1].geometry.coordinates;
        this.$emit("changeAnchors", [coordinatesA, coordinatesB]);
      }
      this.isEditModeActive = false;
      this.resetGhostMarkers();
      this.referencePointForA = {};
      this.referencePointForB = {};
      this.shouldChangeA = false;
      this.shouldChangeB = false;
      if (this.isAnchorsLocked) {
        this.map.getSource("anchors-dashed-line-source").setData({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: []
              }
            },
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: []
              }
            }
          ]
        });
      }
    },
    resetAnchorPoints() {
      this.latA = this.anchorFeatures[0].geometry.coordinates[1];
      this.lngA = this.anchorFeatures[0].geometry.coordinates[0];
      this.latB = this.anchorFeatures[1].geometry.coordinates[1];
      this.lngB = this.anchorFeatures[1].geometry.coordinates[0];
      // even though markers dragged, lat & lngs are not updated if anchor is unlocked
      this.anchorMarkers[0].setLngLat([this.lngA, this.latA]);
      this.anchorMarkers[1].setLngLat([this.lngB, this.latB]);
      // update anchor marker coordinates
      this.anchorsGeojson.features[0].geometry.coordinates[1] = [this.lngA, this.latA];
      this.anchorsGeojson.features[1].geometry.coordinates[1] = [this.lngB, this.latB];
      this.resetGhostMarkers();
      this.referencePointForA = {};
      this.referencePointForB = {};
    },
    resetGhostMarkers() {
      this.ghostMarkers[0].setLngLat([this.lngA, this.latA]);
      this.ghostMarkers[1].setLngLat([this.lngB, this.latB]);
      // update ghost marker coordinates
      this.anchorsGeojson.features[0].geometry.coordinates[0] = [this.lngA, this.latA];
      this.anchorsGeojson.features[1].geometry.coordinates[0] = [this.lngB, this.latB];
    },
    findNearestPoint(refPoint) {
      const turfPoint = point([refPoint.lng, refPoint.lat]);
      let nearestPoint;
      let nearestDistance = Infinity;
      this.allFeatures.forEach((feature) => {
        feature.geometry.coordinates.forEach((coordinate) => {
          if (coordinate?.[0]?.[0]) {
            coordinate.forEach((insideCoordinate) => {
              const geoPoint = point(insideCoordinate);
              const turfDistance = distance(turfPoint, geoPoint);
              if (turfDistance < nearestDistance) {
                nearestPoint = insideCoordinate;
                nearestDistance = turfDistance;
              }
            });
          } else if (coordinate.length == 2) {
            const geoPoint = point(coordinate);
            const turfDistance = distance(turfPoint, geoPoint);
            if (turfDistance < nearestDistance) {
              nearestPoint = coordinate;
              nearestDistance = turfDistance;
            }
          }
        });
      });
      return nearestPoint;
    }
  }
};
</script>

<style lang="scss" scoped>
.anchor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--v-neutral-base);
  color: var(--v-neutral-base);
  border-radius: 50%;

  &.primary-color {
    background-color: var(--v-primary-base);
    border-color: var(--v-primary-base);
    color: var(--v-white-base);
  }

  &.dashed-border {
    border: 1px dashed var(--v-primary-base);
    color: var(--v-primary-base);
  }
}

::v-deep .theme--light.v-text-field.v-input--is-disabled .v-input__slot::before {
  visibility: hidden;
}

.edit-anchor-buttons {
  color: var(--v-white-base);
}
</style>
