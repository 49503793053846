import ClientService from "@/services/ClientService";
import SiteService from "@/services/SiteService";
import BuildingService from "@/services/BuildingService";
import LevelService from "@/services/LevelService";
import ContentService from "@/services/ContentService";

import Helpers from "@/helpers/Helpers";
import MapHelpers from "@/helpers/MapHelpers";

const contentNameTypeCodeDict = {
  pois: "poiTypeCodesObj",
  mapObjects: "mapObjectsTypeCodesObj",
  obstacles: "obstaclesTypeCodesObj"
};

const filteredContentTypeCodeDict = {
  pois: "filteredPoiTypeCodeObjs",
  mapObjects: "filteredMapObjectsTypeCodeObjs",
  obstacles: "filteredObstaclesTypeCodeObjs"
};

export default {
  namespaced: true,
  state: () => ({
    clients: [],
    site: undefined,
    sites: [],
    buildings: [],
    levels: [],
    levelStatuses: [],
    pois: [],
    graphs: [],
    beacons: [],
    mapObjects: [],
    originalMapObjects: [],
    obstacles: [],
    levelGeofences: [],
    globalGeofences: [],
    obstaclesTypeCodesObj: {},
    filteredObstaclesTypeCodeObjs: [],
    filteredGraphs: [],
    filterText: "",
    poiTypeCodesObj: {},
    mapObjectsTypeCodesObj: {},
    filteredMapObjectsTypeCodeObjs: [],
    filteredPoiTypeCodeObjs: [],
    filteredBeacons: [],
    filteredLevelGeofences: [],
    defaultContentVisibility: {
      pois: true,
      beacons: false,
      mapObjects: true,
      obstacles: true,
      levelGeofences: false,
      graphs: false
    },
    contentVisibility: {
      pois: true,
      beacons: false,
      mapObjects: true,
      obstacles: true,
      levelGeofences: false,
      graphs: false
    },
    selectedContent: undefined,
    isFormDirty: false,
    // Used for updating component on route changes by breadcrumb
    refreshKey: 0,
    isDragging: false,
    shownPathLevel: undefined,
    isShownPathLevelSelectorExpanded: false,
    dashboardMode: "normal"
  }),
  mutations: {
    CLIENTS(state, clients = []) {
      state.clients = [...clients];
    },
    SITE(state, site = {}) {
      state.site = site;
    },
    SITES(state, sites = []) {
      state.sites = [...sites];
    },
    BUILDINGS(state, buildings = []) {
      state.buildings = [...buildings];
    },
    LEVELS(state, levels = []) {
      state.levels = [...levels];
    },
    LEVEL_STATUSES(state, levelStatuses) {
      state.levelStatuses = levelStatuses;
    },
    POIS(state, pois = []) {
      state.pois = [...pois];
    },
    LOCAL_POIS(state, payload) {
      if (payload.isEdit) {
        state.pois.every((p, index) => {
          if (p?.properties?.fid === payload.feature?.properties?.fid) {
            state.pois[index] = payload.feature;
            return false;
          }
          return true;
        });
      } else if (payload.isDelete) {
        const indexToDelete = state.pois.findIndex((poi) => poi.properties.fid === payload?.feature?.properties?.fid);
        if (indexToDelete !== -1) {
          state.pois.splice(indexToDelete, 1);
        }
      } else {
        state.pois.push(payload.feature);
      }
      state.pois = [...state.pois];
    },
    GRAPHS(state, graphs = []) {
      state.graphs = [...graphs];
    },
    LOCAL_GRAPHS(state, graphs = []) {
      state.graphs = [...graphs];
      const escapedFilterText = Helpers.escapeRegExp(state.filterText);
      const filter = RegExp(`(${escapedFilterText})`, "i");
      state.filteredGraphs = state.graphs.filter((node) => {
        return filter.test(node?.properties?.name) || filter.test(node?.properties?.fid);
      });
    },
    BEACONS(state, beacons = []) {
      state.beacons = [...beacons];
    },
    LOCAL_BEACONS(state, payload) {
      if (payload.isEdit) {
        state.beacons.every((b, index) => {
          if (b?.properties?.fid === payload.feature?.properties?.fid) {
            state.beacons[index] = payload.feature;
            return false;
          }
          return true;
        });
      } else if (payload.isDelete) {
        const indexToDelete = state.beacons.findIndex((b) => b.properties.fid === payload.feature?.properties?.fid);
        if (indexToDelete !== -1) {
          state.beacons.splice(indexToDelete, 1);
        }
      } else {
        state.beacons.push(payload.feature);
      }
      state.beacons = [...state.beacons];
    },
    MAP_OBJECTS(state, mapObjects = []) {
      state.mapObjects = [...mapObjects];
    },
    ORIGINAL_MAP_OBJECTS(state, mapObjects = []) {
      state.originalMapObjects = [...mapObjects];
    },
    LOCAL_MAP_OBJECTS(state, payload) {
      if (payload.isEdit) {
        state.mapObjects.every((p, index) => {
          if (p?.properties?.fid === payload.feature?.properties?.fid) {
            payload.feature.id = index;
            state.mapObjects[index] = payload.feature;
            return false;
          }
          return true;
        });
      } else if (payload.isDelete) {
        const indexToDelete = state.mapObjects.findIndex(
          (mapObject) => mapObject.properties.fid === payload?.feature?.properties?.fid
        );
        if (indexToDelete !== -1) {
          state.mapObjects.splice(indexToDelete, 1);
        }
      } else {
        state.mapObjects.push(payload.feature);
      }
      state.mapObjects = [...state.mapObjects];
    },
    OBSTACLES(state, obstacles = []) {
      state.obstacles = [...obstacles];
    },
    LOCAL_OBSTACLES(state, obstacle) {
      state.obstacles.every((o, index) => {
        if (o?.properties?.fid === obstacle?.properties?.fid) {
          state.obstacles[index] = obstacle;
          return false;
        }
        return true;
      });
    },
    LEVEL_GEOFENCES(state, levelGeofences = []) {
      state.levelGeofences = [...levelGeofences];
    },
    LOCAL_LEVEL_GEOFENCES(state, payload) {
      if (payload.isEdit) {
        state.levelGeofences.every((lg, index) => {
          if (lg?.properties?.fid === payload.feature?.properties?.fid) {
            state.levelGeofences[index] = payload.feature;
            return false;
          }
          return true;
        });
      } else if (payload.isDelete) {
        const indexToDelete = state.levelGeofences.findIndex(
          (lg) => lg.properties.fid === payload.feature.properties?.fid
        );
        if (indexToDelete !== -1) {
          state.levelGeofences.splice(indexToDelete, 1);
        }
      } else {
        state.levelGeofences.push(payload.feature);
      }
      state.levelGeofences = [...state.levelGeofences];
    },
    GLOBAL_GEOFENCES(state, globalGeofences = []) {
      state.globalGeofences = [...globalGeofences].sort((geofence1, geofence2) => {
        return geofence1?.properties?.name?.toLowerCase().localeCompare(geofence2?.properties?.name?.toLowerCase());
      });
    },
    // creates type code dicts for content types{meeting-space: [], chair: []}
    CREATE_TYPE_CODE_DICTS_FOR_TWO_LEVELS(state) {
      // Update features and type codes accordingly using the contentNameTypeCodeDict
      Object.entries(contentNameTypeCodeDict).forEach(([contentKey, contentName]) => {
        state[contentName] = state[contentKey].reduce((featuresObj, feature) => {
          if (!featuresObj[feature.properties.typeCode]) {
            featuresObj[feature.properties.typeCode] = {
              features: []
            };
            if (state?.[contentName]?.[feature.properties.typeCode]?.isVisible === undefined) {
              featuresObj[feature.properties.typeCode].isVisible = state.contentVisibility[contentKey];
            } else {
              featuresObj[feature.properties.typeCode].isVisible =
                state?.[contentName]?.[feature.properties.typeCode]?.isVisible;
            }
          }
          if (feature.geometry?.type === "Point" || feature.geometry?.type === "Polygon") {
            featuresObj[feature.properties.typeCode].features.push(feature);
          }
          return featuresObj;
        }, {});
        // sort according to key (typeCodes)
        state[contentName] = Object.keys(state[contentName])
          .sort((a, b) => a.localeCompare(b))
          .reduce((obj, key) => {
            obj[key] = state[contentName][key];
            return obj;
          }, {});
        state[filteredContentTypeCodeDict[contentKey]] = Object.values(state[contentName]);
        // sort according to names under typeCodes
        state[filteredContentTypeCodeDict[contentKey]].forEach((featuresGroupedByTypeCode) => {
          featuresGroupedByTypeCode?.features?.sort((a, b) =>
            String(a.properties?.name)?.localeCompare(b.properties?.name)
          );
        });
      });
    },
    FILTER_TEXT(state, value) {
      state.filterText = value.toLowerCase();
    },
    // two levels features are gruped by typeCode
    FILTER_TWO_LEVEL_FEATURES(state) {
      // there is no need to filter the features object if the filter text is empty
      if (!state.filterText) {
        Object.keys(filteredContentTypeCodeDict).forEach((content) => {
          const filteredContentType = filteredContentTypeCodeDict[content]; // e.g. filteredPoiTypeCodeObjs
          const typeCodesObjs = contentNameTypeCodeDict[content]; // e.g. poiTypeCodesObj
          state[filteredContentType] = Object.values(state[typeCodesObjs]); //assigned poiTypeCodesObj values to filteredPoiTypeCodeObjs
        });
        return;
      }
      Object.keys(filteredContentTypeCodeDict).forEach((content) => {
        const filteredContentType = filteredContentTypeCodeDict[content]; // e.g. filteredPoiTypeCodeObjs
        state[filteredContentType] = [];
      });

      Object.keys(filteredContentTypeCodeDict).forEach((content) => {
        const typeCodesObjs = contentNameTypeCodeDict[content];
        for (const typeCode in state[typeCodesObjs]) {
          const higherPriority = [];
          const lowerPriority = [];
          state[typeCodesObjs][typeCode].features.forEach((feature) => {
            const strArrToMatch = Helpers.generateStringArrayWithFilterRules(feature);
            if (strArrToMatch.some((str) => str?.toString().startsWith(state.filterText))) {
              higherPriority.push(feature);
            } else if (strArrToMatch.some((str) => str?.toString().includes(state.filterText))) {
              lowerPriority.push(feature);
            }
          });
          state[filteredContentTypeCodeDict[content]].push({
            isVisible: state[typeCodesObjs][typeCode].isVisible,
            features: [...higherPriority, ...lowerPriority]
          });
        }
      });
    },
    // This is for features without a type code group
    FILTER_ONE_LEVEL_FEATURES(state) {
      const keys = ["beacons", "levelGeofences"];
      const dict = {
        beacons: "filteredBeacons",
        levelGeofences: "filteredLevelGeofences"
      };
      if (!state.filterText) {
        keys.forEach((key) => {
          const filteredKey = dict[key];
          state[filteredKey] = [...state[key]];
        });
      }
      keys.forEach((key) => {
        const filteredKey = dict[key];
        const higherPriority = [];
        const lowerPriority = [];
        state[key].forEach((feature) => {
          const strArrToMatch = Helpers.generateStringArrayWithFilterRules(feature);
          if (strArrToMatch.some((str) => str?.toString().startsWith(state.filterText))) {
            higherPriority.push(feature);
          } else if (strArrToMatch.some((str) => str?.toString().includes(state.filterText))) {
            lowerPriority.push(feature);
          }
        });
        state[filteredKey] = [...higherPriority, ...lowerPriority];
      });
    },
    //graph is a special case. Because we only display one graph but it has many feature in it actually.
    FILTER_GRAPH(state, rootSate) {
      const escapedFilterText = Helpers.escapeRegExp(state.filterText);
      const filter = RegExp(`(${escapedFilterText})`, "i");
      state.filteredGraphs = state.graphs.filter((node) => {
        if (node?.properties?.typeCode === "graph-node") {
          return filter.test("Paths") || filter.test(node?.properties?.name);
        } else {
          return (
            filter.test(node?.properties?.name) ||
            filter.test(rootSate.taxonomy[node?.properties?.typeCode]?.title) ||
            filter.test(node?.properties?.typeCode) ||
            filter.test(node?.properties?.fid)
          );
        }
      });
    },
    TOGGLE_TYPE_CODE_VISIBILITY(state, payload) {
      const contentTypeObjName = contentNameTypeCodeDict[payload.contentName];
      state[contentTypeObjName][payload.typeCode].isVisible = payload.isVisible;
      if (payload.isVisible) {
        state.contentVisibility[payload.contentName] = true;
      } else {
        const isAllInvisible = Object.values(state[contentTypeObjName]).every((typeCodeObj) => !typeCodeObj.isVisible);
        state.contentVisibility[payload.contentName] = !isAllInvisible;
      }
    },
    TOGGLE_CONTENT_VISIBILITY(state, payload) {
      state.contentVisibility[payload.contentName] = payload.isVisible;
      const contentTypeObjName = contentNameTypeCodeDict[payload.contentName];
      if (contentTypeObjName) {
        for (const typeCode in state[contentTypeObjName]) {
          state[contentTypeObjName][typeCode].isVisible = payload.isVisible;
        }
      }
    },
    RESET_CONTENT_VISIBILITY(state) {
      state.contentVisibility = { ...state.defaultContentVisibility };
      Object.entries(contentNameTypeCodeDict).forEach(([contentKey, contentName]) => {
        state[contentName] = state[contentKey].reduce((featuresObj, feature) => {
          if (!featuresObj[feature.properties.typeCode]) {
            featuresObj[feature.properties.typeCode] = {
              isVisible: state.contentVisibility[contentKey],
              features: []
            };
          }
          featuresObj[feature.properties.typeCode].features.push(feature);
          return featuresObj;
        }, {});
        // sort according to key (typeCodes)
        state[contentName] = Object.keys(state[contentName])
          .sort((a, b) => a.localeCompare(b))
          .reduce((obj, key) => {
            obj[key] = state[contentName][key];
            return obj;
          }, {});
        state[filteredContentTypeCodeDict[contentKey]] = Object.values(state[contentName]);
        // sort according to names under typeCodes
        state[filteredContentTypeCodeDict[contentKey]].forEach((featuresGroupedByTypeCode) => {
          featuresGroupedByTypeCode?.features?.sort((a, b) =>
            String(a.properties?.name)?.localeCompare(b.properties?.name)
          );
        });
      });
      MapHelpers.toggleAllLayers(state.mapObjectsTypeCodesObj);
    },
    SELECTED_CONTENT(state, content) {
      state.selectedContent = content;
    },
    IS_FORM_DIRTY(state, isDirty) {
      state.isFormDirty = isDirty;
    },
    INCR_REFRESH_KEY(state) {
      state.refreshKey++;
    },
    IS_DRAGGING(state, value) {
      state.isDragging = value;
    },
    SHOWN_PATH_LEVEL(state, value) {
      state.shownPathLevel = value;
    },
    IS_SHOWN_PATH_LEVEL_SELECTOR_EXPANDED(state, isExpanded) {
      state.isShownPathLevelSelectorExpanded = isExpanded;
    },
    DASHBOARD_MODE(state, value) {
      state.dashboardMode = value;
    }
  },
  actions: {
    UPDATE_FEATURE({ commit, dispatch }, payload) {
      commit("LOCAL_POIS", payload);
      commit("LOCAL_MAP_OBJECTS", payload);
      commit("LOCAL_LEVEL_GEOFENCES", payload);
      commit("LOCAL_BEACONS", payload);
      dispatch("FILTER_TEXT", "");
    },
    GENERATE_TYPE_CODE_DICTS({ commit }) {
      commit("CREATE_TYPE_CODE_DICTS_FOR_TWO_LEVELS");
    },
    FILTER_TEXT({ commit, rootState }, value) {
      commit("FILTER_TEXT", value);
      commit("FILTER_TWO_LEVEL_FEATURES");
      commit("FILTER_ONE_LEVEL_FEATURES");
      commit("FILTER_GRAPH", rootState);
    },
    async SET_CLIENTS({ commit, state }, payload) {
      if ((state.clients.length && payload?.forceUpdate) || !state.clients.length) {
        const response = await ClientService.getClients();
        commit("CLIENTS", response);
        // We're not multiclient yet, so we only have one client
        commit("MAP/CURRENT_CLIENT", response[0]?.clientInternalIdentifier, { root: true });
      }
    },
    async SET_SITE({ commit, state }, payload) {
      if (!payload.siteId) {
        console.debug("Could not set site - invalid params");
        return;
      }
      if (state.sites?.find((site) => site?.siteInternalIdentifier === payload?.siteId) && !payload?.forceUpdate) {
        commit(
          "SITE",
          state.sites?.find((site) => site?.siteInternalIdentifier === payload?.siteId)
        );
        return;
      }
      if ((state.site && payload?.forceUpdate) || !state.site) {
        const response = await SiteService.getSite(payload.siteId);
        commit("SITE", response);
      }
    },
    async SET_SITES({ commit, state }, payload) {
      if (!payload.clientId) {
        console.debug("Could not set sites - invalid params");
        return;
      }

      if ((state.sites.length && payload?.forceUpdate) || !state.sites.length) {
        const response = await SiteService.getSites(payload.clientId);
        commit("SITES", response);
      }
    },
    async SET_BUILDINGS({ commit, state }, payload) {
      if (!payload.siteId) {
        console.debug("Could not set buildings - invalid params");
        return;
      }
      if ((state.buildings.length && payload?.forceUpdate) || !state.buildings.length) {
        const response = await BuildingService.getBuildings(payload.siteId);
        commit("BUILDINGS", response);
      }
    },
    async SET_LEVELS({ commit, state }, payload) {
      if (!payload.buildingId) {
        console.debug("Could not set levels - invalid params");
        return;
      }
      if ((state.levels.length && payload?.forceUpdate) || !state.levels.length) {
        const response = await LevelService.getLevels(payload.buildingId);
        commit("LEVELS", response);
      }
    },
    async SET_LEVEL_STATUSES({ commit }, payload) {
      if (!payload.clientId || !payload.siteId || !payload.buildingId) {
        console.debug("Could not set level statuses - invalid params");
        return;
      }
      const response = await LevelService.getLevelStatuses(payload?.clientId, payload?.siteId, payload?.buildingId);
      let levelStatusesDict = {};
      response.forEach((levelStatus) => {
        levelStatusesDict[levelStatus?.levelIndex] = levelStatus;
      });
      for (let index = response.length - 1; index >= 0; index--) {
        levelStatusesDict[response[index]?.levelIndex] = response[index];
      }
      commit("LEVEL_STATUSES", levelStatusesDict);
    },
    async SET_CONTENTS({ dispatch }, payload) {
      if (
        payload.siteInternalIdentifier === undefined ||
        payload.buildingInternalIdentifier === undefined ||
        payload.levelIndex === undefined
      ) {
        console.debug("Could not set contents - invalid params");
        return;
      }
      await Promise.all([
        dispatch("SET_POIS", {
          forceUpdate: payload.forceUpdate,
          buildingInternalIdentifier: payload.buildingInternalIdentifier,
          levelIndex: payload.levelIndex
        }),
        dispatch("SET_GRAPHS", {
          forceUpdate: payload.forceUpdate,
          buildingInternalIdentifier: payload.buildingInternalIdentifier,
          siteInternalIdentifier: payload.siteInternalIdentifier
        }),
        dispatch("SET_BEACONS", {
          forceUpdate: payload.forceUpdate,
          buildingInternalIdentifier: payload.buildingInternalIdentifier,
          levelIndex: payload.levelIndex
        }),
        dispatch("SET_OBSTACLES", {
          forceUpdate: payload.forceUpdate,
          buildingInternalIdentifier: payload.buildingInternalIdentifier,
          levelIndex: payload.levelIndex
        }),
        dispatch("SET_LEVEL_GEOFENCES", {
          forceUpdate: payload.forceUpdate,
          buildingInternalIdentifier: payload.buildingInternalIdentifier,
          levelIndex: payload.levelIndex
        })
      ]);
    },
    async SET_POIS({ commit, state }, payload) {
      if ((state.pois.length && payload?.forceUpdate) || !state.pois.length || payload.levelIndex) {
        // We don't need to remove it everytime. But it is better to remove old data in case of any error.
        const response = await ContentService.getFeatures(
          ContentService.CONTENT_TYPES.POI,
          payload.buildingInternalIdentifier,
          payload.levelIndex
        );
        commit("POIS", response.features);
      } else {
        console.warn("Could not set pois - invalid params");
      }
    },
    async SET_GRAPHS({ commit, state }, payload) {
      if ((state.graphs.length && payload?.forceUpdate) || !state.graphs.length) {
        const response = await ContentService.getGraphs(payload.siteInternalIdentifier);
        response?.features?.forEach((feature) => {
          if (feature?.properties?.typeCode === "graph-node" && !feature?.properties?.name) {
            feature.properties.name = "Path 001";
          }
        });
        commit("GRAPHS", response?.features);
      }
    },
    async SET_BEACONS({ commit, state }, payload) {
      if ((state.beacons.length && payload?.forceUpdate) || !state.beacons.length) {
        const response = await ContentService.getFeatures(
          ContentService.CONTENT_TYPES.BEACON,
          payload.buildingInternalIdentifier,
          payload.levelIndex
        );
        response?.features?.sort((beacon1, beacon2) => {
          const major1 = beacon1.properties.major;
          const minor1 = beacon1.properties.minor;
          const major2 = beacon2.properties.major;
          const minor2 = beacon2.properties.minor;
          if (major1 > major2) return 1;
          else if (major1 === major2) return minor1 - minor2;
          else return -1;
        });
        commit("BEACONS", response?.features);
      }
    },
    async SET_MAP_OBJECTS({ commit, state, dispatch }, payload) {
      if ((state.mapObjects.length && payload?.forceUpdate) || !state.mapObjects.length) {
        let featuresToAdd;
        if (payload.mapObjects) {
          payload?.mapObjects?.features?.forEach((feature) => {
            if (!feature.properties.style) {
              feature.properties.style = {};
            }
          });
          featuresToAdd = payload.mapObjects.features;
        } else {
          const response = await ContentService.getFeatures(
            ContentService.CONTENT_TYPES.MAP_OBJECT,
            payload.buildingInternalIdentifier,
            payload.levelIndex
          );
          response?.features?.forEach((feature, index) => {
            feature.id = index;
            if (!feature.properties.style) {
              feature.properties.style = {};
            }
          });
          featuresToAdd = response?.features;
        }
        dispatch("MAP/REMOVE_MAP_OBJECTS_FROM_MAP", null, { root: true });
        commit("MAP_OBJECTS", featuresToAdd);
      }
    },
    async SET_ORIGINAL_MAP_OBJECTS({ commit }, payload) {
      if (!payload.request) {
        console.debug("Could not set original map objects - invalid params");
        return;
      }
      const response = await LevelService.convertBase64ToGeojson(payload.request);

      const features = response?.result?.features?.map((feature) => {
        if (!feature.properties.typeCode) {
          return { ...feature, properties: { ...feature.properties, typeCode: "undefined" } };
        }
        if (!feature.properties.fid) {
          feature.properties.fid = Helpers.generateUuid();
        }
        return feature;
      });

      commit("ORIGINAL_MAP_OBJECTS", features);
    },
    async SET_OBSTACLES({ commit, state }, payload) {
      if ((state.obstacles.length && payload?.forceUpdate) || !state.obstacles.length) {
        if (payload.obstacles) {
          commit("OBSTACLES", payload.obstacles.features);
        } else {
          const response = await ContentService.getFeatures(
            ContentService.CONTENT_TYPES.OBSTACLE,
            payload.buildingInternalIdentifier,
            payload.levelIndex
          );
          commit("OBSTACLES", response?.features);
        }
      }
    },
    async SET_LEVEL_GEOFENCES({ commit, state }, payload) {
      if ((state.levelGeofences.length && payload?.forceUpdate) || !state.levelGeofences.length) {
        if (payload.levelGeofences) {
          commit("LEVEL_GEOFENCES", payload.levelGeofences.features);
        } else {
          const response = await ContentService.getFeatures(
            ContentService.CONTENT_TYPES.LEVEL_GEOFENCE,
            payload.buildingInternalIdentifier,
            payload.levelIndex
          );
          commit("LEVEL_GEOFENCES", response?.features);
        }
      }
    },
    async SET_GLOBAL_GEOFENCES({ commit, state }, payload) {
      if ((state.globalGeofences.length && payload?.forceUpdate) || !state.globalGeofences.length) {
        if (payload.globalGeofences) {
          commit("GLOBAL_GEOFENCES", payload.globalGeofences.features);
        } else {
          const response = await ContentService.getGlobalGeofences(payload.clientId);
          commit("GLOBAL_GEOFENCES", response?.features);
        }
      }
    },
    //TODO: Remove
    SET_SELECTED_CONTENT({ commit, getters }, payload) {
      if (payload.featureId) {
        const content = getters.getContentFromFid(payload.featureId);
        commit("SELECTED_CONTENT", content);
      } else {
        commit("SELECTED_CONTENT", undefined);
      }
    },
    SET_LOCAL_CHANGES({ state, dispatch }) {
      dispatch("MAP/ADD_MAP_OBJECTS_TO_MAP", state.mapObjects, {
        root: true
      });
    },
    SET_VISIBILITY_OF_TYPE_CODE({ state }, payload) {
      const contentTypeObjName = contentNameTypeCodeDict[payload.contentName];
      if (state[contentTypeObjName][payload.typeCode]) {
        state[contentTypeObjName][payload.typeCode].isVisible = payload.isVisible;
      }
    },
    SET_SHOWN_PATH_LEVEL({ state, commit }, levelIndex) {
      if (state.isShownPathLevelSelectorExpanded) {
        commit("SHOWN_PATH_LEVEL", levelIndex);
      }
      commit("IS_SHOWN_PATH_LEVEL_SELECTOR_EXPANDED", !state.isShownPathLevelSelectorExpanded);
    }
  },
  getters: {
    getTypeCodeObjVisibility: (state) => (typeCode, contentName) => {
      const contentTypeObjName = contentNameTypeCodeDict[contentName];
      return state[contentTypeObjName][typeCode]?.isVisible;
    },
    getContentFromFid: (state) => (fid) => {
      const content =
        state.pois.find((feature) => fid === feature.properties.fid) ||
        state.beacons.find((feature) => fid === feature.properties.fid) ||
        state.mapObjects.find((feature) => fid === feature.properties.fid) ||
        state.levelGeofences.find((feature) => fid === feature.properties.fid) ||
        state.obstacles.find((feature) => fid === feature.properties.fid);
      return content;
    },
    mapObjects: (state) => state.mapObjects,
    graphs: (state) => state.graphs
  }
};
