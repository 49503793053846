<template>
  <v-expansion-panels v-model="defaultOpenPanelIndex" class="content-add-edit-expansion-panels" flat>
    <v-expansion-panel active-class="expanded" class="mt-0">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="information" />
        <div class="expansion-panel-header-text">{{ $t(`${translationPath}basic-information`) }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <GeometrySection
          ref="geometrySection"
          feature-id="outdoor-path"
          :should-show-polygon-icon="false"
          :should-show-point-icon="false"
          is-path
          :is-edit="isEdit"
          @pathClicked="pathClicked"
          @editGeometry="editGeometry"
          @clear="clearClicked"
        ></GeometrySection>
        <div class="form-footer paragraph-xs mt-5">
          {{ $t(`${translationPath}required`) }}
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState } from "vuex";
import ValidationHelpers from "@/helpers/ValidationHelpers";
import MapHelpers from "@/helpers/MapHelpers";
import ContentService from "@/services/ContentService";
import GraphMode from "@/helpers/drawModes/GraphMode";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import GeometrySection from "@/components/mapDesigner/GeometrySection.vue";

export default {
  components: { PtrIcon, GeometrySection },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    isValid: false,
    showGeometryOptions: true,
    defaultOpenPanelIndex: 0,
    pathUpdated: false,
    isEdit: false
  }),
  computed: {
    ...mapState("MAP", ["isMapReady", "isMapBorderEnabled", "map", "currentSite"]),
    ...mapState("CONTENT", ["graphs", "filteredGraphs"]),
    rules() {
      return {
        required: (value) => ValidationHelpers.isRequired(value)
      };
    }
  },
  watch: {
    isValid() {
      this.$emit("valid", this.isValid);
    },
    $route: {
      handler(newVal, oldVal) {
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        this.cancelClicked();
        this.pathUpdated = false;
        this.isEdit = newVal.params?.featureId === "edit-outdoor-path";
        const graphFeaturesToShow = this.graphs.filter((feature) => {
          return (
            (feature.properties.bid === undefined && feature.properties.lvl === undefined) ||
            feature.properties.typeCode === "building-entrance-exit"
          );
        });
        setTimeout(() => {
          if (!oldVal?.fullPath?.includes("path")) {
            GraphMode.createNodesFromFeatures(graphFeaturesToShow, true);
            GraphMode.generateGraph();
            GraphMode.toggle({ shouldEnable: false });
          }
        }, 500);
      },
      immediate: true
    },
    pathUpdated() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", this.pathUpdated);
    }
  },
  created() {
    MapHelpers.enableMapInteractions();
    this.map.on("contextmenu", this.mapRightClicked);
  },
  beforeDestroy() {
    MapHelpers.disableMapInteractions();
    GraphMode.toggle({ shouldEnable: false });
    this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
    this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
    this.map.off("contextmenu", this.mapRightClicked);
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
  },
  methods: {
    pathClicked() {
      if (this.pathUpdated) {
        this.mapRightClicked();
      }
      this.pathUpdated = true;
      this.showGeometryOptions = false;
      GraphMode.toggle({ shouldEnable: true });
      this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
      this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.finish-drawing"));
    },
    editGeometry() {
      GraphMode.toggle({ shouldEnable: true });
      this.pathUpdated = true;
    },
    mapRightClicked() {
      if (this.isMapBorderEnabled) {
        this.isValid = GraphMode.graphData.features.length !== 0;
        setTimeout(() => {
          this.cancelClicked();
        });
      }
    },
    cancelClicked() {
      this.showGeometryOptions = true;
      GraphMode.toggle({ shouldEnable: false });
      this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
      this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
    },
    clearClicked() {
      GraphMode.toggle({ shouldEnable: false });
      GraphMode.clear();
      let graphFeaturesToShow = this.graphs.filter((feature) => {
        return (
          feature.properties.typeCode !== "graph-node" &&
          feature.properties.bid === undefined &&
          feature.properties.lvl === undefined
        );
      });
      this.removeEdges(graphFeaturesToShow);
      graphFeaturesToShow.push(
        ...this.graphs.filter((feature) => {
          return (
            feature.properties.typeCode === "building-entrance-exit" &&
            feature.properties.bid !== undefined &&
            feature.properties.lvl !== undefined
          );
        })
      );
      GraphMode.createNodesFromFeatures(graphFeaturesToShow, true);
      GraphMode.generateGraph();
      GraphMode.toggle({ shouldEnable: true });
    },
    removeEdges(features) {
      return features.forEach((feature) => {
        feature.properties.neighbors = [];
      });
    },
    onPathCleared() {
      GraphMode.toggle({ shouldEnable: false });
      GraphMode.clear();
    },
    async save() {
      const newGraphJson = GraphMode.exportAsGeoJson();
      newGraphJson.features.forEach((feature) => {
        delete feature.properties.bid;
        delete feature.properties.lvl;
        const sid = feature.properties.sid;
        if (isNaN(sid) || sid === undefined) {
          feature.properties.sid = this.currentSite;
        }
      });
      const otherGraphNodes = this.graphs.filter((feature) => {
        if (feature.properties.bid !== undefined && feature.properties.lvl !== undefined) {
          return feature;
        }
      });
      newGraphJson.features.push(...otherGraphNodes);
      let response = await ContentService.postGraphBySite(this.currentSite, newGraphJson);
      if (response?.createdTimestampUtcEpochSeconds) {
        this.$store.commit("CONTENT/LOCAL_GRAPHS", newGraphJson.features);
        return true;
      } else {
        return false;
      }
    },
    goBack() {
      this.$router
        .push({ name: "OutdoorWayfindingNetwork", params: { contentType: "outdoor-wayfinding-network" } })
        .catch((e) => console.debug(e.message));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.editing-geometry {
  background: var(--v-primary-lighten4);
}

.content-add-edit-expansion-panels {
  width: #{$content-add-edit-panel-max-child-width};
}

.button-container {
  width: fit-content;

  > div {
    font-size: 8px;
    font-weight: #{$font-weight-medium};
    line-height: #{$line-height-12};
    color: var(--v-primary-base);
  }
}
</style>
