<template>
  <div class="beta-tag" :class="cssClass">{{ text }}</div>
</template>

<script>
export default {
  name: "BetaTag",
  props: {
    status: {
      type: String,
      default: "beta"
    }
  },
  data: () => ({
    translationPath: "tags."
  }),
  computed: {
    text() {
      return this.$t(`${this.translationPath}${this.status}`);
    },
    cssClass() {
      return this.status;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
.beta-tag {
  border-radius: 8px;
  display: inline-flex;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  gap: #{$spacing-s};
  font-size: #{$font-size-1};
  &.beta {
    border: 1px solid var(--v-alert-darken3);
    color: var(--v-white-base);
    background: var(--v-alert-darken2);
  }
  &.experimental {
    border-radius: var(--spacing-s, 8px);
    border: 1px solid var(--v-info-darken3);
    background: var(--v-info-darken2);
    color: var(--v-white-base);
  }
}
</style>
