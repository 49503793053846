<template>
  <v-row class="add-new-row mt-0" @click="addNewProperty">
    <v-col cols="1" class="mr-1">
      <button class="add-new-property">
        <PtrIcon width="12" height="12" icon="plus" :editable-color="true" stroke-color="white" color="white"></PtrIcon>
      </button>
    </v-col>
    <v-col>
      <div class="add-new-property-text paragraph-s ml-1">
        {{ $t(`add-new-property`) }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";
export default {
  components: { PtrIcon },
  methods: {
    addNewProperty() {
      this.$emit("addNewClicked");
    }
  }
};
</script>
<style lang="scss" scoped>
.add-new-property {
  background: var(--v-primary-base);
  border-radius: 20.5714px;
  width: 20px;
  height: 20px;
}
.add-new-property-text {
  color: var(--v-primary-base);
}
.add-new-row {
  cursor: pointer;
}
</style>
