<template>
  <div>
    <div class="transition-node-list-container d-flex align-stretch justify-start">
      <div class="vertical-line pt-6 mr-3">
        <div
          v-for="(edge, index) in edgeList"
          :key="`${edge.a}-line`"
          class="d-flex flex-column align-center justify-flex-start"
        >
          <div class="circle big"></div>
          <div v-for="innerIndex in 5" :key="`${edge.a}${innerIndex}`" class="circle small"></div>
          <div
            v-if="canDirectionChange"
            class="direction-button-container"
            :class="{ rotate: directionOptions[edge.direction]?.rotate, clickable: canDirectionChange }"
            @click="directionUpdated(index)"
          >
            <PtrIcon :icon="directionOptions[edge.direction]?.icon" />
          </div>
        </div>
        <div v-if="edgeList?.[0]?.b" class="d-flex flex-column align-center justify-flex-start">
          <div class="circle big"></div>
          <div v-for="index in 5" :key="index" class="circle small"></div>
        </div>
      </div>
      <v-list class="py-0 transition-node-list">
        <v-list-item
          v-for="item in displayedListItems"
          :key="`${item?.properties?.fid}-list-item`"
          :class="{ 'current-list-item': item?.properties?.fid === currentNodeObject?.properties?.fid }"
          class="px-0"
          two-line
          transition="scroll-y-transition"
        >
          <v-list-item-content>
            <v-list-item-title class="paragraph-s mb-0">{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle class="paragraph-xs">{{ item.secondaryText }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon class="delete-icon" @click="edgeRemoved(item)">
            <PtrIcon icon="cancel-circle" class="mr-3" />
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </div>

    <v-menu bottom transition="slide-y-transition" offset-y content-class="transition-link-options-dropdown">
      <template #activator="{ on, attrs }">
        <v-btn class="add-new-button mt-n4" v-bind="attrs" text v-on="on" @click="addNewClicked">
          <div class="circle add-icon d-flex align-center justify-center mr-2">
            <v-icon color="white" small>mdi-plus</v-icon>
          </div>
          <div class="d-flex flex-column align-start justify-start">
            <span class="button-text paragraph-s">{{ $t("addNew") }}</span>
            <div class="description paragraph-xs">{{ $t(`${translationPath}add-transition-link-description`) }}</div>
          </div>
        </v-btn>
      </template>

      <v-list dense>
        <v-subheader>{{
          $t(`${translationPath}${dropdownOptions.length !== 0 ? "quick-access" : "no-available-transitions"}`)
        }}</v-subheader>
        <v-list-item
          v-for="(dropdownFeature, index) in dropdownOptions"
          :key="index"
          class="align-start justify-start"
          @click="add(dropdownFeature)"
        >
          <v-list-item-content>
            <v-list-item-title class="paragraph-s mb-0">{{
              dropdownFeature.properties.name || type
            }}</v-list-item-title>
            <v-list-item-subtitle class="paragraph-xs">{{
              `${getBuildingName(dropdownFeature)}/${getLevelShortTitle(dropdownFeature)}`
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="d-flex" style="flex-direction: row">
            <v-btn class="v-btn--square" small icon depressed plain @click.stop="scrollTo(dropdownFeature)">
              <PtrIcon icon="selected" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
    <TransitionConfiguration
      :portal-travel-time="portalTravelTime"
      :is-accessible="isAccessible"
      :is-comfortable="isComfortable"
      @portalTravelTimeChanged="(newVal) => onPortalTravelTimeChanged(newVal)"
      @isAccessibleChanged="(newVal) => onIsAccessibleChanged(newVal)"
      @isComfortableChanged="(newVal) => onIsComfortableChanged(newVal)"
      @transitionPropertiesValid="
        (areTransitionPropertiesValid) => $emit('transitionPropertiesValid', areTransitionPropertiesValid)
      "
    ></TransitionConfiguration>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import TransitionConfiguration from "@/components/mapDesigner/TransitionConfiguration.vue";
import MapHelpers from "@/helpers/MapHelpers";
import TransitionTaxonomy from "@/constants/transitionTaxonomy";

const UNI_DIRECTION_OPTIONS = {
  "a-to-b": { icon: "one-way", rotate: false },
  "b-to-a": { icon: "one-way", rotate: true }
};

const CUSTOM_DIRECTION_OPTIONS = {
  bi: { icon: "both-ways", rotate: false },
  "a-to-b": { icon: "one-way", rotate: false },
  "b-to-a": { icon: "one-way", rotate: true }
};

const BI_DIRECTION_OPTIONS = {
  bi: { icon: "both-ways", rotate: false }
};

export default {
  name: "TransitionLinks",
  components: { PtrIcon, TransitionConfiguration },
  props: {
    currentNodeObject: Object,
    currentPortalGroupId: String,
    edgeList: Array, // {a:from-fid, b: to-id, direction: a-to-b/b-to-a/bi}
    availableNodes: { type: Array, required: true },
    nodesIdsToAdd: Array,
    type: String,
    portalTravelTime: [String, Number],
    isAccessible: Boolean,
    isComfortable: Boolean
  },
  data: () => ({
    translationPath: "contents.mapDesigner."
  }),
  computed: {
    ...mapState("CONTENT", ["buildings", "levels", "graphs"]),
    ...mapGetters("MAP", ["currentBuildingObject", "currentLevelObject"]),
    dropdownOptions() {
      const selectedIds = this.displayedListItems.map((node) => node?.properties?.fid);
      return this.availableNodes
        .filter((node) => selectedIds.indexOf(node?.properties?.fid) === -1)
        .sort((a, b) => {
          return a?.properties?.lvl - b?.properties?.lvl;
        });
    },
    taxonomy() {
      return TransitionTaxonomy.TRANSITION_TYPES;
    },
    featuresOfSameType() {
      return this.graphs.filter((feature) => feature.properties.typeCode === this.type);
    },
    typeObject() {
      return Object.values(this.taxonomy).find((typeObj) => {
        return typeObj.code === this.type;
      });
    },
    defaultDirection() {
      return this.typeObject.properties.portalDirection;
    },
    directionOptions() {
      if (this.type === "custom") {
        return CUSTOM_DIRECTION_OPTIONS;
      }
      return this.defaultDirection === "unidirectional" ? UNI_DIRECTION_OPTIONS : BI_DIRECTION_OPTIONS;
    },
    idsToNodes() {
      const nodesDict = this.featuresOfSameType.reduce((idToNodesObject, transitionNode) => {
        idToNodesObject[transitionNode.properties.fid] = transitionNode;
        return idToNodesObject;
      }, {});
      nodesDict[this.currentNodeObject.properties.fid] = this.currentNodeObject;
      return nodesDict;
    },
    displayedListItems() {
      let displayedNodeIds = [];
      if (!this.edgeList?.[0]?.b) {
        displayedNodeIds = [this.edgeList?.[0]?.a];
      } else {
        this.edgeList.forEach((edge) => {
          let startingNode = edge.a;
          let destinationNode = edge.b;
          if (displayedNodeIds.findIndex((id) => id === startingNode) === -1) {
            displayedNodeIds.push(startingNode);
          }
          if (displayedNodeIds.findIndex((id) => id === destinationNode) === -1) {
            displayedNodeIds.push(destinationNode);
          }
        });
      }
      const displayedNodes = displayedNodeIds.map((id) => this.idsToNodes[id]);
      return displayedNodes.map((node) => {
        const name = this.getName(node);
        const building = this.buildings?.find(
          (building) => building.buildingInternalIdentifier === node?.properties?.bid
        );
        const buildingName = building?.buildingTitle;
        const levelShortTitle = building?.levels.find(
          (level) => level.levelIndex === node?.properties?.lvl
        )?.levelShortTitle;
        return { ...node, name, secondaryText: `${buildingName} / ${levelShortTitle}` };
      });
    },
    canDirectionChange() {
      return this.directionOptions !== BI_DIRECTION_OPTIONS;
    }
  },
  watch: {
    nodesIdsToAdd() {
      const fid = this.nodesIdsToAdd[0];
      const feature = this.idsToNodes[fid];
      if (feature) {
        this.add(feature);
      }
    }
  },
  methods: {
    getName(node) {
      if (node === undefined) {
        return;
      }
      const name = node?.properties?.name || this.type;
      if (node?.properties?.fid === this.currentNodeObject.properties.fid) {
        return `${name} (Current Node)`;
      }
      return name;
    },
    add(feature) {
      const newlyLinkedFeaturesGroupId =
        feature?.properties?.portalNeighbors?.length > 0 ? feature.properties.portalGroupId : undefined;
      const index = this.nodesIdsToAdd.findIndex((id) => id === feature.properties.fid);
      if (!newlyLinkedFeaturesGroupId || newlyLinkedFeaturesGroupId === this.currentPortalGroupId || index !== -1) {
        this.edgeAdded(feature);
      } else {
        this.$emit("mergeWithCurrentGroup", feature);
      }
    },
    edgeAdded(feature) {
      let index;
      const featureLevel = feature.properties.lvl;
      if (!this.edgeList?.[0]?.b) {
        if (this.currentNodeObject.properties.lvl <= featureLevel) {
          index = 0;
        } else {
          index = -1;
        }
      } else {
        const lowerIndex = this.edgeList
          .map((edgeObject) => edgeObject?.a)
          .findLastIndex((id) => this.idsToNodes[id].properties.lvl < featureLevel);
        const higherIndex = this.edgeList
          .map((edgeObject) => edgeObject?.b)
          .findIndex((id) => this.idsToNodes[id].properties.lvl >= featureLevel);
        if (lowerIndex === -1) {
          index = -1;
        } else if (higherIndex === -1) {
          index = this.edgeList.length;
        } else if (lowerIndex === higherIndex) {
          index = higherIndex;
        } else {
          index = higherIndex + 1;
        }
      }
      this.$emit("addEdge", { feature, index });
      this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
    },
    edgeRemoved(feature) {
      const index = this.edgeList.findIndex((edgeObject) => edgeObject.b === feature.properties.fid);
      this.$emit("edgeRemoved", { feature, index });
      this.$store.commit("MAP/CLICKED_TRANSITION_FEATURE", undefined);
    },
    directionUpdated(index) {
      if (!this.canDirectionChange) {
        return;
      }
      const currentDirection = this.edgeList[index].direction;
      const currIdx = Object.keys(this.directionOptions).indexOf(currentDirection);
      const directionCount = Object.keys(this.directionOptions).length;
      const newIdx = (currIdx + 1) % directionCount;
      const newDirection = Object.keys(this.directionOptions)[newIdx];
      this.$emit("directionUpdated", { index, newDirection });
    },
    async scrollTo(item) {
      this.$store.dispatch("MAP/SET_CURRENT_BUILDING", { building: item.properties.bid });
      await this.$store.dispatch("MAP/SET_CURRENT_LEVEL", { level: item.properties.lvl });
      const coordinates = { lng: item.geometry.coordinates[0], lat: item.geometry.coordinates[1] };
      MapHelpers.jumpToPoint(coordinates);
    },
    addNewClicked() {
      this.$emit("addNewClicked");

      if (this.dropdownOptions.length !== 0) {
        this.$store.commit("MAP/SHOW_UI_ICON", { iconName: "buildingLevelSelector" });
        this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
        this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.choose-transition"));
      }
    },
    getBuildingName(feature) {
      const buildingObject = this.buildings.find(
        (building) => building.buildingInternalIdentifier === feature.properties.bid
      );
      return buildingObject?.buildingTitle;
    },
    getLevelShortTitle(feature) {
      const buildingObject = this.buildings.find(
        (building) => building.buildingInternalIdentifier === feature.properties.bid
      );
      const levelObject = buildingObject.levels.find((level) => level.levelIndex === feature.properties.lvl);
      return levelObject.levelShortTitle;
    },
    onPortalTravelTimeChanged(newVal) {
      this.$emit("portalTravelTimeChanged", Number(newVal) || 0);
    },
    onIsAccessibleChanged(newVal) {
      this.$emit("isAccessibleChanged", newVal);
    },
    onIsComfortableChanged(newVal) {
      this.$emit("isComfortableChanged", newVal);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/variables.scss";
.transition-node-list-container {
  padding-left: 7px;
  .v-list-item__content {
    padding: 4px 0;
    max-width: calc(100% - 24px);
  }
  .v-list-item--two-line {
    min-height: 52px;
    height: 52px;
  }
}

.add-new-button {
  padding-left: 0 !important;
  padding-right: 0 !important;
  background: transparent;
  .button-text {
    color: var(--v-primary-base);
  }
  .description {
    color: var(--v-primary-lighten1);
    max-width: 150px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
}

.current-list-item {
  .delete-icon {
    display: none;
  }
}
.delete-icon {
  cursor: pointer;
}
.vertical-line {
  > div {
    gap: #{$spacing-s};
    height: 52px;
    position: relative;
  }
}
.circle {
  border-radius: 100%;
  flex-shrink: 0;
  &.big {
    background-color: var(--v-primary-base);
    width: 8px;
    height: 8px;
  }
  &.small {
    background-color: var(--v-primary-lighten1);
    width: 4px;
    height: 4px;
  }
  &.add-icon {
    background-color: var(--v-primary-base);
    width: 20px;
    height: 20px;
  }
}

button {
  &.pl-0 {
    padding-left: 0 !important;
  }
}

::v-deep .v-list-item__title {
  align-self: start;
}

.direction-button-container {
  position: absolute;
  top: calc(50% - 6px);
  &.clickable {
    cursor: pointer;
  }
}

.transition-node-list {
  width: calc(100% - 8px);
}

::v-deep {
  .rotate {
    img {
      transform: rotate(180deg);
    }
  }
}

.hidden {
  display: none;
}
</style>
