export default function (isDark) {
  return {
    example: isDark ? "FF0000" : "FFA500",
    white: "#FFFFFF",
    green: "#72F20D",
    orange: "#F26D0D",
    yellow: "#DFF10D",
    purple: "#930DF2",
    magenta: "#F20DA6",
    scrollbarGray: "#F1F2F4",
    primary: {
      base: "#346DF1",
      lighten1: "#96B3F8",
      lighten2: "#B7CBFA",
      lighten3: "#CFDDFC",
      lighten4: "#F5F8FE",
      lighten5: "#FAFCFE",
      darken1: "#0E47CD",
      darken2: "#0D3FB5",
      darken3: "#0B379D",
      darken4: "#07215f"
    },
    error: {
      base: "#CC3247",
      lighten1: "#DF9FA8",
      lighten2: "#ECC6CB",
      lighten3: "#F9ECEE",
      darken1: "#B92D40"
    },
    info: {
      base: "#6DB4CE",
      lighten1: "#A0CEDF",
      lighten2: "#C6E2EC",
      lighten3: "#ECF5F9",
      darken1: "#47A1C2",
      darken2: "#288CBD",
      darken3: "#1F6D93"
    },
    success: {
      base: "#00B46C",
      lighten1: "#9FDFC6",
      lighten2: "#C6ECDD",
      lighten3: "#ECF9F4",
      darken1: "#12A168"
    },
    warning: {
      base: "#FAA732",
      lighten1: "#FCC983",
      lighten2: "#FDDFB4",
      lighten3: "#FEF4E6",
      darken1: "#EF963D",
      darken2: "#F4A106",
      darken3: "#C28005"
    },
    neutral: {
      base: "#9095A2",
      lighten1: "#ABAFBA",
      lighten2: "#C7CAD1",
      lighten3: "#E3E4E8",
      lighten4: "#F8F8F9",
      darken1: "#747B8B",
      darken2: "#5D626F",
      darken3: "#464A53",
      darken4: "#2E3138",
      darken5: "#17191C"
    },
    category1: {
      base: "#6D8692",
      lighten1: "#8A9EA8",
      lighten2: "#A7B6BE",
      lighten3: "#C4CFD4",
      lighten4: "#E2E7E9"
    },
    category2: {
      base: "#C2923D",
      lighten1: "#CEA864",
      lighten2: "#DABE8B",
      lighten3: "#E6D4B2",
      lighten4: "#F3E9D8"
    },
    category3: {
      base: "#BD4242",
      lighten1: "#CA6868",
      lighten2: "#D78E8E",
      lighten3: "#E4B4B4",
      lighten4: "#F2DADA"
    },
    category4: {
      base: "#4294BD",
      lighten1: "#68A9CA",
      lighten2: "#8EBFD7",
      lighten3: "#B4D4E4",
      lighten4: "#DAEAF2"
    },
    category5: {
      base: "#42BDBD",
      lighten1: "#68CACA",
      lighten2: "#8ED7D7",
      lighten3: "#B4E4E4",
      lighten4: "#DAF2F2"
    },
    category6: {
      base: "#4242BD",
      lighten1: "#6868CA",
      lighten2: "#8E8ED7",
      lighten3: "#B4B4E4",
      lighten4: "#DADAF2"
    },
    category7: {
      base: "#BD42A3",
      lighten1: "#CA68B6",
      lighten2: "#D78EC8",
      lighten3: "#E4B4DA",
      lighten4: "#F2DAED"
    },
    category8: {
      base: "#808080",
      lighten1: "#999999",
      lighten2: "#B2B2B2",
      lighten3: "#CCCCCC",
      lighten4: "#E5E5E5"
    }
  };
}
