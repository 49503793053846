import vm from "../main";
import axios from "./PointrCloudAxios";
import Helpers from "@/helpers/Helpers";
import ToastHelpers from "@/helpers/ToastHelpers";

export default class SiteService {
  static async getSites(clientInternalIdentifier) {
    let response;
    let sites;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/clients/${clientInternalIdentifier}/sites/draft`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    sites = response?.data?.result?.sites?.sort(Helpers.sortContent);
    return sites;
  }

  static async getSite(siteInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/sites/${siteInternalIdentifier}/draft`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.result;
  }

  static async createSite(site, clientInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(`/clients/${clientInternalIdentifier}/sites`, site);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        error?.response?.data?.error?.message || `${vm.$t("contents.fail-message")}`
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    ToastHelpers.createSuccessToast(`${site.siteTitle} ${vm.$t("contents.created")}`);
    return response?.data;
  }

  static async updateSite(site, siteInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.patch(`/sites/${siteInternalIdentifier}`, site);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        error?.response?.data?.error?.message || `${vm.$t("contents.fail-message")}`
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    ToastHelpers.createSuccessToast(`${site.siteTitle} ${vm.$t("contents.updated")}`);
    return response?.data;
  }

  static async deleteSite(siteInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.delete(`/sites/${siteInternalIdentifier}`);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        error?.response?.data?.error?.message || vm.$t(`delete-failed`)
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    ToastHelpers.createSuccessToast(`Site ${vm.$t("delete-success")}`);
    return response?.data;
  }
}
