import vm from "../main";
import axios from "./PointrCloudAxios";
import Helpers from "@/helpers/Helpers";
import ToastHelpers from "@/helpers/ToastHelpers";

export default class ContentService {
  static CONTENT_TYPES = {
    POI: "POI",
    BEACON: "BEACON",
    OBSTACLE: "OBSTACLE",
    MAP_OBJECT: "MAP_OBJECT",
    LEVEL_GEOFENCE: "LEVEL_GEOFENCE",
    GRAPH: "GRAPH"
  };

  static async getFeatures(type, buildingInternalIdentifier, levelIndex) {
    let response;
    const endpoint = this.generateEndpoint(type);
    try {
      response = await axios.get(`/buildings/${buildingInternalIdentifier}/levels/${levelIndex}/${endpoint}/draft`);
    } catch (error) {
      console.error(error);
    }
    return response?.data?.result;
  }

  static generateEndpoint(type) {
    let endpoint = "";
    switch (type) {
      case this.CONTENT_TYPES.POI:
        endpoint = "pois";
        break;
      case this.CONTENT_TYPES.BEACON:
        endpoint = "beacons";
        break;
      case this.CONTENT_TYPES.OBSTACLE:
        endpoint = "obstacles";
        break;
      case this.CONTENT_TYPES.MAP_OBJECT:
        endpoint = "mapobjects";
        break;
      case this.CONTENT_TYPES.LEVEL_GEOFENCE:
        endpoint = "geofences";
        break;
    }
    return endpoint;
  }

  static showErrorToast(response, feature, type) {
    let message;
    switch (type) {
      case this.CONTENT_TYPES.POI:
      case this.CONTENT_TYPES.OBSTACLE:
      case this.CONTENT_TYPES.MAP_OBJECT:
      case this.CONTENT_TYPES.LEVEL_GEOFENCE:
      case "global_geofences":
        message = response?.message || `${vm.$t("contents.fail-message")}`;
        break;
      case this.CONTENT_TYPES.BEACON:
        message = response?.message || `${vm.$t("contents.mapDesigner.beacon-create-failed")}`;
        break;
    }
    ToastHelpers.createServiceErrorToast(response, message);
  }

  static showSuccessToast(feature, type, isEdit) {
    let message;
    switch (type) {
      case this.CONTENT_TYPES.POI:
      case this.CONTENT_TYPES.OBSTACLE:
      case this.CONTENT_TYPES.MAP_OBJECT:
      case this.CONTENT_TYPES.LEVEL_GEOFENCE:
      case "global_geofences":
        message = `${feature.properties.name || feature.properties.fid} ${
          isEdit ? vm.$t("contents.updated") : vm.$t("contents.created")
        }`;
        break;
      case this.CONTENT_TYPES.BEACON:
        message = `${
          isEdit ? vm.$t("contents.mapDesigner.beacon-updated") : vm.$t("contents.mapDesigner.beacon-created")
        }`;
        break;
    }
    ToastHelpers.createSuccessToast(message);
  }

  static async putFeature(feature, type, isEdit) {
    let response;
    let fid = feature.properties.fid;
    if (!fid) {
      fid = Helpers.generateUuid();
      feature.properties.fid = fid;
    }
    const featureCollection = {
      type: "FeatureCollection",
      features: [feature]
    };
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.put(`/features/${fid}`, featureCollection);
    } catch (error) {
      console.error(error);
      this.showErrorToast(error?.response?.data?.error, feature, type);
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    this.showSuccessToast(feature, type, isEdit);
    return response?.data;
  }

  static async deleteFeature(feature) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.delete(`/features/${feature.properties.fid}`);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data.error,
        vm.$t("contents.mapDesigner.content-delete-failed")
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    ToastHelpers.createSuccessToast(vm.$t("contents.mapDesigner.content-deleted"));
    return response?.data;
  }

  static async getGlobalGeofences(clientInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/clients/${clientInternalIdentifier}/geofences/global/draft`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.result;
  }

  static async getGraphs(siteInternalIdentifier) {
    let response;
    try {
      response = await axios.get(`/sites/${siteInternalIdentifier}/graphs/draft`);
    } catch (error) {
      console.error(error);
    }
    return response?.data?.result;
  }

  static async postGraphBySite(siteInternalIdentifier, input) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(`/sites/${siteInternalIdentifier}/graphs`, input);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        vm.$t("contents.mapDesigner.graph-update-fail")
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    ToastHelpers.createSuccessToast(vm.$t("contents.mapDesigner.graph-update-success"));
    return response?.data;
  }

  static async autoGenerate(clientInternalIdentifier, buildingInternalIdentifier, levelIndex) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(
        `/clients/${clientInternalIdentifier}/graphs/generate?bid=${buildingInternalIdentifier}&lvl=${levelIndex}`
      );
    } catch (error) {
      console.error(error);
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.result;
  }

  static async uploadImage(request) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(`tools/upload-image`, request);
    } catch (error) {
      console.error(error);
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data;
  }
}
