<template>
  <div>
    <div class="transition-node-list-container">
      <div
        v-for="(edge, index) in displayedEdgeItems"
        :key="JSON.stringify(edge)"
        class="link-container d-flex justify-start"
      >
        <div class="vertical-line d-flex flex-column align-center justify-flex-start mt-6">
          <div class="circle big"></div>
          <div v-for="innerIndex in 5" :key="`${edge.a}${innerIndex}`" class="circle small"></div>
          <div v-if="edge.b" class="circle big"></div>
          <div
            class="direction-button-container clickable"
            :class="{ rotate: directionOptions[edge.direction].rotate }"
            @click="directionUpdated(index)"
          >
            <PtrIcon :icon="directionOptions[edge.direction].icon" />
          </div>
        </div>
        <v-list class="py-0 transition-node-list">
          <v-list-item
            :class="{ 'current-list-item': edge.a.fid === currentFid && index === firstIndexOfCurrentNode }"
            class="px-0"
            two-line
            transition="scroll-y-transition"
          >
            <v-list-item-content>
              <v-list-item-title class="paragraph-s mb-0">{{ edge.a.name }}</v-list-item-title>
              <v-list-item-subtitle class="paragraph-xs">{{ edge.a.secondaryText }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="edge.b"
            :class="{
              'current-list-item': edge.b.fid === currentFid && index === firstIndexOfCurrentNode
            }"
            class="px-0"
            two-line
            transition="scroll-y-transition"
          >
            <v-list-item-content>
              <v-list-item-title class="paragraph-s mb-0">{{ edge.b.name }}</v-list-item-title>
              <v-list-item-subtitle class="paragraph-xs">{{ edge.b.secondaryText }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <TransitionConfiguration
      :portal-travel-time="portalTravelTime"
      :is-accessible="isAccessible"
      :is-comfortable="isComfortable"
      @portalTravelTimeChanged="(newVal) => onPortalTravelTimeChanged(newVal)"
      @isAccessibleChanged="(newVal) => onIsAccessibleChanged(newVal)"
      @isComfortableChanged="(newVal) => onIsComfortableChanged(newVal)"
      @transitionPropertiesValid="
        (areTransitionPropertiesValid) => $emit('transitionPropertiesValid', areTransitionPropertiesValid)
      "
    ></TransitionConfiguration>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import TransitionConfiguration from "@/components/mapDesigner/TransitionConfiguration.vue";
import TransitionTaxonomy from "@/constants/transitionTaxonomy";

export default {
  name: "OutdoorTransitionLinks",
  components: { PtrIcon, TransitionConfiguration },
  props: {
    currentNodeObject: Object,
    currentPortalGroupId: String,
    edgeList: Array, // {a:from-fid, b: to-id, direction: a-to-b/b-to-a/bi}
    nodesIdsToAdd: Array,
    portalTravelTime: [String, Number],
    isAccessible: Boolean,
    isComfortable: Boolean,
    isOutdoor: {
      type: Boolean,
      default: false
    },
    type: String
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    directionOptions: {
      bi: { icon: "both-ways", rotate: false },
      "a-to-b": { icon: "one-way", rotate: false },
      "b-to-a": { icon: "one-way", rotate: true }
    },
    indexToLinkCustomFeature: -1
  }),
  computed: {
    ...mapState("CONTENT", ["buildings", "levels", "graphs"]),
    ...mapGetters("MAP", ["currentBuildingObject", "currentLevelObject"]),
    taxonomy() {
      return TransitionTaxonomy.TRANSITION_TYPES;
    },
    customTransitionNodes() {
      return this.graphs.filter(
        (feature) =>
          feature?.properties?.bid === undefined &&
          feature?.properties?.lvl === undefined &&
          feature?.properties?.typeCode === "building-entrance-exit"
      );
    },
    idsToNodes() {
      const nodesDict = this.customTransitionNodes.reduce((idToNodesObject, transitionNode) => {
        idToNodesObject[transitionNode?.properties.fid] = transitionNode;
        return idToNodesObject;
      }, {});
      nodesDict[this.currentNodeObject?.properties.fid] = this.currentNodeObject;
      return nodesDict;
    },
    displayedEdgeItems() {
      const processedEdgeList = [];
      this.edgeList.forEach((edge) => {
        const aObject = this.getDisplayedItem(edge.a);
        const bObject = this.getDisplayedItem(edge.b);
        if (aObject.name === undefined) {
          aObject.name = this.getName(this.idsToNodes[edge.b]);
        }
        if (bObject.name === undefined) {
          bObject.name = this.getName(this.idsToNodes[edge.a]);
        }
        processedEdgeList.push({ a: aObject, b: bObject, direction: edge.direction });
      });
      return processedEdgeList;
    },
    currentFid() {
      return this.currentNodeObject.properties.fid;
    },
    firstIndexOfCurrentNode() {
      return this.edgeList.findIndex((edge) => edge.a === this.currentFid || edge.b === this.currentFid);
    }
  },
  watch: {
    nodesIdsToAdd() {
      const fid = this.nodesIdsToAdd[0];
      const feature = this.idsToNodes[fid];
      if (feature) {
        this.add(feature);
      }
    }
  },
  methods: {
    getName(node) {
      if (node === undefined) {
        return;
      }
      const name = node?.properties.name || "Custom Transition";
      return name;
    },
    getDisplayedItem(fid) {
      const feature = this.idsToNodes[fid];
      let name = this.getName(feature);
      if (name?.length > 20) {
        name = name.substring(0, 20).padEnd(23, ".");
      }
      if (feature?.properties?.bid !== undefined && feature?.properties?.lvl !== undefined) {
        name = name.concat(" (Current Node)");
        const building = this.buildings?.find(
          (building) => building.buildingInternalIdentifier === feature?.properties?.bid
        );
        const buildingName = building?.buildingTitle;
        const levelShortTitle = building?.levels.find(
          (level) => level.levelIndex === feature?.properties.lvl
        )?.levelShortTitle;
        return { fid, name, secondaryText: `${buildingName} / ${levelShortTitle}` };
      } else {
        return { fid, name, secondaryText: "Outdoor" };
      }
    },
    directionUpdated(index) {
      const currentDirection = this.edgeList[index].direction;
      const currIdx = Object.keys(this.directionOptions).indexOf(currentDirection);
      const directionCount = Object.keys(this.directionOptions)?.length;
      const newIdx = (currIdx + 1) % directionCount;
      const newDirection = Object.keys(this.directionOptions)[newIdx];
      this.$emit("directionUpdated", { index, newDirection });
    },
    onPortalTravelTimeChanged(newVal) {
      this.$emit("portalTravelTimeChanged", Number(newVal) || 0);
    },
    onIsAccessibleChanged(newVal) {
      this.$emit("isAccessibleChanged", newVal);
    },
    onIsComfortableChanged(newVal) {
      this.$emit("isComfortableChanged", newVal);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/variables.scss";

.link-container {
  gap: #{$spacing-m};
}

.transition-node-list-container {
  padding-left: 7px;

  .v-list-item__content {
    padding: 4px 0;

    &:not(.select-feature-button-container) {
      max-width: calc(100% - 24px);
      width: 148px;
    }
  }

  .v-list-item--two-line {
    min-height: 52px;
    height: 52px;
  }
}

.vertical-line {
  gap: #{$spacing-s};
  height: 52px;
  position: relative;
}

.circle {
  border-radius: 100%;
  flex-shrink: 0;

  &.big {
    background-color: var(--v-primary-base);
    width: 8px;
    height: 8px;
  }

  &.small {
    background-color: var(--v-primary-lighten1);
    width: 4px;
    height: 4px;
  }

  &.add-icon {
    background-color: var(--v-primary-base);
    width: 20px;
    height: 20px;
  }
}

button {
  &.pl-0 {
    padding-left: 0 !important;
  }
}

::v-deep .v-list-item__title {
  align-self: start;
}

.direction-button-container {
  position: absolute;
  top: calc(50% - 6px);

  &.clickable {
    cursor: pointer;
  }
}

.transition-node-list {
  width: calc(100% - 8px);
}

.rotate {
  .custom-icon {
    ::v-deep {
      img {
        transform: rotate(180deg);
      }
    }
  }
}

.hidden {
  display: none;
}

.v-list-item:hover {
  border-color: transparent !important;
}
</style>
