<template>
  <v-dialog
    v-model="show"
    content-class="image-cropper-dialog pa-4"
    internal-activator
    persistent
    eager
    :width="getDialogWidth()"
    :height="getDialogHeight()"
  >
    <v-card class="d-flex flex-column position-relative">
      <Cropper
        ref="cropper"
        class="cropper position-relative"
        :src="img.src"
        :debounce="false"
        :canvas="{
          height: 310,
          width: 518
        }"
        :stencil-props="{
          aspectRatio
        }"
      >
      </Cropper>
      <div class="mt-3 d-flex align-center">
        <div>
          <div class="cropper-image-title">{{ fileName }}</div>
          <div class="paragraph-s">{{ $t(`${translationPath}imageCropperDescription`) }}</div>
        </div>
        <div class="d-flex">
          <v-btn plain @click="cancelCrop">{{ $t(`${translationPath}cancel`) }}</v-btn>
          <v-btn color="primary" @click="saveCroppedImage">{{ $t(`${translationPath}confirm`) }}</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
export default {
  name: "ImageCropperPopup",
  components: { Cropper },
  props: {
    file: File,
    aspectRatio: {
      type: Number,
      default: 16 / 9
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    translationPath: "contents.feature.",
    show: true,
    image: null,
    img: {}
  }),
  computed: {
    fileName() {
      if (this.file && this.file.name.length > 30) {
        const extension = this.file.name.split(".").pop();
        const fileName = this.file.name.substring(0, 30);
        return fileName + "..." + extension;
      }
      return this.file.name;
    }
  },
  created() {
    if (this.file) {
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.img.src) {
        URL.revokeObjectURL(this.img.src);
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(this.file);
      // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
      this.img = {
        src: blob,
        type: this.file.type
      };
    }
  },
  methods: {
    saveCroppedImage() {
      const result = this.$refs.cropper.getResult();
      result.canvas.toBlob((blob) => {
        this.$emit("imageCropped", blob);
      });
    },
    cancelCrop() {
      if (!this.isEdit) {
        this.$emit("cancel");
      }
      this.$emit("close");
    },
    resetStencil() {
      this.$refs.cropper.reset();
    },
    getDialogWidth() {
      return this.aspectRatio === 1 ? 560 : 550;
    },
    getDialogHeight() {
      return this.aspectRatio === 1 ? 480 : 420;
    }
  }
};
</script>

<style lang="scss" scoped>
.cropper-description {
  color: var(--v-neutral-base);
}
.cropper-image-title {
  font-size: #{$font-size-6};
}
::v-deep .image-cropper-dialog {
  background: var(--v-neutral-lighten4);
  box-shadow: 0px 4px 8px rgba(23, 25, 28, 0.16); //neutral-darken5
  border-radius: 8px;
}
::v-deep .v-card {
  box-shadow: none !important;
}
.position-relative {
  position: relative;
}
.reset-button-container {
  position: absolute;
  bottom: 4px;
  right: 8px;
}
.reset-button {
  border-radius: 8px;
  border: 1px solid rgba(144, 149, 162, 0.5); //neutral-base
  background: rgba(46, 49, 56, 0.5); //neutral-darken4
  z-index: 99999;
}
</style>
