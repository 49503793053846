<template>
  <v-expansion-panels v-model="defaultOpenPanelIndex" class="content-add-edit-expansion-panels" flat>
    <v-expansion-panel active-class="expanded" class="mt-0">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="information" />
        <div class="expansion-panel-header-text">{{ $t(`${translationPath}basic-information`) }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="beaconForm" v-model="valid">
          <v-row>
            <v-col class="pb-1">
              <v-text-field
                id="major-input"
                v-model.number="major"
                :label="$t(featureTranslationPath + 'major')"
                :rules="[rules.required, (val) => isNumber(val), (val) => isMajorValid(val)]"
                outlined
                hide-details="auto"
                dense
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-1">
              <v-text-field
                id="minor-input"
                v-model.number="minor"
                :label="$t(featureTranslationPath + 'minor')"
                :rules="[rules.required, (val) => isNumber(val), (val) => isMinorValid(val)]"
                outlined
                hide-details="auto"
                dense
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-1">
              <v-text-field
                id="uuid-input"
                v-model="uuid"
                :label="$t(featureTranslationPath + 'uuid')"
                :rules="[rules.required, (val) => isUUIDValid(val)]"
                outlined
                hide-details="auto"
                dense
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-1">
              <v-text-field
                id="eid-input"
                v-model="eid"
                :label="$t(featureTranslationPath + 'eid')"
                hide-details="auto"
                outlined
                dense
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
          <PointGeometry
            ref="pointGeometryComponent"
            :feature="feature"
            marker-class="beacon-marker"
            :features="beacons"
            selected-type="beacon"
          ></PointGeometry>
        </v-form>
        <div class="form-footer paragraph-xs mt-5">
          {{ $t(`${translationPath}required`) }}
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel active-class="expanded" class="mt-0">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="custom-integration" />
        <div class="expansion-panel-header-text">{{ $t(`${translationPath}custom-integration`) }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <CustomIntegration
          ref="customIntegration"
          :extra-data-prop="extraData"
          :feature-id="featureId"
          @integrationUpdated="setFormDirty"
          @setFormDirty="setFormDirty"
        ></CustomIntegration>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <slot name="danger-zone"></slot>
  </v-expansion-panels>
</template>
<script>
import { mapState } from "vuex";
import ValidationHelpers from "@/helpers/ValidationHelpers";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import PointMode from "@/helpers/drawModes/PointMode";
import ContentService from "@/services/ContentService";
import PointGeometry from "@/components/mapDesigner/PointGeometry.vue";
import CustomIntegration from "@/components/shared/CustomIntegration.vue";
import MapHelpers from "@/helpers/MapHelpers";

const UINT16_MAX = 65535;
const MIN_VALUE = -1;

export default {
  components: { PointGeometry, PtrIcon, CustomIntegration },
  props: {
    featureId: [String, Number]
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    featureTranslationPath: "contents.feature.",
    feature: {},
    eid: "",
    keywords: [],
    valid: false,
    major: "",
    minor: "",
    isEdit: false,
    uuid: "",
    defaultOpenPanelIndex: 0,
    extraData: {}
  }),
  computed: {
    ...mapState("CONTENT", ["beacons", "isFormDirty"]),
    ...mapState("MAP", ["currentSite", "currentBuilding", "currentLevel", "drawnCoordinates", "isMapBorderEnabled"]),
    rules() {
      return {
        required: (value) => ValidationHelpers.isRequired(value)
      };
    }
  },
  watch: {
    featureId: {
      immediate: true,
      handler() {
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        PointMode.clearPoints();
        if (this.featureId === "add") {
          this.$refs.beaconForm?.resetValidation();
          this.isEdit = false;
          this.major = "";
          this.minor = "";
          this.uuid = "";
          this.eid = "";
          this.extraData = {};
          this.$refs?.geometrySection?.reset();
          this.feature = {};
          return;
        }
        this.feature = this.beacons.find((beacon) => beacon?.properties?.fid === this.featureId);
        if (this.feature) {
          this.isEdit = true;
          this.major = this.feature.properties.major;
          this.minor = this.feature.properties.minor;
          this.uuid = this.feature.properties.uuid;
          this.eid = this.feature.properties.eid;
          this.extraData = this.feature.properties?.extra || this.feature.properties?.extraData;
          this.$refs?.geometrySection?.reset();
          this.$store.commit("MAP/DRAWN_COORDINATES", this.feature.geometry.coordinates);
        }
      }
    },
    valid() {
      this.emitValidationStatusForForm();
    },
    drawnCoordinates() {
      this.emitValidationStatusForForm();
    },
    isMapBorderEnabled() {
      this.emitValidationStatusForForm();
    }
  },
  created() {
    MapHelpers.enableMapInteractions();
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
    this.$store.dispatch("MAP/MAP_ENTER_EDIT_MODE");
  },
  beforeDestroy() {
    MapHelpers.disableMapInteractions();
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
    this.$store.dispatch("MAP/MAP_EXIT_EDIT_MODE");
  },
  methods: {
    async save() {
      let coordinates;
      try {
        coordinates = JSON.parse(this.drawnCoordinates || "[]");
      } catch (e) {
        coordinates = this.drawnCoordinates;
      }
      let extraData = this.$refs?.customIntegration?.getExtraData();
      if (!extraData) {
        extraData = this.feature?.properties?.extra || this.feature?.properties?.extraData;
      }
      const request = {
        type: "Feature",
        properties: {
          typeCode: "ibeacon",
          fid: this.feature?.properties?.fid,
          eid: this.eid?.trim(),
          sid: Number(this.currentSite),
          bid: Number(this.currentBuilding),
          lvl: Number(this.currentLevel),
          major: this.major,
          minor: this.minor,
          uuid: this.uuid?.trim(),
          style: {},
          extra: extraData
        },
        geometry: {
          type: "Point",
          coordinates: coordinates
        }
      };
      const response = await ContentService.putFeature(request, ContentService.CONTENT_TYPES.BEACON, this.isEdit);

      if (response?.createdTimestampUtcEpochSeconds) {
        this.$store.commit("CONTENT/LOCAL_BEACONS", { feature: request, isEdit: this.isEdit });
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        return true;
      } else {
        return false;
      }
    },

    async deleteContent() {
      const response = await ContentService.deleteFeature(this.feature);
      if (response?.createdTimestampUtcEpochSeconds) {
        this.$store.commit("CONTENT/LOCAL_BEACONS", { feature: this.feature, isDelete: true });
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        return true;
      } else {
        return false;
      }
    },
    isUUIDValid(value) {
      return ValidationHelpers.isUUID(value) || this.$t(`${this.translationPath}is-uuid`);
    },
    isNumber(value) {
      return ValidationHelpers.isInteger(value) || this.$t(`${this.translationPath}is-number`);
    },
    isMajorValid(value) {
      return (
        (value >= MIN_VALUE && value <= UINT16_MAX) ||
        this.$t(`${this.translationPath}is-between`, { minValue: MIN_VALUE, maxValue: UINT16_MAX })
      );
    },
    isMinorValid(value) {
      return (
        (value >= MIN_VALUE && value <= UINT16_MAX) ||
        this.$t(`${this.translationPath}is-between`, { minValue: MIN_VALUE, maxValue: UINT16_MAX })
      );
    },
    emitValidationStatusForForm() {
      this.$emit(
        "valid",
        this.valid && this.drawnCoordinates && this.drawnCoordinates?.length !== 0 && !this.isMapBorderEnabled
      );
    },
    setFormDirty() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    }
  }
};
</script>
