<template>
  <v-row
    v-if="selectedType"
    class="d-flex flex-column"
    :class="{
      'editing-geometry': isMapBorderEnabled
    }"
  >
    <v-col>
      <div class="content-add-edit-subtitle">{{ $t(`${translationPath}geometry`) }}</div>
      <div v-if="(!isDrawingFinished && geometryAction !== 'modify') || !drawnCoordinates">
        <div class="pb-2 geometry-sub-title">
          {{
            isMapBorderEnabled ? $t(`${translationPath}geometry-guidance`) : $t(`${translationPath}geometry-sub-base`)
          }}
        </div>
        <v-btn
          value="Point"
          class="mr-2 geometry-button"
          :class="drawMode === 'Point' ? 'selected' : ''"
          outlined
          @click="enableMarker"
        >
          <PtrIcon icon="draw-marker" color="#9095A2" editable-color />
        </v-btn>
      </div>
      <div v-else>
        <div class="pb-2 geometry-sub-title">{{ $t(`${translationPath}geometry-sub-alternative`) }}</div>
        <v-btn-toggle v-model="geometryAction">
          <div class="geometry-button-container mr-2" :class="{ selected: geometryAction === 'modify' }">
            <v-btn
              value="modify"
              class="geometry-button mb-1"
              :class="{ 'disable-events': geometryAction }"
              :color="geometryAction === 'modify' ? 'primary' : 'white'"
              :disabled="isModifyDisabled"
              @click="editGeometry"
            >
              <PtrIcon
                icon="modify-geometry"
                :color="geometryAction === 'modify' ? '#FFFFFF' : '#9095A2'"
                editable-color
              />
            </v-btn>
            {{ $t(`${translationPath}modify`) }}
          </div>
          <div class="geometry-button-container apply-color-filter">
            <v-btn
              value="clear"
              class="geometry-button mb-1"
              :class="{ 'disable-events': geometryAction }"
              color="white"
              :disabled="isClearDisabled"
              @click="clearCoordinates"
            >
              <PtrIcon icon="clear-geometry" />
            </v-btn>
            {{ $t(`${translationPath}clear`) }}
          </div>
        </v-btn-toggle>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import { Marker, Popup } from "maplibre-gl";
import MapHelpers from "@/helpers/MapHelpers";

export default {
  components: { PtrIcon },
  props: {
    selectedType: String,
    feature: Object,
    markerClass: String,
    features: Array,
    buildingEntranceExitTwinFeatures: Array
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    drawMode: undefined,
    editGeometryClicked: false,
    isDrawingFinished: false,
    geometryAction: undefined,
    clickCount: 0,
    currentMarker: undefined,
    shadowMarker: undefined,
    markers: [],
    transitionWarningPopups: {}
  }),
  computed: {
    ...mapState("MAP", [
      "map",
      "drawnCoordinates",
      "isMapBorderEnabled",
      "pointMarkers",
      "currentSite",
      "currentBuilding",
      "currentLevel",
      "isAddEditPanelActive"
    ]),

    isModifyDisabled() {
      return this.geometryAction !== undefined && this.geometryAction !== "modify";
    },
    isClearDisabled() {
      return this.geometryAction !== undefined && this.geometryAction !== "clear";
    },
    markerSelector() {
      return `.${this.markerClass}.selected`;
    },
    isBeacon() {
      return this.selectedType === "beacon";
    }
  },
  watch: {
    feature: {
      immediate: true,
      handler() {
        this.currentMarker?.remove();
        this.currentMarker = undefined;
        this.generateMarkers();
        if (this.feature) {
          let marker = document.querySelector(this.markerSelector);
          marker?.classList?.remove("selected");
          const currentFid = this.feature?.properties?.fid;
          this.currentMarker = this.markers.find((marker) => marker._element?.fid === currentFid);

          if (this.isBeacon) {
            const otherMarkers = document.getElementsByClassName(this.markerClass);
            [...otherMarkers].forEach((marker) => {
              if (marker.classList.contains(currentFid)) {
                return;
              }
              marker.classList?.add("ghost");
            });
          }
          marker = document.getElementsByClassName(this.feature?.properties?.fid);
          marker?.[0]?.classList?.add("selected");
          this.isDrawingFinished = true;
        } else if (this.isBeacon) {
          const markers = document.getElementsByClassName(this.markerClass);
          [...markers].forEach((marker) => {
            marker.classList?.add("ghost");
          });
        }
      }
    },
    currentLevel() {
      this.clearShadowMarker();
      this.generateMarkers();
      this.generateShadowMarker();
      const fid = this.feature?.properties?.fid;
      if (this.currentLevel === Number(this.$route.params.levelId) && fid) {
        const marker = document.getElementsByClassName(fid);
        marker?.[0]?.classList?.add("selected");
      }
    },
    currentBuilding() {
      this.clearShadowMarker();
      this.generateMarkers();
      this.generateShadowMarker();
      if (this.currentBuilding === Number(this.$route.params.buildingId)) {
        const marker = document.getElementsByClassName(this.feature?.properties?.fid);
        marker?.[0]?.classList?.add("selected");
      }
    },
    selectedType() {
      if (this.drawnCoordinates) {
        const marker = document.querySelector(this.markerSelector);
        if (marker) {
          if (this.selectedType === "building-entrance-exit") {
            marker.classList.remove("neighbor-marker-error");
            marker.classList.add("neighbor-marker-warning");
          } else {
            marker.classList.remove("neighbor-marker-warning");
            marker.classList.add("neighbor-marker-error");
          }
          marker.src = require(`@/assets/custom-icons/${this.selectedType}.svg`);
        }
      }
    }
  },
  created() {
    MapHelpers.enableMapInteractions();
    this.map.on("contextmenu", this.mapRightClicked);
  },
  beforeDestroy() {
    MapHelpers.disableMapInteractions();
    this.clearMarkers();
    this.clearShadowMarker();
    this.currentMarker?.remove();
    const marker = document.querySelector(this.markerSelector);
    marker?.classList?.remove("selected");
    this.map.off("contextmenu", this.mapRightClicked);
    this.cancelClicked();
  },
  methods: {
    enableMarker() {
      if (this.drawMode !== "Point") {
        this.drawMode = "Point";
        this.isDrawingFinished = false;
        this.map.once("click", this.drawMarker);
        this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
        this.$store.commit(
          "MAP/GUIDANCE_MESSAGE",
          this.$t("contents.guidance.click-map-transition", { type: this.typeTitle })
        );
      }
    },
    drawMarker(event) {
      try {
        this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.finish-drawing"));
        let markerElement;
        let warningPopup;
        if (!this.isBeacon) {
          markerElement = document.createElement("img");
          markerElement.src = require(`@/assets/custom-icons/${this.selectedType}.svg`);
          if (this.selectedType === "building-entrance-exit") {
            markerElement.className = `${this.markerClass} selected neighbor-marker-warning`;
          } else {
            markerElement.className = `${this.markerClass} selected neighbor-marker-error`;
          }
          warningPopup = new Popup({
            closeButton: false,
            closeOnClick: false,
            anchor: "bottom",
            className: "warning-popup"
          });
          warningPopup?.setLngLat(event.lngLat);
          if (this.selectedType === "building-entrance-exit") {
            markerElement.addEventListener("mouseenter", () => {
              warningPopup?.setHTML("<p>- Not connected to any path</p>");
              warningPopup?.addTo(this.map);
            });
          } else {
            markerElement.addEventListener("mouseenter", () => {
              warningPopup?.setHTML("<p>- Not connected to any path</p><p>- Not connected to any transition</p>");
              warningPopup?.addTo(this.map);
            });
          }
          markerElement.addEventListener("mouseleave", () => {
            this.clearPopups();
          });
        } else {
          markerElement = document.createElement("div");
          markerElement.className = `${this.markerClass} selected`;
        }

        const marker = new Marker({ element: markerElement, draggable: true }).setLngLat(event.lngLat).addTo(this.map);
        this.$store.commit("MAP/DRAWN_COORDINATES", [
          Number(event.lngLat.lng.toFixed(8)),
          Number(event.lngLat.lat.toFixed(8))
        ]);
        marker.on("drag", () => {
          this.clearPopups();
          this.$store.commit("MAP/DRAWN_COORDINATES", [
            Number(marker._lngLat.lng.toFixed(8)),
            Number(marker._lngLat.lat.toFixed(8))
          ]);
        });
        marker.on("dragend", () => {
          warningPopup?.setLngLat(marker._lngLat)?.addTo(this.map);
        });
        this.currentMarker = marker;
        this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
      } catch (e) {
        console.debug(e);
      }
    },
    editGeometry() {
      if (!this.currentMarker) {
        this.currentMarker = this.markers.find((marker) => marker._element.fid === this.feature?.properties?.fid);
      }
      this.currentMarker.setDraggable(true);
      this.currentMarker.on("drag", () => {
        if (!this.isBeacon) {
          this.transitionWarningPopups[this.currentMarker?._element?.fid]?.remove();
        }
        this.$store.commit("MAP/DRAWN_COORDINATES", [
          Number(this.currentMarker._lngLat.lng.toFixed(8)),
          Number(this.currentMarker._lngLat.lat.toFixed(8))
        ]);
      });
      this.currentMarker.on("dragend", () => {
        if (!this.isBeacon) {
          this.transitionWarningPopups[this.currentMarker?._element?.fid]
            ?.setLngLat(this.currentMarker._lngLat)
            ?.addTo(this.map);
        }
      });

      const options = this.isAddEditPanelActive
        ? {
            padding: {
              top: 250,
              bottom: 250,
              left: 90,
              right: 410 // Left panel width is 320px
            }
          }
        : undefined;
      MapHelpers.fitAroundPoint(
        [this.currentMarker._lngLat.lng.toFixed(8), this.currentMarker._lngLat.lat.toFixed(8)],
        options
      );
      this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", true);
      this.$store.commit("MAP/GUIDANCE_MESSAGE", this.$t("contents.guidance.finish-drawing"));
      this.isDrawingFinished = false;
      this.editGeometryClicked = true;
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    generateMarkers() {
      this.clearMarkers();
      const currentFid = this.feature?.properties?.fid;
      this.features?.forEach((feature) => {
        let markerElement;
        if (!this.isBeacon) {
          let buildingEntranceExitTwinFeature = this.buildingEntranceExitTwinFeatures.find(
            (f) =>
              f?.properties?.portalGroupId === feature?.properties?.portalGroupId &&
              f?.properties?.fid !== feature?.properties?.fid
          );
          markerElement = document.createElement("img");
          try {
            markerElement.src = require(`@/assets/custom-icons/${feature.properties.typeCode}.svg`);
          } catch (e) {
            markerElement.src = require(`@/assets/custom-icons/custom-transition.svg`);
          }
          markerElement.className = `${this.markerClass} ${feature.properties.fid}`;
          if (feature?.properties?.neighbors?.length === 0 && !feature?.properties?.portalGroupId) {
            markerElement.classList.add("neighbor-marker-error");
          } else if (
            (feature?.properties?.typeCode === "building-entrance-exit" &&
              buildingEntranceExitTwinFeature?.properties?.neighbors?.length === 0) ||
            feature?.properties?.neighbors?.length === 0 ||
            !feature?.properties?.portalGroupId
          ) {
            markerElement.classList.add("neighbor-marker-warning");
          }
          let warningPopup = new Popup({
            closeButton: false,
            closeOnClick: false,
            anchor: "bottom",
            className: "warning-popup"
          });
          warningPopup?.setLngLat(feature?.geometry?.coordinates);
          markerElement.addEventListener("mouseenter", () => {
            if (feature?.properties?.neighbors?.length === 0 && !feature?.properties?.portalGroupId) {
              warningPopup?.setHTML("<p>- Not connected to any path</p><p>- Not connected to any feature</p>");
            } else if (feature?.properties?.neighbors?.length === 0) {
              warningPopup?.setHTML("<p>- Not connected to any path</p>");
            } else if (!feature?.properties?.portalGroupId) {
              warningPopup?.setHTML("<p>- Not connected to any transition</p>");
            }
            warningPopup?.addTo(this.map);
          });
          markerElement.addEventListener("mouseleave", () => {
            this.clearPopups();
          });
          this.transitionWarningPopups[feature?.properties?.fid] = warningPopup;
        } else {
          markerElement = document.createElement("div");
          markerElement.className = `${this.markerClass} ${feature.properties.fid}`;
        }

        markerElement.fid = feature.properties.fid;
        markerElement.addEventListener("click", (e) => {
          if (this.isMapBorderEnabled) {
            const fid = e.target.fid;
            const selectedFeature = this.features.find((feature) => feature.properties.fid === fid);
            this.$emit("featureSelected", selectedFeature);
          } else if (this.feature?.properties?.fid === markerElement.fid) {
            this.geometryAction = "modify";
            this.editGeometry();
          }
        });
        const marker = new Marker({ element: markerElement, draggable: false })
          .setLngLat(
            currentFid === feature.properties.fid
              ? this.drawnCoordinates || feature.geometry.coordinates
              : feature.geometry.coordinates
          )
          .addTo(this.map);
        this.markers.push(marker);
      });
      this.$store.commit("MAP/POINT_MARKERS", this.markers);
      if (
        !this.isEdit &&
        this.currentLevel === Number(this.$route.params.levelId) &&
        this.currentBuilding === Number(this.$route.params.buildingId)
      ) {
        this.currentMarker?.addTo(this.map);
      }
    },
    clearMarkers() {
      this.markers?.forEach((marker) => {
        marker.remove();
      });
      this.currentMarker?.remove();
      this.markers = [];
      this.$store.commit("MAP/POINT_MARKERS", this.markers);
    },
    clearPopups() {
      const transitionPopups = document.getElementsByClassName("warning-popup");
      if (transitionPopups.length) {
        transitionPopups[0].remove();
      }
    },
    generateShadowMarker() {
      const currentFid = this.feature?.properties?.fid;
      const transition = this.features.find((feature) => feature.properties.fid === currentFid);
      const buildingId = parseInt(this.$route.params.buildingId, 10);
      const levelId = parseInt(this.$route.params.levelId, 10);
      const shouldAddOnIsEdit =
        (transition && this.currentLevel !== transition.properties.lvl) ||
        (transition &&
          this.currentBuilding !== transition.properties.bid &&
          this.currentLevel !== transition.properties.lvl);
      const shouldAddOnAdd =
        (!this.isEdit && this.currentLevel !== levelId) ||
        (!this.isEdit && this.currentBuilding !== buildingId && this.currentLevel !== levelId);
      if (shouldAddOnIsEdit || shouldAddOnAdd) {
        let markerElement = document.createElement("div");
        markerElement.className = "shadow-marker";
        this.shadowMarker = new Marker({ element: markerElement, draggable: false })
          .setLngLat(this.drawnCoordinates)
          .addTo(this.map);
      }
    },
    clearShadowMarker() {
      if (this.shadowMarker) {
        this.shadowMarker.remove();
        this.shadowMarker = undefined;
      }
    },
    clearCoordinates() {
      this.$store.commit("MAP/DRAWN_COORDINATES", undefined);
      this.currentMarker?.remove();
      this.currentMarker = undefined;
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    mapRightClicked() {
      if (this.drawnCoordinates && this.isMapBorderEnabled) {
        this.isDrawingFinished = true;
        this.editGeometryClicked = false;
        this.currentMarker?.setDraggable(false);
        this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
        this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
        this.drawMode = undefined;
        this.geometryAction = undefined;
      }
    },
    cancelClicked() {
      this.drawMode = undefined;
      this.$store.commit("MAP/DRAWN_COORDINATES", undefined);
      this.currentMarker?.remove();
      this.currentMarker = undefined;
    }
  }
};
</script>
<style lang="scss" scoped>
.editing-geometry {
  background: var(--v-primary-lighten4);
}
</style>
