<template>
  <div>
    <v-menu right offset-x transition="slide-x-transition" content-class="poi-properties">
      <template #activator="{ on, attrs }">
        <v-btn
          class="add-new-property-button paragraph-s py-2 px-0"
          v-bind="attrs"
          :disabled="groupedPropertyItems.length === 0"
          plain
          color="primary"
          v-on="on"
        >
          <div class="icon-background pa-1 mr-2 d-flex align-center justify-center">
            <PtrIcon width="16" height="16" icon="plus" color="#FFFFFF" editable-color></PtrIcon>
          </div>
          {{ $t("addNew") }}
        </v-btn>
      </template>
      <v-list v-for="group in groupedPropertyItems" :key="group.header" subheader dense>
        <v-subheader>{{ group.header }}</v-subheader>
        <v-list-item v-for="item in group.items" :key="item.key" class="paragraph-s" @click="() => addProperty(item)">
          {{ item.title }}
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";

export default {
  components: { PtrIcon },
  props: {
    addedProperties: Object,
    propertiesToShow: Array
  },
  computed: {
    properties() {
      return this.propertiesToShow;
    },
    groupedPropertyItems() {
      const groupedProperties = [];
      const groupedValues = {};

      this.properties.forEach((propertyItem) => {
        if (
          Object.keys(this.addedProperties).find((addedProperty) => {
            return addedProperty.startsWith(propertyItem.key) || propertyItem.key.startsWith(addedProperty);
          })
        ) {
          return;
        }
        if (!groupedValues[propertyItem.valueType]) {
          groupedValues[propertyItem.valueType] = [];
          groupedProperties.push({ header: propertyItem.valueType.toUpperCase() });
        }
        groupedValues[propertyItem.valueType].push(propertyItem);
      });

      groupedProperties.forEach((group) => {
        group.items = groupedValues[group.header.toLowerCase()];
      });
      return groupedProperties;
    }
  },
  methods: {
    addProperty(item) {
      this.$emit("propertyAdded", item);
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/variables.scss";

.add-new-property-button {
  ::v-deep .v-btn__content {
    font-weight: 400;
    opacity: 1 !important;
  }

  &:focus,
  &:hover {
    ::v-deep .v-btn__content {
      opacity: 0.8 !important;
    }
  }

  .icon-background {
    border-radius: 50%;
    background-color: var(--v-primary-base);
    width: 20px;
    height: 20px;

    ::v-deep svg {
      stroke: var(--v-white-base);

      * {
        stroke-width: 0.2;
      }
    }
  }
}

.poi-properties {
  width: #{$content-add-edit-panel-max-child-width};
  max-height: 50%;
  overflow: hidden overlay;
  margin-left: 8px;
}
</style>
