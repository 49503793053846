<template>
  <span>
    <span class="title-property">{{ $t(isEdit ? "editing-property" : "adding-property") }}</span>
    <v-row class="mt-0">
      <v-col class="py-1">
        <v-text-field
          v-model.trim="propertyName"
          label="Property Name"
          hide-details="auto"
          :rules="[rules.required]"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-1">
        <v-text-field
          v-model.trim="propertyValue"
          label="Property Value"
          :rules="[rules.required]"
          hide-details="auto"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn class="property-buttons paragraph-s cancel-btn" outlined depressed @click="cancelClicked">{{
          $t("cancel")
        }}</v-btn>
        <v-btn
          class="property-buttons paragraph-s add-btn"
          :disabled="propertyName === '' || propertyValue === ''"
          outlined
          depressed
          @click="addClicked"
          >{{ isEdit ? $t("edit-property") : $t("add-property") }}</v-btn
        >
      </v-col>
    </v-row>
    <span v-if="isEdit" class="delete-property" @click="deleteClicked">Delete Property</span>
  </span>
</template>
<script>
import ValidationHelpers from "@/helpers/ValidationHelpers";
export default {
  props: {
    isEdit: Boolean,
    extraData: Object
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    showAddNew: true,
    showEmptyList: true,
    propertyName: "",
    propertyValue: ""
  }),
  computed: {
    rules() {
      return {
        required: (value) => ValidationHelpers.isRequired(value)
      };
    }
  },
  created() {
    if (this.isEdit) {
      this.propertyName = this.extraData.key;
      if (typeof this.extraData.value === "string") {
        this.propertyValue = this.extraData.value;
      } else {
        this.propertyValue = JSON.stringify(this.extraData.value);
      }
    }
  },
  methods: {
    addNewClicked() {
      this.showAddNew = false;
      this.showEmptyList = false;
    },
    cancelClicked() {
      this.$emit("cancel");
    },
    addClicked() {
      this.$emit("add", { key: this.propertyName, value: this.propertyValue });
    },
    deleteClicked() {
      this.$emit("delete");
    }
  }
};
</script>
<style lang="scss">
.property-buttons {
  border-radius: 8px;
  font-weight: 400;
  color: var(--v-white-base) !important;
  min-height: 40px !important;
  height: 40px !important;
}
.cancel-btn {
  background-color: var(--v-neutral-base) !important;
  width: 100px;
}
.add-btn {
  width: 140px;
  background-color: var(--v-primary-base) !important;
  margin-left: 8px;
  &.v-btn--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: var(--v-neutral-darken4) !important;
  }
}
.delete-property {
  font-weight: 400;
  font-size: #{$font-size-2};
  color: var(--v-error-base);
  cursor: pointer;
}
.title-property {
  font-weight: 400;
  font-size: #{$font-size-1};
  color: var(--v-primary-lighten1);
}
</style>
