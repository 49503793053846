<template>
  <v-expansion-panels
    ref="poiAddEditExpansionPanels"
    v-model="defaultOpenPanelIndex"
    class="content-add-edit-expansion-panels"
    flat
  >
    <v-expansion-panel active-class="expanded" class="mt-0">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="information" />
        <div class="expansion-panel-header-text">{{ $t(`${translationPath}details`) }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="poiForm" v-model="isDetailsValid" class="mb-5">
          <v-row class="ma-0">
            <v-col class="py-0">
              <v-autocomplete
                id="content-type-input"
                v-model="selectedType"
                :items="typeList"
                item-text="title"
                item-value="code"
                outlined
                dense
                :label="$t(`${translationPath}types`)"
                hide-details="auto"
                :menu-props="{ closeOnContentClick: true }"
                :rules="[autoCompleteRequired]"
                @change="setFormDirty"
              >
                <template #item="{ item }">
                  <v-list-item @click="onTypeSelected(item.code)">
                    <v-list-item-content>
                      <v-list-item-title> {{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template #append>
                  <PtrIcon icon="caret-down" />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col class="py-0">
              <v-text-field
                id="name-input"
                v-model.trim="name"
                :rules="isPoi ? [rules.featureName, rules.characterValidation] : [rules.characterValidation]"
                :label="isPoi ? $t(featureTranslationPath + 'name-required') : $t(featureTranslationPath + 'name')"
                hide-details="auto"
                outlined
                dense
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
        </v-form>
        <AdditionalProperties
          ref="additionalPropertiesComponent"
          :properties="additionalProperties"
          :properties-to-show="propertiesToShow"
          @valid="(isValid) => (isPropertiesValid = isValid)"
          @propertyAdded="(item) => addNewProperty(item)"
          @propertyDeleted="(item) => deleteProperty(item)"
          @propertyUpdated="(item, newValue) => updateProperty(item, newValue)"
        ></AdditionalProperties>
        <div v-show="selectedType">
          <GeometrySection
            ref="geometrySection"
            :feature-id="featureId"
            :should-show-polygon-icon="shouldShowPolygonIcon"
            :should-show-point-icon="shouldShowPointIcon"
            :is-edit="isEdit"
            is-circle-enabled
          ></GeometrySection>
        </div>
        <div class="form-footer paragraph-xs mt-4">
          {{ $t(`${translationPath}required`) }}
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel active-class="expanded" class="mt-0">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="custom-integration" />
        <div class="expansion-panel-header-text">{{ $t(`${translationPath}custom-integration`) }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <CustomIntegration
          ref="customIntegration"
          :extra-data-prop="extraData"
          :feature-id="featureId"
          @integrationUpdated="setFormDirty"
          @setFormDirty="setFormDirty"
        ></CustomIntegration>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <slot name="danger-zone"></slot>
  </v-expansion-panels>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import GeometrySection from "@/components/mapDesigner/GeometrySection.vue";
import PointMode from "@/helpers/drawModes/PointMode";
import ValidationHelpers from "@/helpers/ValidationHelpers";
import ContentService from "@/services/ContentService";
import CustomIntegration from "@/components/shared/CustomIntegration.vue";
import AdditionalProperties from "@/components/mapDesigner/AdditionalProperties.vue";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import MapHelpers from "@/helpers/MapHelpers";
import PoiProperties from "@/constants/poiProperties";
import ToastHelpers from "@/helpers/ToastHelpers";

import booleanWithin from "@turf/boolean-within";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { polygon, point } from "@turf/helpers";

export default {
  components: { GeometrySection, CustomIntegration, PtrIcon, AdditionalProperties },
  props: {
    featureId: [String, Number]
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    featureTranslationPath: "contents.feature.",
    feature: {},
    selectedType: undefined,
    featureKeywordDisplayMode: "",
    taxonomyType: "",
    name: undefined,
    isPropertiesValid: false,
    isDetailsValid: false,
    isEdit: false,
    defaultOpenPanelIndex: 0,
    extraData: {},
    additionalProperties: {},
    isUploadFailed: false
  }),
  computed: {
    ...mapState("CONTENT", ["pois", "mapObjects", "poiTypeCodesObj", "mapObjectsTypeCodesObj", "isFormDirty"]),
    ...mapState("MAP", [
      "mapDraw",
      "currentSite",
      "currentBuilding",
      "currentLevel",
      "drawnCoordinates",
      "mapMode",
      "isMapBorderEnabled"
    ]),
    ...mapGetters("MAP", ["currentBuildingObject", "currentLevelObject"]),
    taxonomy() {
      return this.$store.state.taxonomy;
    },
    isPoi() {
      if (this.selectedType) {
        const typeCode = this.selectedType;
        const classOfType = Object.values(this.taxonomy).find((item) => item.code === typeCode)?.properties?.class;
        return classOfType === "poi";
      } else {
        return this.$route.name === "PoiList";
      }
    },
    propertiesToShow() {
      if (this.isPoi) {
        return PoiProperties.POI_PROPERTIES;
      } else {
        return PoiProperties.POI_PROPERTIES.filter(
          (property) => property.key === "eid" || property.key === "description" || property.key === "keywords"
        );
      }
    },
    typeList() {
      let suggestedTypeCodesArr = [];
      let suggestedTypes = [];
      let allTypes = [];

      if (this.isPoi) {
        suggestedTypeCodesArr = Object.keys(this.poiTypeCodesObj);
      } else {
        suggestedTypeCodesArr = Object.keys(this.mapObjectsTypeCodesObj);
      }

      suggestedTypes = Object.values(this.taxonomy).filter((item) => {
        return item.properties.class === "poi" && suggestedTypeCodesArr.includes(item.code);
      });

      const routeName = this.$route.name;
      allTypes = Object.values(this.taxonomy).filter((item) => {
        if (routeName === "PoiList") {
          return item.properties.class === "poi";
        } else {
          return item.properties.class === "poi" || item.properties.class === "none";
        }
      });

      let autoCompleteItems = [];
      if (suggestedTypes.length) {
        autoCompleteItems = [
          {
            header: "SUGGESTED TYPES"
          },
          ...suggestedTypes
        ];
        if (suggestedTypes.length === allTypes.length) {
          return autoCompleteItems;
        }
      }
      return [
        ...autoCompleteItems,
        {
          header: "ALL TYPES"
        },
        ...allTypes
      ];
    },
    rules() {
      return {
        featureName: (value) => {
          return ValidationHelpers.isRequired(value?.trim());
        },
        required: (value) => ValidationHelpers.isRequired(value),
        characterValidation: (value) => {
          return ValidationHelpers.isNameLengthValid(value);
        }
      };
    },
    shouldShowPointIcon() {
      return this.taxonomyType?.properties?.shape?.includes("point");
    },
    shouldShowPolygonIcon() {
      return this.taxonomyType?.properties?.shape?.includes("polygon");
    }
  },
  watch: {
    featureId: {
      immediate: true,
      handler() {
        this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: undefined, shouldKeepFormClean: true });
        this.$store.commit("MAP/DRAWN_COORDINATES", undefined);
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        PointMode.clearPoints();
        const temp = this.mapObjects.find((mapObject) => mapObject.properties.fid === this.featureId);
        this.feature = structuredClone(temp);
        if (this.feature) {
          this.isEdit = true;
          this.selectedType = this.feature.properties.typeCode;
          this.name = this.feature.properties.name;
          // TODO: Remove when translation design is ready
          this.nameAr = this.feature.properties["name_ar"] ? this.feature.properties["name_ar"] : undefined;
          this.descriptionAr = this.feature.properties["description_ar"]
            ? this.feature.properties["description_ar"]
            : undefined;
          this.setAdditionalProperties();
          this.extraData = this.feature.properties?.extra || this.feature.properties?.extraData;
          this.taxonomyType = this.taxonomy[this.selectedType];
          this.$refs?.geometrySection?.reset();
          this.$store.commit("MAP/DRAWN_COORDINATES", this.feature.geometry.coordinates);

          if (this.feature.geometry.type === "Polygon" || this.feature.geometry.type === "MultiPolygon") {
            this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: this.feature, shouldKeepFormClean: true });
          } else if (this.feature.geometry.type === "Point") {
            PointMode.addPoint(this.drawnCoordinates || [], {
              draggable: false,
              fid: this.feature.properties.fid
            });
          }
        } else {
          this.isEdit = false;
          this.selectedType = undefined;
          this.name = "";
          this.taxonomyType = "";
          this.extraData = {};
          this.additionalProperties = {};
          this.$refs.poiForm?.resetValidation();
        }
      }
    },
    selectedType() {
      this.taxonomyType = this.taxonomy[this.selectedType];
    },
    isPoi() {
      this.additionalProperties = this.filterProperties(this.additionalProperties);
    },
    isPropertiesValid() {
      this.emitValidationStatusForForm();
    },
    isDetailsValid() {
      this.emitValidationStatusForForm();
    },
    name() {
      this.emitValidationStatusForForm();
    },
    drawnCoordinates() {
      this.emitValidationStatusForForm();
    },
    isMapBorderEnabled() {
      this.emitValidationStatusForForm();
    },
    additionalProperties() {
      if (Object.keys(this.additionalProperties).length === 0) {
        this.isPropertiesValid = true;
      }
    }
  },
  created() {
    MapHelpers.enableMapInteractions();
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
    this.$store.dispatch("MAP/MAP_ENTER_EDIT_MODE");
  },
  beforeDestroy() {
    MapHelpers.disableMapInteractions();
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
    this.$store.dispatch("MAP/MAP_EXIT_EDIT_MODE");
  },
  methods: {
    //TODO: Move to a helper
    autoCompleteRequired(value) {
      if (value === null || value === undefined) {
        this.isDetailsValid = false;
        // TODO: Move to language file
        return this.$t("contents.validations.type-must-be-selected");
      }
      this.isDetailsValid = true;
      return true;
    },
    async save() {
      let coordinates;
      try {
        coordinates = JSON.parse(this.drawnCoordinates || "[]");
      } catch (e) {
        coordinates = this.drawnCoordinates;
      }
      const isCoordinatesPolygon = Array.isArray(coordinates?.[0]);
      const isMultiPolygon = this.mapDraw.getAll()?.features?.[0]?.geometry?.type === "MultiPolygon";
      const geometryType = isCoordinatesPolygon ? "Polygon" : "Point";

      if (isMultiPolygon) {
        let isSuccess;
        await Promise.all(
          coordinates.map(async (coordinate, index) => {
            if (index === 0) {
              isSuccess = await this.createRequestAndPut(
                this.feature?.properties?.fid,
                this.name,
                {
                  type: geometryType,
                  coordinates: coordinate
                },
                true
              );
            } else {
              isSuccess = await this.createRequestAndPut(
                undefined,
                this.isPoi ? this.name + "#" + index : undefined,
                {
                  type: geometryType,
                  coordinates: coordinate
                },
                false
              );
            }
          })
        );
        return isSuccess;
      } else {
        return await this.createRequestAndPut(
          this.feature?.properties?.fid,
          this.name,
          {
            type: geometryType,
            coordinates: coordinates
          },
          this.isEdit
        );
      }
    },
    async createRequestAndPut(fid, name, geometry, isEdit) {
      // updates inputs to which user entered a value but does not added properly because he/she did not press enter afterwards
      this.$refs.additionalPropertiesComponent.updateInputs();
      return await this.$nextTick().then(async () => {
        let extraData = this.$refs?.customIntegration?.getExtraData();
        if (!extraData && this.feature) {
          extraData = this.feature.properties?.extra || this.feature.properties?.extraData;
        }

        let requestProperties = await this.getRequestProperties();
        if (this.isUploadFailed) {
          this.isUploadFailed = false;
          return;
        }

        const request = {
          type: "Feature",
          properties: {
            typeCode: this.selectedType,
            fid: fid,
            sid: Number(this.currentSite),
            bid: Number(this.currentBuilding),
            lvl: Number(this.currentLevel),
            name: name?.trim(),
            extra: extraData,
            style: {},
            ...requestProperties,
            // TODO: Remove when translation design is ready
            name_ar: this.nameAr,
            description_ar: this.descriptionAr
          },
          geometry: geometry
        };
        const response = await ContentService.putFeature(request, ContentService.CONTENT_TYPES.POI, isEdit);

        if (response?.createdTimestampUtcEpochSeconds) {
          this.$store.dispatch("CONTENT/UPDATE_FEATURE", { feature: request, isEdit: this.isEdit && isEdit });
          this.$store.dispatch("CONTENT/SET_LOCAL_CHANGES");
          this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
          if (this.isPoi) {
            const buildingObjectPolygon = polygon(this.currentBuildingObject.geometry.coordinates);
            let poiTurfObject;
            let isInside;
            if (geometry.type === "Point") {
              poiTurfObject = point(geometry.coordinates);
              isInside = booleanPointInPolygon(poiTurfObject, buildingObjectPolygon);
            } else {
              poiTurfObject = polygon(geometry.coordinates);
              isInside = booleanWithin(poiTurfObject, buildingObjectPolygon);
            }
            if (!isInside) {
              ToastHelpers.createWarningToast(this.$t(`${this.translationPath}poi-outdoor-warning`));
            }
          }
          return true;
        } else {
          return false;
        }
      });
    },
    async getRequestProperties() {
      const temp = {};
      const keys = Object.keys(this.additionalProperties);

      for await (const key of keys) {
        const propertyObject = this.findPropertyObjectByKey(key);
        if (!propertyObject) continue;
        const value = this.additionalProperties[key];
        if (value === undefined) continue;
        await this.processProperty(temp, propertyObject, value);
      }
      return temp;
    },

    findPropertyObjectByKey(key) {
      return PoiProperties.POI_PROPERTIES.find((propObj) => propObj.key === key);
    },

    async processProperty(temp, propertyObject, value) {
      const { requestKey } = propertyObject;
      if (requestKey === "logo") {
        await this.processLogoProperty(temp, value, requestKey);
      } else if (requestKey === "images") {
        await this.processImagesProperty(temp, value, requestKey);
      } else if (requestKey === "price" || requestKey === "rating") {
        Object.assign(temp, value);
      } else if (propertyObject.valueType === "hyperlink") {
        this.handleHyperlinkProperty(temp, propertyObject, value);
      } else {
        temp[requestKey] = value;
      }
    },

    async processLogoProperty(temp, value, requestKey) {
      if (typeof value !== "string") {
        const imageUrl = await this.uploadImage(value[0]);
        if (imageUrl) {
          temp[requestKey] = imageUrl;
        } else {
          this.handleUploadError("logo");
        }
      } else {
        temp[requestKey] = value;
      }
    },
    async processImagesProperty(temp, value, requestKey) {
      if (typeof value[0] !== "string") {
        const imageUrls = await this.uploadImages(value);
        if (imageUrls) {
          temp[requestKey] = imageUrls;
        } else {
          this.handleUploadError("image");
        }
      } else {
        temp[requestKey] = value;
      }
    },

    async uploadImage(file) {
      try {
        const imageUrl = await this.uploadFile(file, "logo");
        return imageUrl;
      } catch (error) {
        this.isUploadFailed = true;
        this.handleUploadError("logo");
        return null;
      }
    },

    async uploadImages(files) {
      const imageUrls = [];
      for (const file of files) {
        const imageUrl = await this.uploadFile(file, "image");
        if (!imageUrl) {
          this.isUploadFailed = true;
          this.handleUploadError("image");
          return null;
        }
        imageUrls.push(imageUrl);
      }
      return imageUrls;
    },

    async uploadFile(file, imageType) {
      const request = {
        fileType: file?.name?.split(".").pop(),
        fileBase64: file?.base64,
        extra: {
          imageType,
          width: file?.width,
          height: file?.height
        }
      };
      const response = await ContentService.uploadImage(request);
      return response?.createdTimestampUtcEpochSeconds ? response.result?.imageUrl : null;
    },

    handleHyperlinkProperty(temp, propertyObject, button) {
      const buttons = temp["buttons"] || [];
      const buttonObject = {
        name: propertyObject.name,
        action: propertyObject.action,
        intent: button.value === undefined ? button : button.value,
        iconUrl: propertyObject.iconUrl
      };
      if (button.shouldOverride) {
        buttonObject.action = "custom";
      }
      temp["buttons"] = [...buttons, buttonObject];
      delete temp[propertyObject.key];
    },

    handleUploadError(imageType) {
      ToastHelpers.createErrorToast(this.$t(`${this.translationPath}${imageType}-upload-failed`));
    },

    async deleteContent() {
      const response = await ContentService.deleteFeature(this.feature);
      if (response?.createdTimestampUtcEpochSeconds) {
        this.$store.dispatch("CONTENT/UPDATE_FEATURE", { feature: this.feature, isDelete: true });
        this.$store.dispatch("CONTENT/SET_LOCAL_CHANGES");
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        return true;
      } else {
        return false;
      }
    },
    emitValidationStatusForForm() {
      let isValid =
        this.isPropertiesValid &&
        this.isDetailsValid &&
        this.drawnCoordinates &&
        this.drawnCoordinates?.length !== 0 &&
        !this.isMapBorderEnabled;
      if (this.isPoi) {
        isValid = isValid && this.name;
      }
      this.$emit("valid", isValid);
    },
    onTypeSelected(type) {
      this.selectedType = type;
      this.setFormDirty();
    },
    setFormDirty() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    /**
     * Called when an additional property is added from menu
     */
    addNewProperty(item) {
      const temp = { ...this.additionalProperties };
      temp[item.key] = undefined;
      this.additionalProperties = temp;

      //This is to scroll to the bottom of the page when a new property is added
      const element = this.$refs.poiAddEditExpansionPanels.$el;
      element.scrollIntoView(false);
    },
    /**
     * Called when an additional property is deleted by clicking thrash icon
     */
    deleteProperty(propertyName) {
      const temp = this.additionalProperties;
      // additionalProperties is an object that consists of property name and value as key value pairs
      const index = Object.keys(this.additionalProperties).findIndex((property) => property === propertyName);
      if (index === -1) {
        return;
      }
      delete temp[propertyName];
      this.additionalProperties = { ...temp };
    },
    /**
     * Called when an additional property's value is updated
     */
    updateProperty(propertyName, newValue) {
      const value = typeof newValue === "string" ? newValue.trim() : newValue;
      const temp = { ...this.additionalProperties };
      temp[propertyName] = value;
      this.additionalProperties = temp;
    },
    /**
     * Sets data additionalProperties which is an object that consists of property name and value as key value pairs
     * Called when featureId changed
     */
    setAdditionalProperties() {
      this.additionalProperties = {};
      const validKeys = this.getValidKeys();
      Object.keys(this.feature.properties).forEach((propName) => {
        if (this.shouldExcludeProperty(propName, validKeys)) return;
        const value = this.feature.properties[propName];
        if (this.isEmptyValue(value)) return;
        if (propName === "buttons") {
          this.processButtonsProperty(value);
        } else if (propName.toLowerCase().includes("rating")) {
          this.processRatingProperty(propName, value);
        } else if (propName === "openHours") {
          this.processOpenHoursProperty(value);
        } else {
          this.additionalProperties[propName] = value;
        }
      });
    },

    getValidKeys() {
      return PoiProperties.POI_PROPERTIES.map((propertyObject) => propertyObject.requestKey);
    },
    shouldExcludeProperty(propName, validKeys) {
      return !validKeys.includes(propName) && !PoiProperties.SECONDARY_PROPERTIES.includes(propName);
    },
    isEmptyValue(value) {
      return value === "" || (Array.isArray(value) && value.length === 0) || value === null || value === undefined;
    },
    processButtonsProperty(buttons) {
      buttons.forEach((button) => {
        const propertyObject = PoiProperties.POI_PROPERTIES.find((obj) => obj.name === button.name);
        if (button.name === "Book" || button.name === "Order") {
          this.additionalProperties[propertyObject.requestKey] = {
            value: button.intent,
            shouldOverride: button.action === "custom"
          };
        } else {
          this.additionalProperties[propertyObject.requestKey] = button.intent;
        }
      });
    },
    processRatingProperty(propName, value) {
      if (!this.additionalProperties.rating) {
        this.additionalProperties.rating = {};
      }
      this.additionalProperties.rating[propName] = value;
    },
    processOpenHoursProperty(value) {
      if (typeof value !== "object") return;
      const adjustedOpenHours = this.adjustOpenHours(value);
      if (Object.keys(adjustedOpenHours).length > 0) {
        this.additionalProperties.openHours = adjustedOpenHours;
      }
    },
    adjustOpenHours(value) {
      const adjusted = {};
      Object.keys(value).forEach((day) => {
        const hours = value[day];
        if (Array.isArray(hours)) {
          const firstValidHour = hours.find(this.isValidOpeningHours);
          if (firstValidHour) {
            adjusted[day] = [firstValidHour];
          }
        }
      });
      return adjusted;
    },
    isValidOpeningHours(hours) {
      if (!hours || hours.length !== 2) return false;
      const timeRegex = /^([0-1]\d|2[0-3]):[0-5]\d?[+-]?(\d{4})?$/;
      return hours.every((time) => time.match(timeRegex));
    },
    filterProperties() {
      const commonKeys = ["eid", "description", "keywords"];
      const filteredObj = {};

      Object.keys(this.additionalProperties).forEach((key) => {
        if (commonKeys.includes(key)) {
          filteredObj[key] = this.additionalProperties[key];
        }
      });

      return filteredObj;
    }
  }
};
</script>
