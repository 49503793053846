<template>
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner"
};
</script>

<style lang="scss" scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 44px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: -22px;
  width: 32px;
  height: 32px;
  margin: 0px 4px 4px 8px;
  border: 2px solid var(--v-white-base);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.25, 0, 0.5, 1) infinite;
  border-color: var(--v-white-base) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
