<template>
  <v-dialog v-model="show" internal-activator persistent width="400" eager>
    <v-card class="pt-8 pb-8">
      <v-card-title color="" class="text-h5 justify-center font-weight-bold">
        {{ $t(`${translationPath}leaving-page-question`) }}
      </v-card-title>
      <v-card-text class="text-center">{{ $t(`${translationPath}unsaved-changes`) }}</v-card-text>
      <v-card-actions class="justify-center d-flex flex-column">
        <v-btn id="discard-modal-leave-btn" color="primary base" @click="leave">
          {{ $t(`${translationPath}leave`) }}
        </v-btn>
        <v-btn id="discard-modal-stay-btn" color="neutral lighten1" text @click="stay">
          {{ $t(`${translationPath}stay`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NewDiscardChangesModal",
  props: {
    cancelAction: {
      type: Function,
      default: () => ({})
    },
    confirmAction: {
      type: Function,
      default: () => ({})
    }
  },
  data: () => ({
    translationPath: "contents.discardChangesModal.",
    show: true
  }),
  methods: {
    stay() {
      this.cancelAction();
    },
    leave() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
      this.confirmAction();
    }
  }
};
</script>

<style lang="scss" scoped>
.text-h5 {
  color: var(--v-primary-base) !important;
}

.v-card__text {
  color: var(--v-neutral-darken5) !important;
}

.v-btn {
  margin-left: 0 !important;
}
</style>
