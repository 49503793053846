var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:{ 'bordered-container': _vm.isBordered }},[(_vm.label)?_c('div',{staticClass:"label ml-n2 pl-2"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.isMultiple && _vm.maxCount)?_c('div',{staticClass:"count-info",class:!_vm.isFileAdded ? 'mb-5' : ''},[_vm._v(" "+_vm._s(_vm.files?.length || 0)+"/"+_vm._s(_vm.maxCount)+" images ")]):_vm._e(),(_vm.isFileAdded)?_c('div',_vm._l((_vm.files),function(file,index){return _c('div',{key:index,staticClass:"drop-file disable-hover-effect my-0 file",class:{
          'no-border': _vm.files !== undefined,
          'multiple-item': _vm.isMultiple && _vm.isFileAdded,
          'no-margin': _vm.maxCount === _vm.files?.length
        }},[_c('v-row',{staticClass:"content px-0 my-0",class:_vm.isImageFile ? 'py-1' : 'py-3'},[_c('div',{staticClass:"d-flex align-center",class:_vm.isImageFile
                ? 'image-file-background justify-start'
                : 'button-background paragraph-s justify-space-between py-3 px-2'},[(_vm.thumbnails)?_c('img',{staticClass:"thumbnail",attrs:{"src":_vm.thumbnails[index],"alt":"thumbnail"}}):_vm._e(),_c('div',{staticClass:"file-name-container"},[_vm._v(_vm._s(_vm.getFilename(index)))]),_c('div',{staticClass:"right-button-container ml-auto d-flex"},[(_vm.isImageFile)?_c('v-btn',{staticClass:"ml-auto",attrs:{"small":"","icon":"","depressed":"","plain":""},on:{"click":function($event){return _vm.openImageCropper(index)}}},[_c('PtrIcon',{staticClass:"button-icon",attrs:{"icon":"edit","width":"14.4px","color":"neutral"}})],1):_vm._e(),(_vm.isImageFile)?_c('v-btn',{staticClass:"ml-auto",attrs:{"small":"","icon":"","depressed":"","plain":""},on:{"click":function($event){return _vm.removeFile(index)}}},[_c('PtrIcon',{staticClass:"button-icon",attrs:{"icon":"close","width":"14.4px","color":"neutral"}})],1):(_vm.isEdit())?_c('v-btn',{attrs:{"small":"","icon":"","depressed":"","plain":""}},[_c('PtrIcon',{staticClass:"button-icon",attrs:{"icon":"download"}})],1):_c('v-btn',{attrs:{"small":"","icon":"","depressed":"","plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(index)}}},[_c('PtrIcon',{staticClass:"button-icon",attrs:{"icon":"clear"}})],1)],1)])])],1)}),0):_vm._e(),(_vm.shouldShowInput)?_c('div',{staticClass:"mb-2 drop-file",class:{
        'hover-file': _vm.dragging,
        bordered: _vm.isBordered,
        'lower-border-radius': _vm.isMultiple && _vm.isFileAdded
      },on:{"dragover":function($event){_vm.dragging = true},"dragleave":function($event){_vm.dragging = false}}},[_c('v-row',{staticClass:"content my-0 py-0 px-4",on:{"drag":_vm.onChange}},[(!_vm.isMultiple || (_vm.isMultiple && !_vm.isFileAdded))?_c('v-col',{staticClass:"d-flex flex-column align-center py-2 px-4"},[_c('PtrIcon',{staticClass:"ma-2",attrs:{"icon":"cloud-up","width":"28","height":"28"}}),_c('div',{staticClass:"drop-file-title"},[_vm._v(_vm._s(_vm.title))]),(_vm.subtitle)?_c('div',{staticClass:"drop-file-subtitle property-header"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e(),_vm._t("description")],2):_c('v-col',{staticClass:"d-flex align-center justify-start py-1 multiple-input"},[_c('PtrIcon',{attrs:{"icon":"plus","width":"12","height":"12"}}),_c('div',[_vm._v(_vm._s(_vm.$t(`${_vm.translationPath}addAnotherImage`)))])],1)],1),_c('input',{key:_vm.fileInputKey,ref:"fileInput",attrs:{"type":"file"},on:{"change":_vm.onChange}})],1):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"drop-file"},[_c('v-row',{staticClass:"content pa-3",on:{"drag":_vm.onChange}},[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"drop-file-title"},[_vm._v(_vm._s(_vm.progress)+"%")]),_c('div',{staticClass:"drop-file-subtitle"},[_vm._v(_vm._s(_vm.$t(`${_vm.translationPath}processing`)))])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-progress-circular',{attrs:{"width":3,"indeterminate":"","color":"primary"}})],1)],1)],1):_vm._e(),(!_vm.files && !_vm.isLoading && _vm.restriction)?_c('v-row',{staticClass:"restriction paragraph-xs my-0"},[_vm._v(_vm._s(_vm.restriction))]):_vm._e()],1),(_vm.imageToCrop !== undefined)?_c('ImageCropperPopup',{attrs:{"file":_vm.imageToCrop,"aspect-ratio":_vm.aspectRatio,"is-edit":_vm.isImageCropperEdit},on:{"close":_vm.revertCrop,"imageCropped":(url) => _vm.onImageCropped(url),"cancel":_vm.removeImageFromUneditedFiles}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }