import vm from "../main";
import axios from "./PointrCloudAxios";
import ToastHelpers from "@/helpers/ToastHelpers";

export default class SdkConfigurationService {
  static async getDefaultSdkConfigurations() {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get("/configurations/sdk-configurations/default-keys");
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.results;
  }

  static async getClientSdkConfigurations(clientInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/clients/${clientInternalIdentifier}/configurations/sdk-configurations/typed`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.results;
  }

  static async getSiteSdkConfigurations(siteInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/sites/${siteInternalIdentifier}/configurations/sdk-configurations/typed`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.results;
  }

  static async getBuildingSdkConfigurations(buildingInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/buildings/${buildingInternalIdentifier}/configurations/sdk-configurations/typed`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.results;
  }

  static async createClientSdkConfigurations(clientInternalIdentifier, clientTitle, configurations) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(
        `/clients/${clientInternalIdentifier}/configurations/sdk-configurations`,
        configurations
      );
    } catch (error) {
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        `${clientTitle} ${vm.$t("contents.sdk-configuration-failed")}`
      );
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data;
  }

  static async createSiteSdkConfigurations(siteInternalIdentifier, siteTitle, configurations) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(`/sites/${siteInternalIdentifier}/configurations/sdk-configurations`, configurations);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        `${siteTitle} ${vm.$t("contents.sdk-configuration-failed")}`
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data;
  }

  static async createBuildingSdkConfigurations(buildingInternalIdentifier, buildingTitle, configurations) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(
        `/buildings/${buildingInternalIdentifier}/configurations/sdk-configurations`,
        configurations
      );
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        `${buildingTitle} ${vm.$t("contents.sdk-configuration-failed")}`
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data;
  }
}
