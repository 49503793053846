import maplibregl from "maplibre-gl";
import { customMarker } from "@/helpers/StyleHelpers";
import Helpers from "@/helpers/Helpers";

export default class PointMode {
  static on(eventName, callback) {
    if (!this.callbacks[eventName]) {
      console.debug("Invalid event name", eventName);
      return;
    }
    if (typeof callback !== "function") {
      console.debug("Invalid callback", callback);
      return;
    }
    this.callbacks[eventName].push(callback);
  }

  static off(eventName, callback) {
    this.callbacks[eventName].splice(this.callbacks[eventName].indexOf(callback), 1);
  }

  static init() {
    this.isEnabled = false;
    this.markers = {};
    this.isSingleMarker = false;
    this.callbacks = {};
    this.events = ["point.create", "point.update", "point.delete"];

    this.events.forEach((eventName) => (this.callbacks[eventName] = []));
  }

  static create({ map, isSingleMarker }) {
    this.map = map;
    this.isSingleMarker = isSingleMarker;
    this.mapClick = this.mapClick.bind(this);
  }

  // Not used anywhere
  static destroy() {
    if (!this.map) {
      return;
    }
    this.callbacks["point.delete"].forEach((callback) => callback());
    this.clearPoints();
    this.init();
    this.toggle({ shouldEnable: false });
  }

  static mapClick(e) {
    const { lng, lat } = e.lngLat;
    const markerEl = customMarker({ markerId: Helpers.generateUuid(), className: "node-marker-point" });
    this.addMarker(markerEl, [lng, lat], Helpers.generateUuid(), { draggable: true });
  }

  static addPoint(coordinate, options) {
    const markerId = options.fid ? options.fid : Helpers.generateUuid();
    // Checks if point/marker is already added before, if added skips
    if (this.markers[markerId]) {
      return;
    }
    const markerEl = customMarker({
      markerId: markerId,
      className: options?.isBeacon ? "beacon-marker" : "node-marker-point"
    });
    this.addMarker(markerEl, coordinate, markerId, options);
    return markerEl;
  }

  static movePoint(markerId, coordinate) {
    this.markers[markerId].setLngLat(coordinate);
  }

  // enable/disable
  static toggle(options) {
    if (!this.map) {
      return; // setup not called yet. So we don't need toggle
    }
    this.isEnabled = options?.shouldEnable ?? !this.isEnabled;

    if (this.isEnabled) {
      this.isSingleMarker = true;
      this.map.on("click", this.mapClick);
    } else {
      this.isSingleMarker = false;
      this.map.off("click", this.mapClick);
      document.getElementsByClassName("map-wrapper")[0].classList.remove("crosshair");
    }
    Object.values(this.markers).forEach((marker) => {
      marker.setDraggable(this.isEnabled);
      marker.on("drag", () => {
        this.callbacks["point.update"].forEach((callback) => callback());
      });
    });
  }

  static addMarker(markerEl, coord, markerId, options) {
    if (this.isSingleMarker && Object.keys(this.markers).length) {
      return;
    }
    if (!coord) {
      return;
    }
    let marker = new maplibregl.Marker({ element: markerEl, draggable: options.draggable });
    marker.setLngLat(coord).addTo(this.map);
    if (options.draggable) {
      marker.on("drag", () => {
        this.callbacks["point.update"].forEach((callback) => callback());
      });
    }
    if (this.isSingleMarker) {
      this.clearPoints();
      this.markers = { markerId: marker };
    } else {
      this.markers[markerId] = marker;
    }
    this.callbacks["point.create"].forEach((callback) => callback());
  }

  static highlight(markerId) {
    if (this.markers[markerId]) {
      this.markers[markerId].getElement().classList.add("node-marker-selected");
    }
  }

  static unhighlight(markerId) {
    if (this.markers[markerId]) {
      this.markers[markerId].getElement().classList.remove("node-marker-selected");
    }
  }

  static removeMarker(markerIdToRemove) {
    Object.keys(this.markers).some((markerId) => {
      if (markerId == markerIdToRemove) {
        this.markers[markerIdToRemove].getElement().remove();
        delete this.markers[markerIdToRemove];
        return true;
      }
    });
  }

  static clearPoints() {
    Object.keys(this.markers).forEach((markerId) => {
      this.markers[markerId].getElement().remove();
    });
    this.markers = {};
  }

  static exportCoordinates() {
    const lngLatObjArr = Object.values(this.markers).map((obj) => obj.getLngLat());
    return lngLatObjArr.reduce((total, lngLat) => {
      const { lng, lat } = lngLat;
      total.push([lng, lat]);
      return total;
    }, []);
  }
}
