<template>
  <v-tooltip
    :bottom="position === 'bottom'"
    :right="position === 'right'"
    :left="position === 'left'"
    :top="position === 'top'"
    :open-on-hover="false"
    :content-class="`help-content pa-4 ${className}`"
  >
    <template #activator="{ on }">
      <v-btn small depressed plain icon retain-focus-on-click v-on="on" @click="on.click" @blur="on.blur">
        <PtrIcon :icon="iconName" :height="iconHeight || 18" />
      </v-btn>
    </template>
    <span v-if="content" class="d-flex flex-column">
      <img v-if="content.imageLink" :src="content.imageLink" alt="Content Image" />
      <div v-if="content.header" class="hoverable-header mb-2">{{ content.header }}</div>
      <div v-if="content.description" class="paragraph-s">{{ content.description }}</div>
      <div v-if="content.link">
        <a :href="content.link.href" target="_blank" rel="noopener">{{ content.link.text }}</a>
      </div>
    </span>
    <slot name="html-header"></slot>
    <slot name="html-content"></slot>
  </v-tooltip>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";
export default {
  components: { PtrIcon },
  props: {
    content: Object,
    className: {
      type: String,
      default: ""
    },
    position: {
      type: String,
      default: "right"
    },
    iconHeight: String,
    iconName: { type: String, default: "question-mark" }
  }
};
</script>
<style lang="scss">
.help-content {
  width: 296px;
  background-color: var(--v-neutral-darken4);
  color: var(--v-white-base);
  box-shadow: 0px 4px 8px rgba(23, 25, 28, 0.16);
  border-radius: 8px;
  pointer-events: all;
  opacity: 1;
  overflow: inherit !important; // that's important to make the pseudo-element visible outside the main container
  z-index: 20;

  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 10px);
    bottom: 0;
    left: -8px;
    z-index: 21;
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid var(--v-neutral-darken4);
    box-sizing: border-box;
  }
  &.bottom::before {
    content: "";
    position: absolute;
    top: -20px;
    bottom: auto;
    left: calc(50% - 10px);
    border-top: 10px solid var(--v-neutral-darken4);
    border-bottom: 10px solid transparent;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    transform: rotate(180deg);
    box-sizing: border-box;
  }
  .hoverable-header {
    font-weight: #{$font-weight-medium};
    line-height: #{$line-height-10};
    font-size: #{$font-size-6};
  }
  a {
    color: var(--v-white-base);
  }
  &.higher-z-index {
    z-index: 300 !important;
  }
}
</style>
