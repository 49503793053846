import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import generateColors from "../assets/colors";
import { setupSystemThemeListener, isDarkTheme } from "../helpers/ThemeHelpers";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: isDarkTheme(localStorage.getItem("theme")),
    themes: {
      light: generateColors(false),
      dark: generateColors(true)
    }
  }
});

setupSystemThemeListener(vuetify);

export default vuetify;
