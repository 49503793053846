import { polygon } from "@turf/helpers";
import centroid from "@turf/centroid";
import router from "../router/index";

import MapConstants from "@/constants/mapConstants";

import MapHelpers from "@/helpers/MapHelpers";

export default {
  namespaced: true,
  state: () => ({
    map: undefined,
    mapDraw: undefined,
    mapMode: "",
    zoom: 13,
    minZoom: 1,
    maxZoom: 23.5,
    currentClient: undefined,
    currentSite: undefined,
    currentBuilding: undefined,
    currentLevel: undefined,
    isMapReady: false,
    isAddEditPanelActive: false,
    isMapBorderEnabled: false,
    isAIMappingInProgress: false,
    isAddEditPanelOpening: false,
    isAddEditPanelClosing: false,
    guidanceMessage: undefined,
    shouldShowMapIcons: {
      search: {
        shouldShow: true,
        isDisabled: false
      },
      zoom: {
        shouldShow: true,
        isDisabled: false
      },
      cursor: {
        shouldShow: false,
        isDisabled: false
      },
      marker: {
        shouldShow: false,
        isDisabled: false
      },
      polygon: {
        shouldShow: false,
        isDisabled: false
      },
      point: {
        shouldShow: false,
        isDisabled: false
      },
      delete: {
        shouldShow: false,
        isDisabled: false
      },
      circle: {
        shouldShow: false,
        isDisabled: false
      },
      rectangle: {
        shouldShow: false,
        isDisabled: false
      },
      cut: {
        shouldShow: false,
        isDisabled: false
      },
      graph: {
        shouldShow: false,
        isDisabled: false
      },
      target: {
        shouldShow: false,
        isDisabled: false
      },
      help: {
        shouldShow: false,
        isDisabled: false
      },
      eye: {
        shouldShow: false,
        isDisabled: false
      },
      publish: {
        shouldShow: false,
        isDisabled: false
      },
      levelSelector: {
        shouldShow: false,
        isDisabled: false
      },
      buildingSiteSelector: {
        shouldShow: false,
        isDisabled: false
      },
      buildingLevelSelector: {
        shouldShow: false,
        isDisabled: false
      },
      pathLevelSelector: {
        shouldShow: false,
        isDisabled: false
      }
    },
    drawnCoordinates: undefined,
    clickedTransitionFeature: undefined,
    pointMarkers: [],
    shouldDisplayOriginalFloorPlan: false
  }),
  mutations: {
    MAP(state, map) {
      state.map = map;
    },
    MAP_DRAW(state, mapDraw) {
      state.mapDraw = mapDraw;
    },
    MAP_MODE(state, mode) {
      state.mapMode = mode;
    },
    ZOOM(state, zoom) {
      if (zoom === state.zoom) {
        return;
      } else if (zoom < state.minZoom || zoom - state.minZoom < 0.1) {
        state.zoom = state.minZoom;
      } else if (zoom > state.maxZoom || state.maxZoom - zoom < 0.1) {
        state.zoom = state.maxZoom;
      }
      state.zoom = zoom;
      state.map.jumpTo({
        zoom: zoom
      });
    },
    IS_ADD_EDIT_PANEL_ACTIVE(state, value) {
      state.isAddEditPanelActive = value;
    },
    HIDE_UI_ICONS(state, iconName) {
      if (iconName) {
        Object.keys(state.shouldShowMapIcons).some((i) => {
          if (i === iconName) {
            state.shouldShowMapIcons[i].shouldShow = false;
            return true;
          }
        });
      } else {
        Object.keys(state.shouldShowMapIcons).forEach((i) => (state.shouldShowMapIcons[i].shouldShow = false));
      }
    },
    SHOW_UI_ICON(state, { iconName }) {
      if (!(iconName in state.shouldShowMapIcons)) {
        console.warn(`UI icon ${iconName} wouldn't be visible - Invalid UI icon name`, state.shouldShowMapIcons);
        return;
      }
      state.shouldShowMapIcons[iconName].shouldShow = true;
    },
    SHOULD_DISPLAY_ORIGINAL_FLOOR_PLAN(state, value) {
      state.shouldDisplayOriginalFloorPlan = value;
    },
    TOGGLE_UI_ICON_DISABILITY_STATE(state, { iconName, isDisabled }) {
      state.shouldShowMapIcons[iconName].isDisabled = isDisabled;
    },
    CURRENT_CLIENT(state, client) {
      state.currentClient = client;
    },
    CURRENT_SITE(state, site) {
      state.currentSite = Number(site);
    },
    CURRENT_BUILDING(state, building) {
      state.currentBuilding = building !== undefined ? Number(building) : building;
    },
    CURRENT_LEVEL(state, level) {
      if (level !== undefined) {
        state.currentLevel = Number(level);
      } else {
        state.currentLevel = undefined;
      }
    },
    MAP_READY(state, value) {
      state.isMapReady = value;
    },
    DRAWN_COORDINATES(state, value) {
      state.drawnCoordinates = value;
    },
    IS_MAP_BORDER_ENABLED(state, value) {
      state.isMapBorderEnabled = value;
    },
    IS_AI_MAPPING_IN_PROGRESS(state, value) {
      state.isAIMappingInProgress = value;
    },
    IS_ADD_EDIT_PANEL_OPENING(state, value) {
      state.isAddEditPanelOpening = value;
    },
    IS_ADD_EDIT_PANEL_CLOSING(state, value) {
      state.isAddEditPanelClosing = value;
    },
    GUIDANCE_MESSAGE(state, value) {
      state.guidanceMessage = value;
    },
    CLICKED_TRANSITION_FEATURE(state, value) {
      state.clickedTransitionFeature = { ...value };
    },
    POINT_MARKERS(state, value) {
      state.pointMarkers = value;
    }
  },
  actions: {
    // TODO: move to mutation (except sync and ones do 2 different commits)
    SET_CURRENT_CLIENT({ commit, rootState }, payload) {
      const clientObject = rootState.CONTENT.clients.find(
        (client) => client.clientInternalIdentifier === payload.client
      );
      if (clientObject) {
        commit("CURRENT_CLIENT", payload.client);
      } else {
        router.push({ name: "Clients" }).catch((e) => console.log(e.message));
      }
    },
    SET_CURRENT_SITE({ state, commit, getters }, payload) {
      const site = payload.site;
      if (state.currentSite == site && !payload.forceUpdate) {
        return;
      }
      commit("CURRENT_SITE", site);
      const siteObj = getters.currentSiteObject;
      if (siteObj?.geometry?.coordinates?.[0] === undefined) {
        console.debug("Given site is not valid - Site id:", site, siteObj);
        router.push({ name: "Clients" }).catch((e) => console.log(e.message));
        return;
      }
      MapHelpers.fitBounds(siteObj.geometry.coordinates[0]);
    },
    SET_CURRENT_BUILDING({ state, getters, commit }, payload) {
      const building = payload.building;
      if (state.currentBuilding == building && !payload.forceUpdate) {
        return;
      }
      commit("CURRENT_BUILDING", building);
      const buildingObj = getters.currentBuildingObject;
      if (buildingObj?.geometry?.coordinates?.[0] === undefined) {
        console.debug("Given building is not valid - Building id:", building, buildingObj);
        router.push({ name: "Clients" }).catch((e) => console.log(e.message));
        return;
      }
      MapHelpers.fitBounds(buildingObj.geometry.coordinates[0]);
    },
    async SET_CURRENT_LEVEL({ commit, state, rootState, dispatch }, payload) {
      const level = payload.level;
      commit("CURRENT_LEVEL", level);

      if (level !== undefined) {
        dispatch("UNHIGHLIGHT_ALL_FEATURES");
        await dispatch(
          "CONTENT/SET_MAP_OBJECTS",
          { buildingInternalIdentifier: state.currentBuilding, levelIndex: level, forceUpdate: true },
          { root: true }
        );
        if (rootState.CONTENT.contentVisibility["levelGeofences"]) {
          dispatch("ADD_GEOFENCES_TO_MAP");
        } else {
          dispatch("ADD_MAP_OBJECTS_TO_MAP");
        }
      } else {
        dispatch("REMOVE_MAP_OBJECTS_FROM_MAP");
      }
    },
    REMOVE_MAP_OBJECTS_FROM_MAP({ state }) {
      if (state.map.getSource(MapConstants.SOURCE)) {
        state.map.getSource(MapConstants.SOURCE).setData(MapConstants.EMPTY_GEO_JSON);
      }
    },
    ADD_MAP_OBJECTS_TO_MAP({ state, rootState, dispatch }, features) {
      if (state.map.getSource(MapConstants.SOURCE)) {
        if (features) {
          state.map.getSource(MapConstants.SOURCE).setData({
            type: "FeatureCollection",
            features: features
          });
        } else if (state.shouldDisplayOriginalFloorPlan) {
          dispatch("SHOW_ORIGINAL_FLOOR_PLAN");
        } else {
          let featureArray = [...rootState.CONTENT.mapObjects];
          if (rootState.CONTENT.contentVisibility["levelGeofences"]) {
            featureArray = [...rootState.CONTENT.mapObjects, ...rootState.CONTENT.levelGeofences];
          }
          state.map.getSource(MapConstants.SOURCE).setData({
            type: "FeatureCollection",
            features: featureArray
          });
        }
        if (rootState.CONTENT.contentVisibility["levelGeofences"]) {
          dispatch("ADD_GEOFENCES_TO_MAP");
        }
      }
    },
    SHOW_ORIGINAL_FLOOR_PLAN({ state, rootState }) {
      let featureArray = [...rootState.CONTENT.mapObjects, ...rootState.CONTENT.originalMapObjects];
      if (rootState.CONTENT.contentVisibility["levelGeofences"]) {
        featureArray = [
          ...rootState.CONTENT.mapObjects,
          ...rootState.CONTENT.originalMapObjects,
          ...rootState.CONTENT.levelGeofences
        ];
      }
      state.map.getSource(MapConstants.SOURCE).setData({
        type: "FeatureCollection",
        features: featureArray
      });
    },
    ADD_GEOFENCES_TO_MAP({ state, rootState }) {
      if (state.map.getSource(MapConstants.SOURCE)) {
        if (state.shouldDisplayOriginalFloorPlan) {
          state.map.getSource(MapConstants.SOURCE).setData({
            type: "FeatureCollection",
            features: [
              ...rootState.CONTENT.mapObjects.filter((obj) => obj.properties.typeCode !== "level-geofence"),
              ...rootState.CONTENT.levelGeofences,
              ...rootState.CONTENT.originalMapObjects
            ]
          });
        } else {
          state.map.getSource(MapConstants.SOURCE).setData({
            type: "FeatureCollection",
            features: [
              ...rootState.CONTENT.mapObjects.filter((obj) => obj.properties.typeCode !== "level-geofence"),
              ...rootState.CONTENT.levelGeofences
            ]
          });
        }
      }
    },
    HIGHLIGHT_FEATURES({ state }, features) {
      state.map.getSource(MapConstants.SELECTED_SOURCE).setData({
        type: "FeatureCollection",
        features: features
      });
    },
    UNHIGHLIGHT_ALL_FEATURES({ state }) {
      state.map.getSource(MapConstants.SELECTED_SOURCE).setData(MapConstants.EMPTY_GEO_JSON);
    },
    DISPLAY_SITE_SYMBOLS({ state, rootState }) {
      const sitesGeoJson = {
        type: "FeatureCollection",
        features: rootState.CONTENT.sites.map((site) => {
          const turfSite = polygon(site.geometry.coordinates);
          return {
            type: "Feature",
            properties: {
              name: site.siteTitle,
              typeCode: "site-outline",
              siteInternalIdentifier: site.siteInternalIdentifier,
              siteExternalIdentifier: site.siteExternalIdentifier
            },
            geometry: {
              type: "Point",
              coordinates: centroid(turfSite)?.geometry?.coordinates || []
            }
          };
        })
      };
      state.map.getSource(MapConstants.SITE_OUTLINE_SOURCE).setData(sitesGeoJson);
    },
    HIDE_SITE_SYMBOLS({ state }) {
      state.map.getSource(MapConstants.SITE_OUTLINE_SOURCE).setData(MapConstants.EMPTY_GEO_JSON);
    },
    DISPLAY_SITE_BORDERS({ state, rootState }) {
      const sitesGeoJson = {
        type: "FeatureCollection",
        features: rootState.CONTENT.sites.map((site) => {
          return {
            type: "Feature",
            properties: {
              name: site?.properties?.name,
              typeCode: "site-outline"
            },
            geometry: {
              type: "Polygon",
              coordinates: site?.geometry?.coordinates || []
            }
          };
        })
      };
      state.map.getSource(MapConstants.SITE_BORDERS_SOURCE).setData(sitesGeoJson);
    },
    HIDE_SITE_BORDERS({ state }) {
      state.map.getSource(MapConstants.SITE_BORDERS_SOURCE).setData({
        type: "FeatureCollection",
        features: []
      });
    },
    DISPLAY_BUILDING_BORDERS({ state, rootState, dispatch }, payload) {
      const buildingsGeoJson = {
        type: "FeatureCollection",
        features: rootState.CONTENT.buildings
          .filter((building) => {
            if (payload?.enableCurrentBuildingFilter) {
              return building.buildingInternalIdentifier !== state.currentBuilding;
            } else {
              return true;
            }
          })
          .map((building) => {
            return {
              type: "Feature",
              id: building.buildingInternalIdentifier,
              properties: {
                name: building.buildingTitle,
                typeCode: "building-outline",
                buildingInternalIdentifier: building.buildingInternalIdentifier,
                buildingExternalIdentifier: building.buildingExternalIdentifier
              },
              geometry: {
                type: "Polygon",
                coordinates: building?.geometry?.coordinates || []
              }
            };
          })
      };
      state.map.getSource(MapConstants.BUILDING_BORDERS_SOURCE).setData(buildingsGeoJson);

      if (state.map.getLayer("fill_building-outline_ptr")) {
        state.map.setPaintProperty("fill_building-outline_ptr", "fill-extrusion-opacity", payload?.opacity || 1);
      }

      dispatch("DISPLAY_BUILDING_SYMBOLS", payload);
    },
    HIDE_BUILDING_BORDERS({ state, dispatch }) {
      state.map.getSource(MapConstants.BUILDING_BORDERS_SOURCE).setData(MapConstants.EMPTY_GEO_JSON);
      dispatch("HIDE_BUILDING_SYMBOLS");
    },
    DISPLAY_BUILDING_SYMBOLS({ state, rootState }, payload) {
      const buildingsGeoJson = {
        type: "FeatureCollection",
        features: []
      };
      rootState.CONTENT.buildings
        .filter((building) => {
          if (payload?.enableCurrentBuildingFilter) {
            return building.buildingInternalIdentifier !== state.currentBuilding && building.geometry?.coordinates;
          } else {
            return building.geometry?.coordinates;
          }
        })
        .forEach((building) => {
          const turfBuilding = polygon(building.geometry?.coordinates);
          buildingsGeoJson.features.push({
            type: "Feature",
            id: building.buildingInternalIdentifier,
            properties: {
              name: building.buildingTitle,
              typeCode: "building-outline",
              buildingInternalIdentifier: building.buildingInternalIdentifier,
              buildingExternalIdentifier: building.buildingExternalIdentifier
            },
            geometry: {
              type: "Point",
              coordinates: centroid(turfBuilding)?.geometry?.coordinates || []
            }
          });
        });
      state.map.getSource(MapConstants.BUILDING_OUTLINE_SOURCE).setData(buildingsGeoJson);
    },
    HIDE_BUILDING_SYMBOLS({ state }) {
      state.map.getSource(MapConstants.BUILDING_OUTLINE_SOURCE).setData(MapConstants.EMPTY_GEO_JSON);
    },
    DRAWN_POLYGON_CHANGED({ state, commit }, { feature, shouldKeepFormClean }) {
      if (!shouldKeepFormClean) {
        commit("CONTENT/IS_FORM_DIRTY", true, { root: true });
      }
      if (feature === undefined) {
        state.drawnCoordinates = undefined;
        state.mapDraw.deleteAll();
      }
      const drawnPolygon = state.mapDraw.getAll()?.features?.[0];
      if (JSON.stringify(drawnPolygon?.geometry?.coordinates) === state.drawnCoordinates) {
        return;
      }
      state.drawnCoordinates = JSON.stringify(feature.geometry.coordinates);
      if (feature?.properties?.fid) {
        feature.id = feature.properties.fid;
      }

      state.mapDraw.add(feature);
    },
    // TODO: If another mode comes in refactor these to one function
    MAP_ENTER_EDIT_MODE({ state, rootState }) {
      rootState.CONTENT.mapObjects.forEach((mapObject) => {
        state.map.setFeatureState({ source: "source_ptr", id: mapObject.id }, { edit: true });
      });
    },
    MAP_EXIT_EDIT_MODE({ state, rootState }) {
      rootState.CONTENT.mapObjects.forEach((mapObject) => {
        state.map.setFeatureState({ source: "source_ptr", id: mapObject.id }, { edit: false });
      });
    },
    MAP_ENTER_WAYFINDING_MODE({ state, rootState }) {
      rootState.CONTENT.mapObjects.forEach((mapObject) => {
        state.map.setFeatureState({ source: "source_ptr", id: mapObject.id }, { "wayfinding-edit": true });
      });
    },
    MAP_EXIT_WAYFINDING_MODE({ state, rootState }) {
      rootState.CONTENT.mapObjects.forEach((mapObject) => {
        state.map.setFeatureState({ source: "source_ptr", id: mapObject.id }, { "wayfinding-edit": false });
      });
    }
  },
  getters: {
    map: (state) => state.map,
    currentClientObject: (state, _getters, rootState) => {
      return rootState.CONTENT.clients.find((client) => client.clientInternalIdentifier === state.currentClient);
    },
    currentSiteObject: (state, _getters, rootState) => {
      return (
        rootState.CONTENT?.sites.find((site) => site.siteInternalIdentifier === Number(state.currentSite)) ||
        rootState.CONTENT?.site
      );
    },
    currentBuildingObject: (state, _getters, rootState) => {
      if (state.currentBuilding === undefined) {
        return;
      }
      return rootState.CONTENT.buildings.find(
        (building) => building.buildingInternalIdentifier === Number(state.currentBuilding)
      );
    },
    currentLevelObject: (state, _getters, rootState) => {
      return rootState.CONTENT.levels.find((level) => level.levelIndex === Number(state.currentLevel));
    }
  }
};
